import React, { useContext, useEffect } from 'react';
import axios from 'axios';

import NavBar from 'appLayout/components/navbar/NavBar';
import SearchBar from './_SearchBar';
import { store } from 'store';
// eslint-disable-next-line no-unused-vars
import { Button, Grid, Link, makeStyles, Typography } from '@material-ui/core';

import logo from 'images/logo.svg';

const useStyles = makeStyles((theme) => ({
  header: {},
  subHeader: {},
  userGrid: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '10px',
  },
  searchBar: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px 0 20px 20px',
    justifyContent: 'flex-end',
  },
  welcomeMessage: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '20px',
  },
  link: {
    margin: theme.spacing(1),
    color: '#BB120F',
  },
  logo: {
    cursor: 'pointer',
  },
  logoContainer: {
    height: '90px',
    width: '100%',
  },
  navbar: {
    height: '100px',
    zIndex: 9999,
  },
  navigateToHomeButton: {
    margin: '0 10px 0 0',
  },
  loginContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
}));

const Header = ({ history }) => {
  const globalState = useContext(store)
  const { state, dispatch } = globalState
  const loggedInUser = localStorage.getItem('user')

  useEffect(() => {
    if(loggedInUser && !state.syllabusSaqsCountList) {
      axios
        .get('/sscount')
        .then((response) =>
          dispatch({
            type: 'set syllabus and saqs count in store',
            data: response.data,
          })
        )
        .catch((err) => console.log(err));
    }
  });

  const classes = useStyles();

  const onClickNavigateToHome = () => history.push('/home');

  const logout = async (url = '') =>
    await fetch(url, {
      method: 'GET',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      redirect: 'follow',
      referrerPolicy: 'no-referrer'
    });

  const handleLoginButtonOnClick = () => {
    if(loggedInUser) {
      dispatch({
        type: 'remove user from store',
        data: '',
      });
      localStorage.removeItem('user')
      // logout('http://localhost:8080/session/logout')
      //   .then(() => history.replace('/'))
      //   .catch(err => console.log('error', err))
      logout('https://critbear.com/api/session/logout')
        .then(() => history.replace('/'))
        .catch(err => console.log('error', err))
    } else {
      window.location.href = '/login';
    }
  };

  const renderHeader = (user) => {
    return (
      <Grid className={classes.subHeader} container>
        <Grid item md={2} sm={2} xs={2} className={classes.userGrid}>
          <div
            className={classes.logoContainer}
            onClick={() => onClickNavigateToHome()}
          >
            <img
              className={classes.logo}
              src={logo}
              alt="Exam Technique Table"
            />
          </div>
        </Grid>
        <Grid item md={2} sm={2} xs={2} className={classes.welcomeMessage}>
          {user && <Typography variant="h5">
            {`Hi ${JSON.parse(user).username}`}
          </Typography>}
        </Grid>
        <Grid item md={7} sm={7} xs={7} className={classes.searchBar}>
          <Typography>
            <Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                history.push('/contact');
              }}
              className={classes.link}
            >
              Have a question or found a mistake?
            </Link>
          </Typography>
          <SearchBar history={history}/>
        </Grid>
        <Grid item md={1} sm={1} xs={1} className={classes.loginContainer}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: '10px', cursor: 'pointer' }}
            onClick={handleLoginButtonOnClick}
          >
            {loggedInUser ? 'Log out' : 'Log In'}
          </Button>
        </Grid>
      </Grid>
    )
  }

  return (
    <div className={classes.header}>
      {renderHeader(loggedInUser)}

      <div children={classes.navbar}>
        <NavBar history={history}/>
      </div>
    </div>
  );
};

export default Header;
