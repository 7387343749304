// store.js
import React, { createContext, useReducer } from 'react';
import { omit } from 'lodash';

const initialState = {
  saqRowCheckedList: {},
  syllabusRowCheckedList: {},
  pharmRowCheckedList: {},
  syllabusSaqsCountList: null,
  shouldUpdateProgress: true,
  user: null,
};
const store = createContext(initialState);
const { Provider } = store;
const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'set syllabus and saqs count in store':
        const countList = action.data || [];
        const syllabusSaqsCountList = countList.reduce(
          (acc, curr) => ({ ...acc, [curr.sectionType]: curr.count }),
          {}
        );
        return {
          ...state,
          syllabusSaqsCountList,
        };

      case 'update progress boolean in store':
        return {
          ...state,
          shouldUpdateProgress: action.data,
        };

      case 'set physiology syllabus in store':
        const syllabusData = action.data;
        const flattenedData = syllabusData.flat(4);
        return {
          ...state,
          physiologySyllabus: flattenedData,
        };

      case 'set pharmacology syllabus in store':
        return {
          ...state,
          pharmacologySyllabus: action.data,
        };

      case 'set checked status of all physiology syllabus':
        return {
          ...state,
          syllabusRowCheckedList: action.data,
        };

      case 'set checked status of all pharmacology syllabus':
        return {
          ...state,
          pharmRowCheckedList: action.data,
        };

      case 'set checked status of all saqs':
        return {
          ...state,
          saqRowCheckedList: action.data,
        };

      case 'change checked status of syllabus row':
        const rowCheckedData = action.data;
        const currentRowId = rowCheckedData.id;

        const currentCheckedList = state.syllabusRowCheckedList;
        const shouldRowBeRemoved = !!(
          currentCheckedList[currentRowId] && rowCheckedData.checked === false
        );

        const finalCheckedList = shouldRowBeRemoved
          ? omit(currentCheckedList, currentRowId)
          : { ...currentCheckedList, [currentRowId]: rowCheckedData.checked };

        return {
          ...state,
          syllabusRowCheckedList: finalCheckedList,
        };

      case 'change checked status of pharm syllabus row':
        const pharmRowCheckedData = action.data;
        const pharmCurrentRowId = pharmRowCheckedData.id;

        const pharmCurrentCheckedList = state.pharmRowCheckedList;
        const shouldPharmRowBeRemoved = !!(
          pharmCurrentCheckedList[pharmCurrentRowId] &&
          pharmRowCheckedData.checked === false
        );

        const finalPharmCheckedList = shouldPharmRowBeRemoved
          ? omit(pharmCurrentCheckedList, pharmCurrentRowId)
          : {
              ...pharmCurrentCheckedList,
              [pharmCurrentRowId]: pharmRowCheckedData.checked,
            };

        return {
          ...state,
          pharmRowCheckedList: finalPharmCheckedList,
        };

      case 'change checked status of saq row':
        const saqRowCheckedData = action.data;
        const saqRowPaperId = saqRowCheckedData.id;

        const saqCurrentCheckedList = state.saqRowCheckedList;
        const shouldSaqRowBeRemoved = !!(
          saqCurrentCheckedList[saqRowPaperId] &&
          saqRowCheckedData.checked === false
        );

        const finalSaqCheckedList = shouldSaqRowBeRemoved
          ? omit(saqCurrentCheckedList, saqRowPaperId)
          : {
              ...saqCurrentCheckedList,
              [saqRowPaperId]: saqRowCheckedData.checked,
            };

        return {
          ...state,
          saqRowCheckedList: finalSaqCheckedList,
        };

      case 'set search results in store':
        return {
          ...state,
          searchResults: action.data,
        };
      case 'set user in store':
        return {
          ...state,
          user: action.data,
        };
      case 'remove user from store':
        return {
          ...state,
          user: null,
        };
      default:
        return initialState;
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider };
