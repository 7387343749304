import React from 'react';
import { Grid, Typography } from '@material-ui/core';

export const PlainText = (props) => {
  const { styles, text, largeBottomMargin } = props;
  const includesSpecialTextToBeStyled = text.includes(
    'To access the site, please log in or try it free here'
  );
  return (
    <Grid
      item
      md={12}
      className={`${styles.plainTextContainer} ${includesSpecialTextToBeStyled} 
        ${largeBottomMargin && styles.marginBtmLg}`}
    >
      <Typography className={`${styles.plainText}`}>{text}</Typography>
    </Grid>
  );
};
