import React, {forwardRef, useContext, useEffect, useState} from 'react';
import axios from 'axios';
import {Checkbox, Grid, Link, makeStyles, Typography,} from '@material-ui/core';
import {useSideBarHook} from 'appLayout/components/sidebar/useSideBarHook';
import {SidebarContext} from 'appLayout/components/sidebar/SideBarContextProvider.jsx';
import {URLS} from 'constants/url';
import {globalStyles} from 'styles/globalStyles';
import {store} from 'store';
import {renderPhysiologySyllabusTableHeaders} from 'commonComponents/TableHeaders';
import Footer from 'appLayout/components/footer/Footer';

const syllabusStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  furtherInnerPointWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px 0px 0px 55px',
    alignItems: 'center',
  },
  mainHeaders: {
    fontSize: '25px',
    padding: '10px 0px 10px 30px',
  },
  rowHeaders: {
    fontSize: '25px',
    padding: '10px 0px 10px 30px',
  },
  subSectionHeaders: {
    fontSize: '18px',
    padding: '10px 0px 10px 0px',
    fontWeight: '800',
  },
  cellWrapper: {
    padding: '10px 0px 10px 30px',
    wordBreak: 'break-all',
    wordWrap: 'break-word',
  },
  tableCellWrapper: {
    padding: '0',
  },
  checkboxRoot: {
    padding: '0'
  },
  ...globalStyles,
}));

const renderSaqs = (relatedSaqs, styles, grouped = false) => {
  return relatedSaqs.map((saq, idx) => {
    if (typeof saq !== 'string') return renderSaqs(saq, styles, true);
    const paperDate = parseInt(saq.substring(0, 4));
    return paperDate > 2011 ? (
      <React.Fragment key={`relatedSaq-${saq}-${idx}`}>
        {idx === 0 && '['}
        <Link
          href={`/saqs/${saq.trim()}`}
          target={'_blank'}
          className={styles.links}
        >
          {saq}
        </Link>
        {idx < relatedSaqs.length - 1 && ', '}
        {idx === relatedSaqs.length - 1 && ']'}
        {idx === relatedSaqs.length - 1 && grouped && <br/>}
      </React.Fragment>
    ) : (
      <Typography className={styles.deadLink} key={`relatedSaq-${saq}-${idx}`}>
        {idx === 0 && '['}
        {saq}
        {idx < relatedSaqs.length - 1 && ', '}
        {idx === relatedSaqs.length - 1 && ']'}
        {idx === relatedSaqs.length - 1 && grouped && <br/>}
      </Typography>
    );
  });
};

let pageRef = React.createRef();
let listOfRefs = [];

const PhysiologySyllabus = () => {
  const globalState = useContext(store);
  const {dispatch} = globalState;
  const context = useContext(SidebarContext);

  const loggedInUser = JSON.parse(localStorage.getItem('user'));

  const [phySyllabus, setPhySyllabus] = useState({
    syllabus: [],
    sections: {},
  });

  useEffect(() => {
    if (
      phySyllabus.syllabus.length > 0 &&
      Object.keys(phySyllabus.sections).length > 0
    )
      return;
    axios
      .get(URLS.SYLLABUS__PHYSIOLOGY)
      .then((response) => {
        setPhySyllabus({
          syllabus: response.data[0].syllabus,
          sections: response.data[0].sections,
        });
        dispatch({
          type: 'set physiology syllabus in store',
          data: response.data[0].syllabus,
        });
      })
      .catch((err) => console.log(err));
  }, [
    dispatch,
    phySyllabus.sections,
    phySyllabus.syllabus.length,
  ]);

  const handleCheckboxOnChange = (event, id, dispatch) => {
    context.resetSideBarContext();
    axios
      .put(`${URLS.PHYSIOLOGY_PROGRESS}/${loggedInUser.id}`, {
        syllabusId: id,
        value: event.target.checked,
      })
      .then((response) => {
        response.data && localStorage.setItem('user', JSON.stringify(response.data));
        dispatch({
          type: 'change checked status of syllabus row',
          data: {id, checked: !!response.data.physiologyProgress[id]},
        });
        dispatch({
          type: 'update progress boolean in store',
          data: true,
        });
      })
      .catch((err) => console.log(err));
  };

  const renderSubSectionRowData = ({
    rowData,
    dispatch,
    styles,
    syllabus,
    sections,
  }) => {
    return rowData.map((data, idx) => {
      const firstTwoLettersOfHeader = data.syllabusId.substring(0, 2);
      return (
        <Grid
          container
          key={`${syllabus._id}-subSection-row-${idx}`}
          className={styles.tableCellWrapper}
        >
          {idx === 0 && (
            <Grid container key={`${syllabus._id}-subSection-header-${idx}`}>
              <Grid item md={12} className={styles.headingsContainer}>
                <Typography className={styles.h2}>
                  {`${firstTwoLettersOfHeader}. ${
                    sections[firstTwoLettersOfHeader.toUpperCase()]
                  }`}
                </Typography>
              </Grid>
            </Grid>
          )}

          <Grid container key={`${syllabus._id}-subSection-row-data-${idx}`}>
            <Grid item md={1} className={styles.cellWrapper}>
              <Checkbox
                onChange={(value) =>
                  handleCheckboxOnChange(value, data.syllabusId, dispatch)
                }
                checked={(loggedInUser && loggedInUser.physiologyProgress[data.syllabusId]) || false}
                color="primary"
                inputProps={{'aria-label': 'secondary checkbox'}}
                size={'small'}
                classes={{ root: styles.checkBoxNoPaddingAndSmRightMargin }}
              />
              {data.syllabusId}
            </Grid>
            <Grid item md={5} className={styles.cellWrapper}>
              {data.ability}
            </Grid>
            <Grid item md={1} className={styles.cellWrapper}>
              {data.level}
            </Grid>
            <Grid item md={2} className={styles.cellWrapper}>
              {data.pOneLinks &&
              data.pOneLinks.map(({title, link}, idx) => (
                <Link
                  href={`${link}`}
                  target="_blank"
                  className={styles.links}
                  key={`syllabus-pOneLink-${idx}`}
                >
                  {title}
                  {idx + 1 !== data.pOneLinks.length && ', '}
                </Link>
              ))}
            </Grid>
            <Grid item md={3} className={styles.cellWrapper}>
              {renderSaqs(data.relatedSaqs, styles, false)}
            </Grid>
          </Grid>
        </Grid>
      );
    });
  };

  const renderSingleRowContent = ({
    rowData,
    rowIdx,
    dispatch,
    styles,
  }) => {
    return (
      <Grid
        container
        key={`syllabus-header-row-${rowIdx}`}
        className={styles.tableCellWrapper}
      >
        <Grid item md={1} className={styles.cellWrapper}>
          <Checkbox
            checked={(loggedInUser && loggedInUser.physiologyProgress[rowData.syllabusId]) || false}
            onChange={(value) =>
              handleCheckboxOnChange(value, rowData.syllabusId, dispatch)
            }
            color="primary"
            disableRipple
            classes={{ root: styles.checkBoxNoPaddingAndSmRightMargin }}
          />
          {rowData.syllabusId}
        </Grid>
        <Grid item md={5} className={styles.cellWrapper}>
          {rowData.ability}
        </Grid>
        <Grid item md={1} className={styles.cellWrapper}>
          {rowData.level}
        </Grid>
        <Grid item md={2} className={styles.cellWrapper}>
          {rowData.pOneLinks &&
          rowData.pOneLinks.map(({title, link}, idx) => (
            <Link
              href={`${link}`}
              target="_blank"
              className={styles.links}
              key={`syllabus-pOneLink-${idx}`}
            >
              {`${title}`}
              {idx + 1 !== rowData.pOneLinks.length && ', '}
            </Link>
          ))}
        </Grid>
        <Grid item md={3} className={styles.cellWrapper}>
          {renderSaqs(rowData.relatedSaqs, styles, false)}
        </Grid>
      </Grid>
    );
  };

  const EmptyDivContainer = forwardRef((props, ref) => {
    return <div ref={ref} style={{height: '45px'}}/>;
  });

  const renderPhysiologySyllabusContent = (syllabus) => {
    let refs = [];
    return syllabus.map((sectionData, sectionIdx) => {
      const thisRef = React.createRef();
      refs = refs.concat(thisRef);

      let sections = sectionData.map((rowData, rowIdx) => {
        const firstLetterOfHeader = Array.isArray(rowData)
          ? rowData[0].syllabusId.substring(0, 1)
          : rowData.syllabusId.substring(0, 1);
        return (
          <Grid key={`${phySyllabus._id}-subSection-${rowIdx}`}>
            {rowIdx === 0 && (
              <Grid className={styles.headingsContainer}>
                <EmptyDivContainer
                  ref={thisRef}
                  id={`emptyContainer${sectionIdx}`}
                />
                <Typography className={styles.h1}>
                  {`${firstLetterOfHeader}. ${
                    phySyllabus.sections[firstLetterOfHeader.toUpperCase()]
                  }`}
                </Typography>
              </Grid>
            )}
            {Array.isArray(rowData)
              ? renderSubSectionRowData({
                rowData,
                dispatch,
                styles,
                syllabus: phySyllabus.syllabus,
                sections: phySyllabus.sections,
              })
              : renderSingleRowContent({
                rowData,
                rowIdx,
                dispatch,
                styles,
              })}
          </Grid>
        );
      });
      listOfRefs = refs;
      return sections;
    });
  };

  useSideBarHook(listOfRefs);
  const styles = syllabusStyles();
  return (
    <div className="syllabus">
      <Grid className={styles.root} ref={pageRef}>
        {renderPhysiologySyllabusTableHeaders(styles, phySyllabus.syllabus)}
        {renderPhysiologySyllabusContent(phySyllabus.syllabus)}
      </Grid>
      <Footer/>
    </div>
  );
};

export default PhysiologySyllabus;
