import React from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import { fullWidth } from 'pages/contact/styles';

/* eslint-disable max-len */
const GENERAL_RESOURCES = [
  [
    'Printable SAQ paper',
    'https://drive.google.com/file/d/17PWbt77Yr-auCwFwF1FFmLWzXSk27ALf/view?usp=sharing'
  ],
  [
    'Exam instructions (from CICM)',
    'https://cicm.org.au/CICM_Media/CICMSite/CICM-Website/Trainees/Assessments%20and%20Examinations/First%20Part%20Exam/T-17-(2014)-Notes-to-candidates-for-the-First-Part-Examination-Updated-20180710.pdf'
  ],
  [
    'Example written paper (from CICM)',
    'https://cicm.org.au/CICM_Media/CICMSite/CICM-Website/Trainees/Assessments%20and%20Examinations/First%20Part%20Exam/First-Part-Written-Exam-Paper-1-Example.pdf'
  ]
];

const OTHER_RESOURCES = [
  [
    'Actual SAQ answer booklet',
    'https://drive.google.com/file/d/1G1ZWodZhEtkWVFcV15HYqwv9m5kmuEJB/view?usp=sharing'
  ],
  [
    'If you fail you get a breakdown of your results & a Trainee Action Plan',
    'https://drive.google.com/file/d/1pM4kTbAX_aVyRDl8flaa_Q5HuKk7VNS_/view?usp=sharing'
  ]
];
/* eslint-enable */

const separateTextIntoTextAndLinks = (
  text,
  textStartAt,
  textStopAt,
  link,
  styles
) => (
  <>
    {text
      .split(' ')
      .slice(textStartAt, textStopAt)
      .join(' ')}{' '}
    <Link href={link} target={'_blank'} className={styles.links}>
      {text
        .split(' ')
        .slice(textStopAt, text.length - 1)
        .join(' ')}
    </Link>
  </>
);

const ExamResources = ({ useStylesWithTheme }) => {
  const styles = useStylesWithTheme()();
  return (
    <Grid container>
      <Grid item md={fullWidth} className={styles.headingsContainer}>
        <Typography className={styles.h2} id={'examResources'}>
          Exam resources
        </Typography>
      </Grid>

      <Grid item md={fullWidth} className={styles.headingsContainer}>
        <Typography className={styles.h3}>General resources:</Typography>
      </Grid>
      <Grid item md={fullWidth} className={styles.plainTextContainer}>
        <ul className={styles.unorderedList}>
          {GENERAL_RESOURCES.map(([label, href], idx) => (
            <li key={`exam-resources-${idx}`}>
              <Link href={href} target={'_blank'} className={styles.links}>
                {label}
              </Link>
            </li>
          ))}
        </ul>
      </Grid>

      <Grid item md={fullWidth} className={styles.headingsContainer}>
        <Typography className={styles.h3}>
          Here are some resources to help de-mystify the exam:
        </Typography>
      </Grid>
      <Grid item md={fullWidth} className={styles.plainTextContainer}>
        <ul className={styles.unorderedList}>
          {OTHER_RESOURCES.map(([label, href], idx) => (
            <li key={`exam-resources-${idx}`}>
              {label ===
              'If you fail you get a breakdown of your results & a Trainee Action Plan' ? (
                separateTextIntoTextAndLinks(label, 0, 6, href, styles)
              ) : label === 'Actual SAQ answer booklet' ? (
                separateTextIntoTextAndLinks(label, 0, 1, href, styles)
              ) : (
                <Link href={href} target={'_blank'} className={styles.links}>
                  {label}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </Grid>
    </Grid>
  );
};

export default ExamResources;
