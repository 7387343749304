import React, {useState} from 'react';

import { Button, Grid, Link, TextField, Typography, useTheme, } from '@material-ui/core';
import {fullWidth, generateStyles} from './styles';
import {SectionsTable} from './_sectionsTable';
import {sections} from './sectionOrder.json';
import {renderStudyPlanTableHeaders} from 'commonComponents/TableHeaders';
import { isSafari, truncateToFixed } from 'utils/helpers.js'
import moment from 'moment';
import axios from 'axios';

let dateToStartAddingFrom = 0;
let allocatedPercentageTotal = 0;

const Planner = React.forwardRef((props, ref) => {
  const [examDate, setExamDate] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [showSchedule, setShowSchedule] = useState(false);
  const [timeToExamInDays, setTimeToExamInDays] = useState(0);

  const useStylesWithTheme = generateStyles(useTheme());
  const styles = useStylesWithTheme()();

  const updateUser = async (url = '', data = {}) => {
    // Default options are marked with *
    try {
      const response = await axios({
        method: 'post',
        url,
        data,
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer
      });
      return response.data[0]; // parses JSON response into native JavaScript objects
    } catch (e) {
     console.log(e)
    }
  }

  const validateDateAndSave = () => {
    const now = moment.now();
    const momentExamDate = moment(examDate, 'YYYY-MM-DD');
    if (!!!examDate) return setErrorMsg('Date cannot be empty.');
    const earlierThanToday = momentExamDate.diff(now, 'days') < 0;
    if (earlierThanToday)
      return setErrorMsg('Date cannot be earlier than today');
    setShowSchedule(true);
    setTimeToExamInDays(momentExamDate.diff(now, 'days') + 1);
    // Update user object in db
    const user = JSON.parse(localStorage.getItem('user'))
    updateUser('users/update-exam-date', {userId: user.id, examDate})
      .then(updatedUser => {
        if (updatedUser) localStorage.setItem('user', updatedUser)
      })
      .catch(err => console.log('error', err))
  };

  const handleOnClick = () => {
    allocatedPercentageTotal = 0;
    setShowSchedule(false);
    validateDateAndSave();
  };

  return (
    <>
      <Grid item md={fullWidth} className={styles.headingsContainer}>
        <Typography className={styles.h1} ref={ref}>Planner</Typography>
      </Grid>

      <Grid item md={fullWidth} className={styles.plainTextContainer}>
        <Typography className={styles.plainText}>
          The study planner below works based on the date of your exam. I’ve
          apportioned the time to spend studying each Section of the syllabus
          (both relevant Physiology & Pharmacology) based on how frequently SAQs
          for that section have been asked since 2012. E.g., Section G
          (cardiovascular) is where you should spend most time studying, &
          Section E (cellular physiology), the least. This format encourages an
          exam-focused study plan & gives you an idea of the relative importance
          of each Section. I recommend starting with the most frequently asked
          Section & working your way through to the least. Make your study plan – & stick to it!
        </Typography>
      </Grid>
      <SectionsTable/>
      <Grid item md={fullWidth} className={styles.plainTextContainer}>
        <Typography className={styles.plainText}>
          How to use this tool:
        </Typography>
      </Grid>
      <Grid
        item
        md={fullWidth}
        className={`${styles.plainTextContainer} ${styles.marginBtmLg}`}
      >
        <ol className={styles.orderedList}>
          <li>
            Identify the likely length of time required to study for the exam
            based on the information under{' '}
            <Link href={'#studyPlanAdvice'} className={styles.links}>
              Advice
            </Link>{' '}
            on Study Plan above and choose an appropriate exam sitting based on
            this information.
          </li>
          <li>Enter your exam date (Written exam) into the planner.</li>
          <li>
            The planner will give you a suggested length of study for each
            Section of the syllabus (to study both the relevant Physiology &
            Pharmacology). It also allows for revision time.
          </li>
        </ol>
      </Grid>
      <Grid item md={fullWidth} className={`${styles.plainTextContainer}`}>
        <Typography className={styles.plainText}>
          Select the date of your written exam – note that every time you change
          it, it changes what’s on your Dashboard.
        </Typography>
      </Grid>
      <div className={styles.examDateEntryRow}>
        <TextField
          id="outlined-search"
          label="Exam Date"
          type="date"
          variant="outlined"
          value={examDate}
          onChange={(e) => {
            dateToStartAddingFrom = 0;
            setShowSchedule(false);
            setErrorMsg('');
            setExamDate(e.target.value);
          }}
          InputLabelProps={{shrink: true}}
          className={styles.textFieldStyle}
          size={'small'}
          error={!!errorMsg}
          helperText={errorMsg}
          placeholder={isSafari ? 'yyyy-mm-dd or yyyy/mm/dd' : 'dd/mm/yyyy' }
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOnClick()}
        >
          Submit
        </Button>
      </div>
      {showSchedule && (
        <div className={styles.studyPlanTableContainer}>
          <Grid item md={fullWidth}>
            <Grid item md={fullWidth} className={`${styles.timeTillExamText}`}>
              <Typography className={styles.h3}>
                {`Time from today until exam: ${timeToExamInDays} days`}
              </Typography>
            </Grid>
            <Grid item md={fullWidth} className={`${styles.copyPrintText}`}>
              <Typography>
                PLEASE COPY/PRINT YOUR STUDY PLAN AS IT WON’T BE STORED ON THE
                DATABASE:
              </Typography>
            </Grid>
            <Grid
              item
              md={fullWidth}
              style={{display: 'flex', justifyContent: 'center'}}
            >
              <Grid item md={8} style={{textAlign: 'center'}}>
                {renderStudyPlanTableHeaders(styles)}
                {sections.map((section, idx) => {
                  // boolean for first iteration
                  const isFirstIteration = idx === 0;

                  // section allocation percentage for row
                  const rowAllocationPercentage = isFirstIteration
                    ? section.allocation / 100
                    : (section.allocation + allocatedPercentageTotal) / 100;

                  const individualRowDaysToExam =
                    (section.allocation / 100) * timeToExamInDays;

                  // boolean to check if section allocation is 0
                  const isAllocationZero = section.allocation === 0;

                  /* 
                  define start date
                  if index is 0, set the startdate to moment.now
                  else set the start date to dateToStartAddingFrom
                  */
                  const now = moment()
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format('DD/MM/YYYY');

                  const startDate = isFirstIteration
                    ? now
                    : moment(dateToStartAddingFrom).format('D/M/YYYY');

                  /* 
                  define end date
                  multiply the total number of exam days by the cumulative allocated percentage
                  */
                  const daysToAddFromNow =
                    rowAllocationPercentage * timeToExamInDays;

                  const endDate = moment(now, 'DD/MM/YYYY').add(
                    daysToAddFromNow,
                    'days'
                  );

                  /* 
                  define end date in UNIX timestamp
                  to override dateToStartAddingFrom
                  */
                  // noinspection UnnecessaryLocalVariableJS
                  const endDateUnix = moment(now, 'DD/MM/YYYY')
                    .add(daysToAddFromNow, 'days')
                    .valueOf();

                  // override dateToStartAddingFrom with endDate in unix timestamp
                  dateToStartAddingFrom = endDateUnix;

                  // add to allocatedPercentageTotal
                  allocatedPercentageTotal += section.allocation;

                  return (
                    <Grid
                      key={`sectionRow${idx}`}
                      className={`${
                        idx === sections.length - 1
                          ? styles.studyPlanTableLastRowWrapper
                          : styles.studyPlanTableRowWrapper
                      }`}
                    >
                      <Grid item md={4} className={styles.plainText}>
                        {!isAllocationZero
                          ? `${startDate} - ${endDate.format('D/M/YYYY')}`
                          : ''}
                      </Grid>
                      <Grid item md={4} className={styles.plainText}>
                        {section.name}
                      </Grid>
                      <Grid item md={4} className={styles.plainText}>
                        {truncateToFixed(individualRowDaysToExam, 1)}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
});

export default Planner;
