import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

import { globalStyles } from 'styles/globalStyles';
import { renderSaqBySyllabusTableHeaders } from 'commonComponents/TableHeaders';
import { renderSaqsBySyllabus } from 'pages/search/commonComponents/RenderContent';
import SearchHeader from 'pages/search/commonComponents/SearchHeader';
import SearchContent from 'pages/search/commonComponents/SearchContent';

const searchSaqsStyles = makeStyles((theme) => ({
  ...globalStyles,
}));

const SearchSaqs = (props) => {
  const { saqs } = props;
  const classes = searchSaqsStyles();
  return (
    <>
      <SearchHeader>
        <Typography className={classes.h1}>SAQs</Typography>
      </SearchHeader>

      <SearchContent>
        {renderSaqBySyllabusTableHeaders(classes, saqs, true)}
        {renderSaqsBySyllabus(saqs)}
        {(saqs || []).length === 0 && <Typography className={classes.h3}>No SAQs found</Typography>}
      </SearchContent>
    </>
  );
};

export default SearchSaqs;
