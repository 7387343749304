export const URLS = {
    SYLLABUS__PHYSIOLOGY: '/phySyllabus',
    SYLLABUS__PHARMACOLOGY: '/pharmSyllabus',
    SAQS: '/saqs',
    SEARCH: '/search',
    PHYSIOLOGY_PROGRESS: '/users/phys',
    PHARMACOLOGY_PROGRESS: '/users/pharm',
    SAQS_PROGRESS: '/users/saqs',
    USER: '/users',
    RETRIEVE_SESSION: '/payment/retrieve-session'
}