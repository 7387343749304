import React from 'react';
import { makeStyles } from '@material-ui/core';

const flexContainerStyles = makeStyles((theme) => ({
  flexContainerDefault: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  flexContainerColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  columnAndSpaceBetween: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
}));

const FlexContainer = (props) => {
  const { column, columnAndSpaceBetween } = props;
  const styles = flexContainerStyles();

  return (
    <div
      className={
        columnAndSpaceBetween
          ? styles.columnAndSpaceBetween
          : column
          ? styles.flexContainerColumn
          : styles.flexContainerDefault
      }
    >
      {props.children}
    </div>
  );
};

FlexContainer.defaultProps = {
  column: false,
  customClass: '',
};

export default FlexContainer;
