import {makeStyles} from '@material-ui/styles';
import {globalStyles} from 'styles/globalStyles';

export const generateStyles = () =>
  makeStyles({
    main: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      fontSize: '16px',
      height: '100%'
    },
    paymentTitleContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      marginTop: '50px'
    },
    paymentTextContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center'
    },
    paddingLeftMed: {
      paddingLeft: '40px'
    },
    fullWidth: {
      width: '100%'
    },
    title: {
      marginBottom: '20px',
      marginTop: '50px'
    },
    text: {
      marginBottom: '20px'
    },
    paymentContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '50px'
    },
    dropdown: {
      padding: '20px'
    },
    paymentButton: {
      marginTop: '20px'
    },
    outlineNone: {
      outline: 'none'
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'column'
    },
    paymentPointsContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '50px'
    },
    ...globalStyles
  });

export const fullWidth = 12;
