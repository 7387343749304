import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { NAVPATH } from 'constants/navigation';
import { URLS } from 'constants/url';
import { useLocation } from 'react-router-dom';

import { fastFacts } from 'pages/resources/sections/fastFacts.json';
import { SidebarContext } from 'appLayout/components/sidebar/SideBarContextProvider';

const syllabusLetterMapping = {
  'acid base': 'J. ',
  'autonomic nervous': 'M. ',
  'body fluids & electrolytes': 'I. ',
  cardiovascular: 'G. ',
  'cellular physiology': 'E. ',
  endocrine: 'U. ',
  gastrointestinal: 'O. ',
  haematological: 'Q. ',
  'immunology & host defence': 'S. ',
  liver: 'N. ',
  microbiology: 'T. ',
  musculoskeletal: 'L. ',
  nervous: 'K. ',
  'nutrition & metabolism': 'P. ',
  obstetrics: 'V. ',
  pharmacodynamics: 'C. ',
  pharmacokinetics: 'B. ',
  'principles of measurement & equipment': 'W. ',
  'procedural anatomy': 'X. ',
  renal: 'H. ',
  respiratory: 'F. ',
  thermoregulation: 'R. ',
  'variability in drug response': 'D. '
};

const studyAdviceMenu = () => [
  'Approach to exam',
  'Reference material',
  'Adjuncts'
];

const fastFactsMenu = () => fastFacts.map(([topic]) => topic);

const saqsOrSyllabusMenu = saqOrSyllabus => saqOrSyllabus;

const sideBarMenuTypes = {
  [NAVPATH.STUDY_ADVICE]: studyAdviceMenu,
  [NAVPATH.RESOURCES__FAST_FACTS]: fastFactsMenu,
  [NAVPATH.SAQS__BY_SYLLABUS]: saqsOrSyllabusMenu,
  [NAVPATH.SAQS__BY_PAPER]: saqsOrSyllabusMenu,
  [NAVPATH.SYLLABUS__PHARMACOLOGY]: saqsOrSyllabusMenu,
  [NAVPATH.SYLLABUS__PHYSIOLOGY]: saqsOrSyllabusMenu
};

const SideBarMenu = ({ currentPath, updateSideBarContext, styles }) => {
  const handleClick = (idx, id) => () =>
    updateSideBarContext({ selected: idx, id });
  const itemsToRender = sideBarMenuTypes[currentPath]
    ? sideBarMenuTypes[currentPath]()
    : [];
  return (
    <List>
      {itemsToRender.map((item, idx) => {
        return (
          <ListItem
            button
            id={`listItem-${idx}`}
            key={`listItem-${idx}`}
            onClick={handleClick(idx, `listItem-${idx}`)}
            className={styles.listItem}
          >
            <ListItemText primary={item} classes={{ root: styles.listText }} />
          </ListItem>
        );
      })}
    </List>
  );
};

const SaqOrSyllabusSideBarMenu = ({
  currentPath,
  updateSideBarContext,
  saqOrSyllabus,
  styles
}) => {
  const handleClick = (idx, id) => () =>
    updateSideBarContext({ selected: idx, id });
  const itemsToRender = sideBarMenuTypes[currentPath](saqOrSyllabus);
  return (
    <List>
      {itemsToRender.map((item, idx) => {
        return (
          <ListItem
            button
            key={`listItem-${idx}`}
            onClick={handleClick(idx, `listItem-${idx}`)}
            className={styles.listItem}
          >
            <ListItemText primary={item} classes={{ root: styles.listText }} />
          </ListItem>
        );
      })}
    </List>
  );
};

const sideBarWidth = '180px';
const getSideBarStyles = makeStyles({
  drawer: {
    flexShrink: '0',
    width: sideBarWidth,
    overflow: 'auto'
  },
  listItem: {
    padding: '0'
  },
  listText: {
    flex: '1 1 auto',
    minWidth: 0,
    padding: '8px 0 8px 0',
    margin: '0'
  },
  paper: { width: sideBarWidth, position: 'unset', padding: '15px' }
});

const Sidebar = () => {
  const [saq, setSaq] = useState([]);
  const [paperSaqs, setPaperSaqs] = useState([]);
  const [pharmSyllabus, setPharmSyllabus] = useState([]);
  const [phySyllabus, setPhySyllabus] = useState([]);

  // helper functions
  const sortSaqsBySyllabus = saqs => {
    const saqsSyllabusSections = saqs
      .map(saq => saq.syllabusSection)
      .filter(section => section.length > 0);
    const sortedSyllabusSections = [...new Set(saqsSyllabusSections)]
      .reduce(
        (acc, curr) => [
          ...acc,
          {
            headerSection: `${syllabusLetterMapping[curr.toLowerCase()]}${curr}`
          }
        ],
        []
      )
      .sort((a, b) =>
        a.headerSection.localeCompare(b.headerSection, 'en', {
          numeric: true
        })
      )
      .map(saqObject => saqObject.headerSection);
    return sortedSyllabusSections;
  };

  const sortSaqsByPaper = saqs => {
    const sortedSaqsByPaper = saqs.sort((a, b) =>
      b.paperSaqId.localeCompare(a.paperSaqId, 'en', { numeric: true })
    );
    const sortedPaperIds = [
      ...new Set(sortedSaqsByPaper.map(saq => saq.paperSaqId.substring(0, 5)))
    ]
      .reduce(
        (acc, curr) => [
          ...acc,
          {
            saqs: saqs.filter(saq => saq.paperSaqId.substring(0, 5) === curr)
          }
        ],
        []
      )
      .sort((a, b) =>
        b.saqs[0].paperSaqId.localeCompare(a.saqs[0].paperSaqId, 'en', {
          numeric: true
        })
      )
      .map(saq => saq.saqs[0].paperSaqId.substring(0, 5));
    return sortedPaperIds;
  };

  // variables
  const location = useLocation();
  const currentPath = location.pathname;
  const styles = getSideBarStyles();
  const context = useContext(SidebarContext);
  const saqPaths = [NAVPATH.SAQS__BY_PAPER, NAVPATH.SAQS__BY_SYLLABUS];
  const pharmSyllabusPath = [NAVPATH.SYLLABUS__PHARMACOLOGY];
  const phySyllabusPath = [NAVPATH.SYLLABUS__PHYSIOLOGY];

  useEffect(() => {
    if (currentPath === NAVPATH.SAQS__BY_SYLLABUS && saq.length > 0) return;
    if (currentPath === NAVPATH.SAQS__BY_PAPER && paperSaqs.length > 0) return;
    if (
      currentPath === NAVPATH.SYLLABUS__PHARMACOLOGY &&
      pharmSyllabus.length > 0
    )
      return;
    if (currentPath === NAVPATH.SYLLABUS__PHYSIOLOGY && phySyllabus.length > 0)
      return;

    pharmSyllabusPath.includes(currentPath) &&
      axios
        .get(URLS.SYLLABUS__PHARMACOLOGY)
        .then(response => {
          const syllabusList = response.data.map(syllabus => syllabus.section);
          setPharmSyllabus(syllabusList);
        })
        .catch(err => console.log(err));

    phySyllabusPath.includes(currentPath) &&
      axios
        .get(URLS.SYLLABUS__PHYSIOLOGY)
        .then(response => {
          const syllabusList = response.data[0].syllabus;
          const sections = response.data[0].sections;
          const headers = syllabusList.map(syllabusData => {
            const syllabusId = Array.isArray(syllabusData[0])
              ? syllabusData[0][0].syllabusId
              : syllabusData[0].syllabusId;
            return `${syllabusId.substring(0, 1)}. ${
              sections[syllabusId.substring(0, 1).toUpperCase()]
            }`;
          });
          setPhySyllabus(headers);
        })
        .catch(err => console.log(err));

    saqPaths.includes(currentPath) &&
      axios
        .get(URLS.SAQS)
        .then(response => {
          if (currentPath === NAVPATH.SAQS__BY_SYLLABUS) {
            const saqsBySyllabus = sortSaqsBySyllabus(response.data);
            setSaq(saqsBySyllabus);
          }
          if (currentPath === NAVPATH.SAQS__BY_PAPER) {
            const saqsByPaper = sortSaqsByPaper(response.data);
            setPaperSaqs(saqsByPaper);
          }
        })
        .catch(err => console.log(err));
  });

  return saqPaths.includes(location.pathname) ? (
    <Drawer
      className={styles.drawer}
      variant="permanent"
      classes={{
        paper: styles.paper
      }}
    >
      <SaqOrSyllabusSideBarMenu
        currentPath={currentPath}
        {...context}
        saqOrSyllabus={
          currentPath === NAVPATH.SAQS__BY_SYLLABUS ? saq : paperSaqs
        }
        styles={styles}
      />
    </Drawer>
  ) : pharmSyllabusPath.includes(location.pathname) ||
    phySyllabusPath.includes(location.pathname) ? (
    <Drawer
      className={styles.drawer}
      variant="permanent"
      classes={{
        paper: styles.paper
      }}
    >
      <SaqOrSyllabusSideBarMenu
        currentPath={currentPath}
        {...context}
        saqOrSyllabus={
          pharmSyllabusPath.includes(location.pathname)
            ? pharmSyllabus
            : phySyllabus
        }
        styles={styles}
      />
    </Drawer>
  ) : (
    <Drawer
      className={styles.drawer}
      variant="permanent"
      classes={{
        paper: styles.paper
      }}
    >
      <SideBarMenu
        currentPath={location.pathname}
        {...context}
        styles={styles}
      />
    </Drawer>
  );
};

export default Sidebar;
