import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import Form from './_Form';
import { fullWidth, generateStyles } from './styles';

import Footer from 'appLayout/components/footer/Footer';

const Contact = ({ history }) => {
  const styles = generateStyles();
  return (
    <div className={styles.heroContainer}>
      <Grid className={styles.root}>
        <Grid className={styles.noPaddingHeadingsContainer}>
          <Typography className={styles.h1}>
            Found a mistake? Or just want to get in touch?
          </Typography>
          <Typography className={styles.plainText}>
            If reporting an error tell me where I can find it
            & provide a reference with your correction as appropriate.
          </Typography>
        </Grid>

        <Grid item md={fullWidth}>
          <Form history={history} />
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default Contact;
