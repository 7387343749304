import { makeStyles } from '@material-ui/styles';
import { globalStyles } from 'styles/globalStyles';

export const generateStyles = (theme) => (customStyles) =>
  makeStyles({
    howToUseHeroGridContainer: {
      height: '100%',
    },
    ...customStyles,
    ...globalStyles,
  });

export const fullWidth = 12;
