import React from 'react';
import { Typography, Grid, Link } from '@material-ui/core';

import { fullWidth } from 'pages/studyAdvice/styles';
import { NAVPATH } from 'constants/navigation';

const Rationale = ({ useStylesWithTheme }) => {
  const styles = useStylesWithTheme()();
  return (
    <>
      <Grid container>
        <Grid item md={fullWidth} className={styles.headingsContainer}>
          <Typography className={styles.h2}>Rationale</Typography>
        </Grid>

        <Grid item className={styles.plainTextContainer}>
          <Typography className={styles.plainText}>
            The CICM First Part syllabus is HUGE, with a deep level of knowledge
            expected in many areas. The syllabus is intimidating & many people
            will tell you it requires 1000 hrs of study. Studying for the exam
            alongside full-time work, family, other commitments & life in
            general, is stressful & exhausting. The Written is by far the larger
            hurdle than the VIVA, with ~60% & ~95% pass rates respectively.
            Therefore, this platform focuses on helping candidates pass the
            Written.
          </Typography>
          <br />
          <Typography className={styles.plainText}>
            In order to make the most efficient use of your time, I advocate
            studying almost exclusively from SAQs, ensuring that you complete
            the most frequently asked{' '}
            {
              <Link href={NAVPATH.SAQS__BY_SYLLABUS} className={styles.links}>
                SAQs
              </Link>
            }{' '}
            first. This is the bare-bones approach - the minimum that must be
            done for the exam. Those who can afford to could start by reading a
            general physiology & pharmacology textbook (e.g. Power & Kam & Peck
            & Hill) or do textbook reading around{' '}
            {
              <Link
                href={NAVPATH.SYLLABUS__PHYSIOLOGY}
                className={styles.links}
              >
                syllabus topics
              </Link>
            }{' '}
            for more thorough study. Note that I’ve based my suggestions on SAQs
            asked since 2012, for reasons stated below.
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item md={fullWidth} className={styles.headingsContainer}>
          <Typography className={styles.h3}>
            Why base my study on SAQs?
          </Typography>
        </Grid>

        <Grid item className={styles.plainTextContainer}>
          <Typography className={styles.plainText}>Several reasons:</Typography>
          <ul className={styles.unorderedList}>
            <li>
              <Typography className={styles.plainText}>
                It helps focus your study & keeps it only on topics that
                have ACTUALLY been examined. New questions may come up, but this
                is most efficient.
              </Typography>
            </li>
            <li>
              <Typography className={styles.plainText}>
                Many candidates aren’t sure how to structure their SAQs & are
                unsure what examiners want. SAQ-focused study allows you to
                practice SAQ structure right from the beginning. I advocate
                writing each SAQ under exam conditions, which allows familiarity
                with exam technique right from the beginning also.
              </Typography>
            </li>
            <li>
              <Typography className={styles.plainText}>
                SAQs are far more important than MCQs in terms of marks (40% of
                overall exam vs. 10%); most people are already used to MCQ
                technique; & studying SAQs will overlap with MCQ (and VIVA)
                content anyhow.
              </Typography>
            </li>
            <li>
              <Typography className={styles.plainText}>
                To further maximize your return on time, I also advocate
                completing{' '}
                {
                  <Link
                    href={NAVPATH.SAQS__BY_SYLLABUS}
                    className={styles.links}
                  >
                    SAQs in order of frequency of appearance
                  </Link>
                }{' '}
                – this ensures you complete highest-yield SAQs first.
              </Typography>
            </li>
          </ul>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item className={styles.headingsContainer}>
          <Typography className={styles.h3}>
            Why do you only include SAQs since 2012?
          </Typography>
        </Grid>

        <Grid item className={styles.plainTextContainer}>
          <Typography className={styles.plainText}>
            If you compare older SAQs (e.g. those from 2007) to the most recent
            ones, you will notice the question style is very different. In order
            to keep your study effective, I’ve based this site on SAQs asked
            since 2012 – the year after the first CICM First Part syllabus was
            released. There have been changes to the syllabus since then, but
            most of these have been minor, & therefore basing your study on SAQs
            since 2012 ensures your study goes back far enough, while still
            remaining relevant.
          </Typography>
        </Grid>

        <Grid item className={styles.plainTextContainer}>
          <Typography className={styles.plainText}>
            However, some SAQs prior to 2012 have been listed in the “Related
            SAQs” column of the{' '}
            {
              <Link href={NAVPATH.SAQS__BY_SYLLABUS} className={styles.links}>
                SAQs pages
              </Link>
            }
            . This is to give you further opportunity to find model answers
            online.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default Rationale;
