import React from 'react'
import Grid from '@material-ui/core/Grid';
import { generateStyles } from './styles';
import { Typography } from '@material-ui/core';

const FormSubmitSuccess = () => {
  const styles = generateStyles();
  return (
    <Grid md={12} className={`${styles.plainTextContainer} ${styles.marginTopSm}`}>
      <Typography className={styles.plainText}>Your message has been sent. Thank you for contacting us!</Typography>
    </Grid>
  )
}

export default FormSubmitSuccess
