import { makeStyles } from '@material-ui/styles';
import { globalStyles } from 'styles/globalStyles';

export const generateStyles = () =>
  makeStyles({
    main: {
      display: 'flex',
      width: '100%',
      alignItems: 'flex-start',
      flexDirection: 'column'
    },
    freeTrialTextContainer: {
      marginBottom: '50px',
      marginLeft: '40px',
      fontSize: '16px'
    },
    submitButtonContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center'
    },
    title: {
      marginTop: '50px'
    },
    text: {
      marginBottom: '20px'
    },
    paymentContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '50px'
    },
    dropdown: {
      marginBottom: '20px'
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'column'
    },
    paymentPointsContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '50px'
    },
    root: {
      marginRight: '0'
    },
    label: {
      fontSize: '14px'
    },
    leftMargin: {
      marginLeft: '5px'
    },
    ...globalStyles
  });

export const fullWidth = 12;
