import React from 'react';
import { Grid, Typography } from '@material-ui/core';

export const StandardContent = (props) => {
  const {
    styles, header, h2Header = false, text, imgSrc, imgAlt,
    includeBottomMargin = true, speciallyAddedBottomMargin
  } = props;

  const useLargeBottomMargin = includeBottomMargin && speciallyAddedBottomMargin;
  const useMediumBottomMargin = includeBottomMargin && !speciallyAddedBottomMargin;
  return (
    <Grid item md={12}
          className={
            useLargeBottomMargin
              ? styles.marginBtmXLg
              : useMediumBottomMargin
              ? styles.marginBtmMed
              : ''}>
      <Grid item md={12} className={`${styles.headingsContainer}`}>
        <Typography className={`${h2Header ? styles.h2 : styles.h3}`}>
          {header}
        </Typography>
      </Grid>
      <Grid item md={12} className={styles.plainTextContainer}>
        <span className={styles.plainText}>
          {text}
        </span>
      </Grid>
      {imgSrc && <Grid item md={12} className={styles.centeredImage}>
        <img src={imgSrc} alt={imgAlt} className={styles.imageStyle}/>
      </Grid>}
    </Grid>
  );
};

StandardContent.defaultProps = {
  speciallyAddedBottomMargin: false
}
