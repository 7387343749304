import React, {useEffect, useState} from 'react'
import {Typography, useTheme} from '@material-ui/core';
import {generateStyles} from 'pages/payment/styles';
import Footer from '../../appLayout/components/footer/Footer';
import {URLS} from '../../constants/url';
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';

const PaymentSuccess = props => {
  const sessionId = props.match.params.id;
  const useStylesWithTheme = generateStyles(useTheme());
  const styles = useStylesWithTheme();

  const [currentUser, setCurrentUser] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    setCurrentUser(user)
    const queryString = qs.stringify({sessionId, userId: user.id})
    // Retrieve session id and store in state
    axios.get(`${URLS.RETRIEVE_SESSION}?${queryString}`)
      .then(response => {
        const retrievedUser = response.data.retrievedUser
        setExpiryDate(moment(retrievedUser.subscriptionEndDate).format('DD MMM YYYY'))
        localStorage.setItem('user', JSON.stringify(retrievedUser))
        setTimeout(() => {
          props.history.push('/home')
        }, 5000)
      })
      .catch(err => setTimeout(() => {
        console.log('Error retrieving stripe session', err)
        props.history.push('/home')
      }, 5000))
  }, [props.history, sessionId])

  return (
    <div className={styles.main}>
      <div className={styles.paymentTitleContainer}>
        {expiryDate ? <Typography
          className={styles.text}>{`Thank you ${currentUser && currentUser.username}, 
          your payment is successful.`}
        </Typography> : <Typography
          className={styles.text}>{'Invalid session ID: ' + sessionId}
        </Typography>}
      </div>

      {
        expiryDate && (
          <div className={styles.paymentTextContainer}>
            <Typography className={styles.text}>{`Your renewed subscription will end on ${expiryDate}.`}</Typography>
          </div>
        )
      }


      <br/>

      <div className={styles.paymentTextContainer}>
        <Typography className={styles.text}>Please wait while we redirect you to the home page...</Typography>
      </div>

      <div className={styles.footerContainer}>
        <Footer/>
      </div>
    </div>
  )
}

export default PaymentSuccess
