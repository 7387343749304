import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import tableSectionImportance from './tableSectionImportance.png';

const useStyles = makeStyles({
  imageContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    overflowX: 'unset',
    justifyContent: 'center',
    margin: '40px 0',
  },
  imageStyle: {
    height: '500px'
  },
});

export const SectionsTable = () => {
  const classes = useStyles();

  return (
    <div className={classes.imageContainer}>
      <img
        className={classes.imageStyle}
        src={tableSectionImportance}
        alt="Exam Technique Table"
      />
    </div>
  );
};
