import React from 'react';
import { Route } from 'react-router-dom';
import Sidebar from 'appLayout/components/sidebar/Sidebar';

export const SidebarContext = React.createContext({
  sidebarMenu: null
});

export class SidebarProvider extends React.Component {
  updateSideBarContext = next => this.setState(next);

  resetSideBarContext = () =>
    this.setState({
      selected: 0,
      id: ''
    });

  state = {
    selected: 0,
    id: 'initialId',
    updateSideBarContext: this.updateSideBarContext,
    resetSideBarContext: this.resetSideBarContext
  };

  render() {
    return (
      <SidebarContext.Provider value={this.state}>
        <Route path={this.props.renderSidebarOnPaths}>
          <Sidebar />
        </Route>
        {this.props.children}
      </SidebarContext.Provider>
    );
  }
}
