import React from 'react';
import { Grid, Typography, useTheme } from '@material-ui/core';
import { uuid } from 'uuidv4';

import { fastFacts } from 'pages/resources/sections/fastFacts.json';
import { useSideBarHook } from 'appLayout/components/sidebar/useSideBarHook';
import { generateStyles } from 'pages/resources/styles';

import bloodDistribution from 'images/bloodDistribution.png';
import co2Carriage from 'images/co2Carriage.png';
import o2Cascade from 'images/o2Cascade.png';

import Footer from 'appLayout/components/footer/Footer';

const fastFactStyles = {
  topicSection: { paddingLeft: '30px' },
  imageWrapper: {
    width: '100%',
    textAlign: 'center',
  },
  imageStyle: {
    height: 'auto',
    width: '640px',
  },
  main: {
    marginTop: '20px'
  }
};

let pageRef = React.createRef();
let listOfRefs = [];

const FAST_FACTS_IMAGES = [
  'img: bloodDistribution.png',
  'img: co2Carriage.png',
  'img: o2Cascade.png',
];

const FAST_FACTS_IMAGES_MAPPING = {
  bloodDistribution,
  co2Carriage,
  o2Cascade,
};

const ListSection = ({
  listOfFacts,
  topicKey,
  styles,
  parentFactKey,
  isNotFirstIteration,
}) => {
  return listOfFacts.map((eachFact, factIdx) => {
    let key = parentFactKey
      ? parentFactKey
      : `topic-${topicKey}-fact${factIdx}`;

    if (typeof eachFact === 'string') {
      if (FAST_FACTS_IMAGES.includes(eachFact)) {
        return (
          <div key={uuid()} className={styles.imageWrapper}>
            <img
              className={styles.imageStyle}
              src={
                FAST_FACTS_IMAGES_MAPPING[
                  eachFact.substring(5, eachFact.length - 4)
                ]
              }
              alt="Exam Technique Table"
            />
          </div>
        );
      } else {
        return <li key={`individualfact-${factIdx}`}>{eachFact}</li>;
      }
    }

    const ulClassName = isNotFirstIteration
      ? styles.unorderedListNestingLevelTwo
      : styles.unorderedList;
    return (
      <ul className={ulClassName} key={`${key}-subfact${factIdx}-${eachFact}`}>
        <ListSection
          listOfFacts={eachFact}
          parentFactKey={`${key}-subfact${factIdx}`}
          styles={styles}
          isNotFirstIteration
        />
      </ul>
    );
  });
};

const _FastFacts = () => {
  const useStylesWithTheme = generateStyles(useTheme());
  const styles = useStylesWithTheme(fastFactStyles)();
  useSideBarHook(listOfRefs);

  const generateFastFacts = () => {
    let refs = [];
    let fastFactJsx = fastFacts.map(
      ([subSectionTopic, ...listOfFacts], topicKey) => {
        let thisRef = React.createRef();
        refs = refs.concat(thisRef);
        return (
          <Grid
            ref={pageRef}
            key={`fast-facts-subsection-${topicKey}`}
            container
            id="fastFacts-page"
          >
            <Grid item className={styles.headingsContainer} md={12}>
              <Typography ref={thisRef} className={styles.h1}>
                {subSectionTopic}
              </Typography>
            </Grid>
            <Grid item className={styles.plainTextContainer}>
              <ListSection
                listOfFacts={listOfFacts}
                topicKey={topicKey}
                styles={styles}
              />
            </Grid>
          </Grid>
        );
      }
    );
    listOfRefs = refs;
    return fastFactJsx;
  };

  return (
    <>
      <Grid ref={pageRef} className={styles.main} container>
        {generateFastFacts()}
      </Grid>
      <Footer />
    </>
  );
};

export default _FastFacts;
