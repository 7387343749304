import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { Checkbox, Grid, Link, makeStyles, Typography, } from '@material-ui/core'
import { useSideBarHook } from 'appLayout/components/sidebar/useSideBarHook'
import { SidebarContext } from 'appLayout/components/sidebar/SideBarContextProvider.jsx'
import { URLS } from 'constants/url'
import { areAllSaqsBefore2012, sortRelatedSaqsForPaperAndSyllabus } from 'utils/helpers'
import { globalStyles } from 'styles/globalStyles'
import { renderSaqBySyllabusTableHeaders } from 'commonComponents/TableHeaders'
import { store } from 'store'
import Footer from 'appLayout/components/footer/Footer'

// Syllabus Letters Mapping
const syllabusLetterMapping = {
  'acid base': 'J. ',
  'autonomic nervous': 'M. ',
  'body fluids & electrolytes': 'I. ',
  cardiovascular: 'G. ',
  'cellular physiology': 'E. ',
  endocrine: 'U. ',
  gastrointestinal: 'O. ',
  haematological: 'Q. ',
  'immunology & host defence': 'S. ',
  liver: 'N. ',
  microbiology: 'T. ',
  musculoskeletal: 'L. ',
  nervous: 'K. ',
  'nutrition & metabolism': 'P. ',
  obstetrics: 'V. ',
  pharmacodynamics: 'C. ',
  pharmacokinetics: 'B. ',
  'principles of measurement & equipment': 'W. ',
  'procedural anatomy': 'X. ',
  renal: 'H. ',
  respiratory: 'F. ',
  thermoregulation: 'R. ',
  'variability in drug response': 'D. ',
}

const saqStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  saqTableWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 0 0 0',
    position: 'sticky',
    backgroundColor: '#d3d3d3',
    height: '45px',
    zIndex: 9,
    top: 0,
  },
  cellWrapper: {
    padding: '0px 0px 0px 30px',
    wordBreak: 'break-all',
    wordWrap: 'break-word',
  },
  tableCellWrapper: {
    padding: '0',
    marginBottom: '20px',
  },
  link: {
    margin: theme.spacing(0),
    fontSize: '14px',
    color: 'black',
  },
  ...globalStyles,
}))

let pageRef = React.createRef()
let listOfRefs = []
const SaqBySyllabus = () => {
  const globalState = useContext(store)
  const { dispatch } = globalState
  const [saq, setSaqs] = useState(null)
  const context = useContext(SidebarContext)
  const user = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    const abortController = new AbortController()
    if (saq) return
    axios
      .get(URLS.SAQS)
      .then((response) => {
        const saqs = response.data.filter((saq) => saq.syllabusSection)
        const saqsWithoutDuplicates = Array.from(
          new Set(
            saqs
              .map((saq) => saq.paperSaqId)
              .map((id) => saqs.find((saq) => saq.paperSaqId === id))
          )
        )
        const sortedSaqsBySyllabus = saqsWithoutDuplicates.sort((a, b) =>
          a.syllabusSection.localeCompare(b.syllabusSection, 'en', {
            numeric: true,
          })
        )
        const saqsWithHeaders = [
          ...new Set(sortedSaqsBySyllabus.map((saq) => saq.syllabusSection)),
        ]
          .reduce(
            (acc, curr) => [
              ...acc,
              {
                saqs: saqsWithoutDuplicates.filter(
                  (saq) => saq.syllabusSection === curr
                ),
                headerSection: `${
                  syllabusLetterMapping[curr.toLowerCase()]
                }${curr}`,
              },
            ],
            []
          )
          .sort((a, b) =>
            a.headerSection.localeCompare(b.headerSection, 'en', {
              numeric: true,
            })
          )

        // Add the saq count to each individual saq
        const saqsWithCount = saqsWithHeaders.map((header) => ({
          headerSection: header.headerSection,
          saqs: header.saqs.map((saq) => {
            const rSaqs = saq.relatedSaqs.flat(Infinity)
            const rSaqsWithoutDuplicates = [...new Set(rSaqs)]
            const removeIrrelevantSaqs = rSaqsWithoutDuplicates.filter(
              (rSaq) => parseInt(rSaq.substring(0, 4)) > 2011
            )
            return {
              ...saq,
              relatedSaqsCount: removeIrrelevantSaqs.length,
            }
          }),
        }))

        // Sort by related saqs count and then by year
        const saqsSortedByCountAndYear = saqsWithCount.map((header) => ({
          headerSection: header.headerSection,
          saqs: header.saqs
            .sort((a, b) => {
              const isALaterYearResult = b.paperSaqId.localeCompare(a.paperSaqId, 'en', { numeric: true })
              if ((a.relatedSaqsCount < b.relatedSaqsCount)) {
                return 1
              }
              if (a.relatedSaqsCount > b.relatedSaqsCount) {
                return -1
              }
              if (isALaterYearResult === 1) return 1
              if (isALaterYearResult === -1) return -1
              return 0
            })
            .map(saq => sortRelatedSaqsForPaperAndSyllabus(saq))
        }))

        return setSaqs(saqsSortedByCountAndYear)
      })
      .catch((err) => console.log(err))
    return () => {
      abortController.abort()
    }
  }, [dispatch, saq, user])
  useSideBarHook(listOfRefs)

  const findSaqProgressByPaperSaqId = (paperSaqId) => {
    if (user) {
      return user.saqsProgress[paperSaqId]
    }
    return false
  }

  const handleCheckboxOnChange = (event, id, dispatch) => {
    context.resetSideBarContext()
    axios
      .put(`${URLS.SAQS_PROGRESS}/${user.id}`, {
        paperSaqId: id,
        value: event.target.checked,
      })
      .then((response) => {
        response.data && localStorage.setItem('user', JSON.stringify(response.data))
        dispatch({
          type: 'change checked status of saq row',
          data: { id, checked: !!response.data.saqsProgress[id] },
        })
        dispatch({
          type: 'update progress boolean in store',
          data: true,
        })
      })
      .catch((err) => console.log(err))
  }

  const renderSaqs = (saqs, rowSaqId, styles, grouped = false) => {
    const filteredSaqs = saqs.filter((saq) => saq !== rowSaqId)
    return filteredSaqs.map((saq, idx) => {
      if (typeof saq !== 'string')
        return renderSaqs(saq, rowSaqId, styles, true)
      const paperDate = parseInt(saq.substring(0, 4))
      return paperDate > 2011 ? (
        <React.Fragment key={`relatedSaq-${saq}-${idx}`}>
          {idx === 0 && '['}
          <Link href={`${saq.trim()}`} target="_blank" className={styles.links}>
            {saq}
          </Link>
          {idx < filteredSaqs.length - 1 && ', '}
          {idx === filteredSaqs.length - 1 && ']'}
          {idx === filteredSaqs.length - 1 && grouped && <br/>}
        </React.Fragment>
      ) : (
        <React.Fragment key={`relatedSaq-${saq}-${idx}`}>
          {idx === 0 && '['}
          <span className={styles.deadLink}>{saq}</span>
          {idx < filteredSaqs.length - 1 && ', '}
          {idx === filteredSaqs.length - 1 &&
            filteredSaqs.length > 1 &&
            !areAllSaqsBefore2012(filteredSaqs) &&
            ']'}
          {idx === filteredSaqs.length - 1 &&
            filteredSaqs.length > 1 &&
            areAllSaqsBefore2012(filteredSaqs) &&
            ']'}
          {idx === filteredSaqs.length - 1 && filteredSaqs.length === 1 && ']'}
          {idx === filteredSaqs.length - 1 && grouped && <br/>}
        </React.Fragment>
      )
    })
  }

  const getSyllabusLetterFromTitle = (syllabusTitle) =>
    syllabusLetterMapping[syllabusTitle.toLowerCase()]

  const renderAllSaqRows = (saq) => {
    let refs = []
    return (
      saq &&
      saq.map((section, sectionIdx) => {
        let sections = section.saqs.map((saqRowData, idx) => {
          let thisRef
          if (idx === 0) {
            thisRef = React.createRef()
            refs = refs.concat(thisRef)
          }
          return (
            <React.Fragment key={saqRowData._id}>
              <Grid container>
                {idx === 0 && (
                  <Grid className={styles.headingsContainer}>
                    {sectionIdx !== 0 && (<div style={{ height: '45px' }} ref={thisRef}/>)}
                    {sectionIdx === 0 && (
                      <>
                        <div ref={thisRef}/>
                        <div className={styles.plainTextContainer}>
                          <Typography className={styles.plainText}>
                            Most frequent SAQs listed first.
                          </Typography>
                        </div>
                      </>
                    )}
                    <Typography className={styles.h1}>
                      {(getSyllabusLetterFromTitle(saqRowData.syllabusSection) ||
                        '') + saqRowData.syllabusSection}
                    </Typography>
                  </Grid>
                )}
                <Grid container className={styles.tableCellWrapper}>
                  <Grid item md={2} className={styles.cellWrapper}>
                    <Checkbox
                      disableRipple
                      color={'primary'}
                      onChange={(event) =>
                        handleCheckboxOnChange(
                          event,
                          saqRowData.paperSaqId,
                          dispatch
                        )
                      }
                      checked={!!findSaqProgressByPaperSaqId(saqRowData.paperSaqId)}
                      classes={{ root: styles.checkBoxNoPaddingAndSmRightMargin }}
                    />
                    {saqRowData.paperSaqId}
                  </Grid>
                  {/* <Grid item md={2} className={styles.cellWrapper}>
                  {saqRowData.syllabusSection}
                </Grid> */}
                  <Grid item md={5} className={styles.cellWrapper}>
                    {saqRowData.question}
                  </Grid>
                  <Grid item md={1} className={styles.cellWrapper}>
                    {saqRowData.erLink.map((link, idx) => (
                      <Link
                        key={`erLink${idx}`}
                        href={`${link}`}
                        target="_blank"
                        className={styles.links}
                      >
                        ER
                      </Link>
                    ))}
                  </Grid>
                  <Grid item md={1} className={styles.cellWrapper}>
                    {saqRowData.cwLinks.map((link, idx) => (
                      <Link
                        key={`cwLink${idx}`}
                        href={`${link}`}
                        target="_blank"
                        className={styles.links}
                      >
                        CW
                      </Link>
                    ))}
                  </Grid>
                  <Grid item md={1} className={styles.cellWrapper}>
                    {saqRowData.ippLinks.map((link, idx) => (
                      <Link
                        key={`ippLink${idx}`}
                        href={`${link}`}
                        target="_blank"
                        className={styles.links}
                      >
                        IPP
                      </Link>
                    ))}
                  </Grid>
                  <Grid item md={2} className={styles.cellWrapper}>
                    {renderSaqs(
                      saqRowData.relatedSaqs,
                      saqRowData.paperSaqId,
                      styles,
                      false
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          )
        })
        listOfRefs = refs
        return sections
      })
    )
  }

  const styles = saqStyles()
  return (
    <div className="syllabus">
      <Grid container className={styles.root} ref={pageRef}>
        {renderSaqBySyllabusTableHeaders(styles, saq)}
        {renderAllSaqRows(saq)}
      </Grid>
      <Footer/>
    </div>
  )
}

export default SaqBySyllabus
