import React from 'react';
import { Typography, Grid, Link } from '@material-ui/core';

import { fullWidth } from 'pages/studyAdvice/styles.js';
import { NAVPATH } from 'constants/navigation';

const GeneralStudyAdvice = ({ useStylesWithTheme }) => {
  const styles = useStylesWithTheme()();
  return (
    <>
        <Grid item md={fullWidth} className={styles.headingsContainer}>
        <Typography className={styles.h2}>General study advice</Typography>
      </Grid>

      <Grid container className={styles.plainTextContainer}>
        <Grid item>
          <Typography className={styles.plainText}>
            You will know what works for you, but in general:
          </Typography>
          <ul className={styles.unorderedList}>
            <li>
              <Typography className={styles.plainText}>
                I strongly recommend studying with others (study group,
                tutorials, courses). Individual study time should definitely
                form the foundation of your study but studying with others is
                helpful because:
              </Typography>
            </li>

            <ul className={styles.unorderedListNestingLevelTwo}>
              <li>
                <Typography className={styles.plainText}>
                  Tutorials & courses allow contact with CICM examiners who will
                  give invaluable advice on the Written & VIVA. They also allow
                  you to gauge where other candidates are positioned in comparison
                  (serves as a reality-check to assess your progress & readiness to sit the exam).
                </Typography>
              </li>
              <li>
                <Typography className={styles.plainText}>
                  You can help each other understand difficult concepts.
                </Typography>
              </li>
              <li>
                <Typography className={styles.plainText}>
                  It helps keep you motivated.
                </Typography>
              </li>
              <li>
                <Typography className={styles.plainText}>
                  It helps you keep in the loop about changes to the Primary
                  exam process.
                </Typography>
              </li>
              <li>
                <Typography className={styles.plainText}>
                  It’s useful for moral support – Primary study is an emotional
                  rollercoaster!
                </Typography>
              </li>
            </ul>

            <li>
              <Typography className={styles.plainText}>
                Try to complete as many SAQs as possible from the last 5 years at
                least. Use the{' '}
                <Link href={NAVPATH.SAQS__BY_SYLLABUS} className={styles.links}>
                  SAQ checklist
                </Link>{' '}
                to monitor your progress.
              </Typography>
            </li>

            <li>
              <Typography className={styles.plainText}>
                Study as regularly as possible & at any opportunity available –
                at work, on the train, waiting at the doctor’s, etc. repetition
                is KEY.
              </Typography>
            </li>

            <li>
              <Typography className={styles.plainText}>
                Definitely study on days off. When you’re working a day shift –
                try to study before or after the shift. When you’re working a
                night shift – I think it’s almost impossible to study…
              </Typography>
            </li>

            <li>
              <Typography className={styles.plainText}>
                Reinforce your study by grabbing whoever will listen &
                explaining concepts to them. This may be medical students or
                nurses at work, your partner, etc.
              </Typography>
            </li>

            <li>
              <Typography className={styles.plainText}>
                You need recharge time – I found it better to get study out of
                the way at the beginning of the day when I was concentrating
                best, & then relax in the evening. Make sure you allow time for
                sleep & doing things that help you stay sane.
              </Typography>
            </li>

            <li>
              <Typography className={styles.plainText}>
                Don’t get caught up in minutia – rather than laboring over a
                small point, sometimes it’s just better to accept & move on.
                The syllabus is huge!
              </Typography>
            </li>

            <li>
              <Typography className={styles.plainText}>
                Interval timers are great – I used to study in 1 hr blocks,
                with 50 min study followed by a 10 min break. I’d repeat this
                4-6x on days I wasn’t working (~4-6 hrs study!)
              </Typography>
            </li>

            <li>
              <Typography className={styles.plainText}>
                Aim to sit the exam once & therefore study seriously - failing
                can set you back more than you realise.
              </Typography>
            </li>
          </ul>
        </Grid>
      </Grid>

      <Grid container className={styles.plainTextContainer}>
        <Grid item>
          <Typography className={styles.plainText}>
            Don’t be afraid to take time off from full-time work to study – I
            did this and found it hugely beneficial because:
          </Typography>
          <ul className={styles.generalAdviceUnorderedListNestingLevelTwo}>
            <li>
              <Typography className={styles.plainText}>
                It keeps you sane amongst work and all your other commitments.
              </Typography>
            </li>
            <li>
              <Typography className={styles.plainText}>
                You can focus completely on study so you have the best chance of
                passing the first time.
              </Typography>
            </li>
            <li>
              <Typography className={styles.plainText}>
                None of your ICU time counts to the program until you’ve
                completed the Primary anyway (but anesthetic, medical & elective time does).
              </Typography>
            </li>
            <li>
              <Typography className={styles.plainText}>
                You can locum – you work less but earn the same, you can avoid
                doing ANY night shifts, you have a flexible schedule that allows
                you to attend study group/tutorials/courses, & you can
                cherry-pick less tiring shifts.
              </Typography>
            </li>
          </ul>
        </Grid>
      </Grid>
    </>
  );
};

export default GeneralStudyAdvice;
