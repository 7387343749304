import { makeStyles } from '@material-ui/styles';
import { globalStyles } from 'styles/globalStyles'

export const generateStyles = theme => (
  customStyles => makeStyles({
    section: {
      marginBottom: '50px'
    },
    sectionHeader: {
      marginBottom: '20px',
      paddingLeft: '20px'
    },
    sectionSubHeader: {
      marginBottom: '30px',
      paddingLeft: '50px'
    },
    subtitle1: {
      color: '#990000'
    },
    subheaderText: {
      color: 'blue'
    },
    body1: {
      margin: '1em 0'
    },
    interSectionBottomMargin: {
      marginBottom: '20px'
    },
    interParagraphBottomMargin: {
      marginBottom: '10px'
    },
    listBody: theme.typography.body1,
    listHeader: {
      fontWeight: 'bold'
    },
    ...customStyles,
    ...globalStyles
  })
);

export const fullWidth = 12;
