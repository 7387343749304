import { makeStyles } from '@material-ui/styles';
import { globalStyles } from 'styles/globalStyles';

export const generateStyles = theme => customStyles =>
  makeStyles({
    ...customStyles,
    ...globalStyles
  });

export const fullWidth = 12;
