import moment from 'moment';
import axios from 'axios';
import browser from 'browser-detect';

export const areAllSaqsBefore2012 = (saqs) => {
  const saqYears = saqs.map((saqString) => parseInt(saqString.substring(0, 4)));
  return saqYears.filter((year) => year > 2011).length === 0;
};

export const truncateToFixed = (num, fixed) => {
  const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
  return num.toString().match(re)[0];
};

export const generateRandomNumberForSaq = (saqs, randNumberList) => {
  const randomNumber = Math.floor(Math.random() * saqs.length);
  if (randNumberList.includes(randomNumber))
    generateRandomNumberForSaq(saqs, randNumberList);
  return randomNumber;
};

export const shuffle = (listToShuffle) => {
  // noinspection StatementWithEmptyBodyJS
  for (
    var j, x, i = listToShuffle.length;
    i;
    j = parseInt(Math.random() * i),
      x = listToShuffle[--i],
      listToShuffle[i] = listToShuffle[j],
      listToShuffle[j] = x
  ) ;
  return listToShuffle;
};

export const unpackPoints = (points) =>
  points.map((point) =>
    point.innerPoints
      ? [
        {
          title: point.title,
          level: point.level,
          p1Links: point.p1Links,
          relatedSaqs: point.relatedSaqs,
        },
        ...unpackPoints(point.innerPoints),
      ]
      : point
  );

export const fetchUser = async (user, dispatch, localStorage) => {
  const parsedUser = JSON.parse(user);
  try {
    const options = {
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer
    }

    const response = await axios.get(`users/${parsedUser.id}`, options);

    const fetchedUser = response.data[0]
    if (fetchedUser) {
      localStorage.setItem('user', JSON.stringify(fetchedUser))
      dispatch({
        type: 'set user in store',
        data: fetchedUser,
      });
      dispatch({
        type: 'set checked status of all physiology syllabus',
        data: fetchedUser.physiologyProgress,
      });
      dispatch({
        type: 'set checked status of all pharmacology syllabus',
        data: fetchedUser.pharmacologyProgress,
      });
      dispatch({
        type: 'set checked status of all saqs',
        data: fetchedUser.saqsProgress,
      });
      dispatch({
        type: 'update progress boolean in store',
        data: false,
      });
    }

    return fetchedUser;
  } catch (e) {
    return {
      error: e,
      message: 'Something went wrong'
    }
  }

}

export const storeUserInfoInStore = (user, globalState) => {
  const {dispatch} = globalState;
  dispatch({
    type: 'set user in store',
    data: user,
  });
  dispatch({
    type: 'set checked status of all physiology syllabus',
    data: user.physiologyProgress,
  });
  dispatch({
    type: 'set checked status of all pharmacology syllabus',
    data: user.pharmacologyProgress,
  });
  dispatch({
    type: 'set checked status of all saqs',
    data: user.saqsProgress,
  });
  dispatch({
    type: 'update progress boolean in store',
    data: false,
  });
}

export const hasFreeTrialDateButHasExpired = user => {
  const hasFreeTrialEndDate = !!user.trialEndDate
  // const trialEndDateMoment = moment(user.trialEndDate).subtract(15, 'days');
  const trialEndDateMoment = moment(user.trialEndDate);
  const today = moment();
  const differenceInDays = trialEndDateMoment.diff(today, 'days')
  const hasFreeTrialExpired = differenceInDays < 0
  return hasFreeTrialEndDate && hasFreeTrialExpired
}

export const hasSubscriptionDateButHasExpired = user => {
  const hasSubscriptionEndDate = !!user.subscriptionEndDate
  // const subscriptionEndDateMoment = moment(user.subscriptionEndDate).subtract(10, 'months')
  const subscriptionEndDateMoment = moment(user.subscriptionEndDate)
  const today = moment();
  const differenceInDays = subscriptionEndDateMoment.diff(today, 'days')
  const hasSubscriptionExpired = differenceInDays < 0
  return hasSubscriptionEndDate && hasSubscriptionExpired
}

export const shouldUserBeDirectedToPaymentPage = user => {
  if (!user) {
    return false
  }
  const hasSubscriptionEndDate = !!user.subscriptionEndDate
  if (!hasSubscriptionEndDate) return hasFreeTrialDateButHasExpired(user)
  return hasFreeTrialDateButHasExpired(user) && hasSubscriptionDateButHasExpired(user)
}

export const sortRelatedSaqsForPaperAndSyllabus = saq => {
  const relatedSaqs = saq.relatedSaqs;
  if (typeof relatedSaqs[0] !== 'string') {
    const sortedMultipleRelatedSaqs = relatedSaqs.map(
      relatedSaqList => sortRelatedSaqsByLatestYear(relatedSaqList)
    )
    return {
      ...saq,
      relatedSaqs: sortedMultipleRelatedSaqs
    };
  }
  else {
    return {
      ...saq,
      relatedSaqs: sortRelatedSaqsByLatestYear(saq.relatedSaqs)
    }
  }
}

export const sortRelatedSaqsByLatestYear = relatedSaqsList => {
  if (relatedSaqsList.length < 1) return
  return relatedSaqsList.sort((a, b) => {
    if(a.localeCompare(b, 'en', { numeric: true }) < 0) {
      return 1;
    }
    if(a.localeCompare(b, 'en', { numeric: true }) > 0) {
      return -1;
    }
    return 0;
  })
}

export const isJsonString = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const isDev = () => {
  console.log('env', process.env.NODE_ENV);
  return process.env.NODE_ENV === 'development'
}

export const isSafari = browser().name.toLowerCase() === 'safari'
