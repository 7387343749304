import React, { useContext, useEffect } from 'react';
import { Grid, Link, Typography, useTheme } from '@material-ui/core';

import { fullWidth, generateStyles } from 'pages/howToUse/styles';
import { NAVPATH } from 'constants/navigation';
import Footer from 'appLayout/components/footer/Footer';
import FlexContainer from 'commonComponents/FlexContainer';
import { fetchUser } from 'utils/helpers';
import { store } from 'store';

const HowToUse = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const loggedInUser = localStorage.getItem('user')

  useEffect(() => {
    if(!state.user && loggedInUser) fetchUser(loggedInUser, dispatch, localStorage)
  })

  const generateLink = (name, target) => (
    <Link href={target} className={styles.links}>
      {name.trim()}
    </Link>
  );

  const useStylesWithTheme = generateStyles(useTheme());
  const styles = useStylesWithTheme()();
  return (
    <Grid container className={styles.howToUseHeroGridContainer}>
      <FlexContainer columnAndSpaceBetween>
        <div>
          <Grid item md={fullWidth} className={`${styles.headingsContainer} ${styles.marginTopSm}`}>
            <Typography className={styles.h3}>
              How to use this platform:
            </Typography>
          </Grid>


          <ol className={styles.howToUseOrderedList}>

            <Grid
              item
              md={fullWidth}
              className={styles.noPaddingHeadingsContainer}
            >
              <li>
                Read {generateLink('Study advice', NAVPATH.STUDY_ADVICE)} for:
              </li>
              <ul className={styles.unorderedListNestingLevelTwo}>
                <li>
                  Advice on study & the exam
                </li>
                <li>
                  A list of what resources I found useful
                </li>
                <li>
                  Some CICM exam documents that help de-mystify the whole process
                </li>
                <li>
                  A list of First Part courses (I may have missed some),
                  tutorial groups & candidates who are looking for study group members
                </li>
              </ul>
            </Grid>

            <Grid
              item
              md={fullWidth}
              className={styles.noPaddingHeadingsContainer}
            >
              <li>
                Use {generateLink('Study Plan', NAVPATH.STUDY_PLAN)} to create a
                systematic study plan.
              </li>
            </Grid>

            <Grid
              item
              md={fullWidth}
              className={styles.noPaddingHeadingsContainer}
            >
              <li>Choose your style:</li>
              <ul className={styles.unorderedListNestingLevelTwo}>
                <li>
                  I just want to do the essentials to pass – use&nbsp;
                  {generateLink('SAQs by syllabus', NAVPATH.SAQS__BY_SYLLABUS)},
                  where SAQs are listed in order of frequency, to complete
                  highest-yield SAQs for each section of the syllabus.
                </li>
                <li>
                  I have a little more time – still use&nbsp;
                  {generateLink('SAQs by syllabus', NAVPATH.SAQS__BY_SYLLABUS)}&nbsp;
                  to complete highest-yield SAQs, but also use the&nbsp;
                  {generateLink(
                    'Syllabus physiology',
                    NAVPATH.SYLLABUS__PHYSIOLOGY
                  )}
                  &nbsp;&&nbsp;
                  {generateLink('pharmacology', NAVPATH.SYLLABUS__PHARMACOLOGY)}&nbsp;
                  pages to read up on each syllabus Ability.
                </li>
              </ul>
            </Grid>

            <Grid
              item
              md={fullWidth}
              className={styles.noPaddingHeadingsContainer}
            >
              <li>
                Check off past {generateLink('SAQs', NAVPATH.SAQS__BY_SYLLABUS)}&nbsp;
                or&nbsp;
                {generateLink('syllabus Abilities', NAVPATH.SYLLABUS__PHYSIOLOGY)} to
                monitor your progress & keep you on track. You’ll need to login to do this.
              </li>
              <ul className={styles.unorderedListNestingLevelTwo}>
                <li>
                  Your progress through your study time, the syllabus & past
                  SAQs will be shown on your Dashboard.
                </li>
              </ul>
            </Grid>

            <Grid
              item
              md={fullWidth}
              className={styles.noPaddingHeadingsContainer}
            >
              <li>
                Use&nbsp;
                {generateLink(
                  'Resources > Fast Facts',
                  NAVPATH.RESOURCES__FAST_FACTS
                )}
                , {generateLink('Equations', NAVPATH.RESOURCES__EQUATIONS)} &&nbsp;
                {generateLink('Diagrams', NAVPATH.RESOURCES__DIAGRAMS)} for
                reference & in your day-to-day study as flashcards.
              </li>
            </Grid>

            <Grid
              item
              md={fullWidth}
              className={styles.noPaddingHeadingsContainer}
            >
              <li>
                Use&nbsp;
                {generateLink(
                  'Resources > Templates',
                  NAVPATH.RESOURCES__TEMPLATES
                )}&nbsp;
                to give you default templates to use for SAQs – particularly
                helpful for SAQs you haven’t seen before.
              </li>
            </Grid>

            <Grid
              item
              md={fullWidth}
              className={styles.noPaddingHeadingsContainer}
            >
              <li>
                Use&nbsp;
                {generateLink(
                  'Resources > Random paper',
                  NAVPATH.RESOURCES__RANDOM_PAPER
                )}&nbsp;
                to generate a list of 10 random SAQs to do under exam
                conditions, just prior to sitting the Written.
              </li>
            </Grid>
          </ol>
        </div>
        <div>
          <Footer/>
        </div>
      </FlexContainer>
    </Grid>
  );
};

export default HowToUse;
