import React, { useEffect, useState } from 'react';
import qs from 'qs';
import axios from 'axios';

import { URLS } from 'constants/url';

import SearchSaqs from './_SearchSaqs';
import SearchPhySyllabus from './_SearchPhySyllabus';
import SearchPharmSyllabus from './_SearchPharmSyllabus';
import SearchSectionContainer from './commonComponents/SearchSectionContainer';
import { Typography } from '@material-ui/core';
import FlexContainer from 'commonComponents/FlexContainer';
import Footer from '../../appLayout/components/footer/Footer';
import { generateStyles } from '../contact/styles';

const Search = (props) => {
  const [saqsBySyllabus, setSaqsBySyllabus] = useState(null); // [null, dispatch()]
  const [phySyllabus, setphySyllabus] = useState(null);
  const [pharmSyllabus, setpharmSyllabus] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const queryString = props.location.search;
    const parsedQueryString = qs.parse(queryString, { ignoreQueryPrefix: true })
      .searchQuery;
    if(
      (saqsBySyllabus || phySyllabus || pharmSyllabus) &&
      searchQuery === parsedQueryString
    )
      return;

    if(searchQuery !== parsedQueryString)
      axios.get(`${URLS.SEARCH}${queryString}`).then((response) => {
        const { query, saqs, phySyllabus, pharmSyllabus } = response.data;
        const sortedSaqs =
          saqs &&
          saqs.sort((a, b) =>
            b.paperSaqId.localeCompare(a.paperSaqId, 'en', {
              numeric: true,
            })
          );
        setSaqsBySyllabus(sortedSaqs);
        setphySyllabus(phySyllabus);
        setpharmSyllabus(pharmSyllabus);
        setSearchQuery(query);
      });
  }, [
    saqsBySyllabus,
    phySyllabus,
    pharmSyllabus,
    props.location.search,
    searchQuery,
  ]);

  const styles = generateStyles();
  return (
    <>
      {searchQuery && searchQuery.length > 2 ? (
        <div className={styles.spaceBetweenFlex}>
          <div>
            <SearchSectionContainer>
              <SearchSaqs saqs={saqsBySyllabus}/>
            </SearchSectionContainer>

            <SearchSectionContainer>
              <SearchPhySyllabus syllabus={phySyllabus}/>
            </SearchSectionContainer>

            <SearchSectionContainer>
              <SearchPharmSyllabus syllabus={pharmSyllabus}/>
            </SearchSectionContainer>
          </div>

          <div className={styles.footerContainer}>
            <Footer/>
          </div>
        </div>
      ) : (
        <FlexContainer columnAndSpaceBetween>
          <Typography>
            {searchQuery &&
            searchQuery.length < 3 && searchQuery !== '' && 'Please enter 3 or more characters in your search.'}
          </Typography>
          <div className={styles.footerContainer}>
            <Footer/>
          </div>
        </FlexContainer>
      )}
    </>
  );
};

export default Search;
