import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { URLS } from 'constants/url';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 0,
  },
}));

const SearchBar = (props) => {
  const [searchValue, setSearchValue] = useState('');
  const classes = useStyles();

  const navigateToSearchUrl = (history, query) => {
    history.push(`${URLS.SEARCH}?searchQuery=${query.replace(/\s+/g,' ').trim()}`);
  };

  const handleOnChange = (e) => setSearchValue(e.target.value);
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      navigateToSearchUrl(props.history, searchValue);
    }
  };
  const handleOnClick = (e) =>
    navigateToSearchUrl(props.history, searchValue);
  return (
    <Paper className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder="Search"
        // inputProps={{ 'aria-label': 'search saqs/syllabus' }}
        onChange={handleOnChange}
        onKeyPress={handleKeyPress}
        value={searchValue}
      />
      <IconButton
        className={classes.iconButton}
        aria-label="search"
        onClick={handleOnClick}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default SearchBar;
