import React from 'react';
import { Typography, Grid, Link } from '@material-ui/core';

import { fullWidth } from 'pages/studyAdvice/styles';
import { NAVPATH } from 'constants/navigation';

const StudyPlanAdvice = ({ useStylesWithTheme }) => {
  const styles = useStylesWithTheme()();
  return (
    <>
      <Grid item md={fullWidth} className={styles.headingsContainer}>
        <Typography className={styles.h2}>Study plan advice</Typography>
      </Grid>

      <Grid item className={styles.plainTextContainer}>
        <Typography className={styles.plainText}>
          Use the tool under{' '}
          {
            <Link href={NAVPATH.STUDY_PLAN} className={styles.links}>
              Study Plan
            </Link>
          }{' '}
          to create a systematic study plan.
        </Typography>
      </Grid>

      <Grid container>
        <Grid item className={styles.headingsContainer}>
          <Typography className={styles.h3}>Using the Study Plan</Typography>
        </Grid>

        <Grid item className={styles.plainTextContainer}>
          <Typography className={styles.plainText}>
            During study for each Section:
          </Typography>

          <ul className={styles.unorderedList}>
            <li>
              <Typography className={styles.plainText}>
                Base your study for each Section on the relevant SAQs, starting
                with those that have been asked most frequently. The SAQs are
                listed by frequency on{' '}
                {
                  <Link
                    href={NAVPATH.SAQS__BY_SYLLABUS}
                    className={styles.links}
                  >
                    SAQs by syllabus
                  </Link>
                }
                . Be sure to check off completed SAQs as you go to monitor your
                progress & keep your study on track!
              </Typography>
            </li>
            <li>
              <Typography className={styles.plainText}>
                For the first time going through SAQs, I suggest spending a
                short amount of time reading around the SAQ, then reading model
                answers (but treat these with a degree of suspicion as they are
                often written by other candidates and may contain errors), before
                attempting the SAQ under exam conditions on exam paper (see{' '}
                {
                  <Link href={'#examResources'} className={styles.links}>
                    Exam Resources
                  </Link>
                }{' '}
                for printable version).
              </Typography>
            </li>
            <li>
              <Typography className={styles.plainText}>
                I recommend making flash cards (physical or using software like
                ANKI) for{' '}
                <Link
                  href={NAVPATH.RESOURCES__FAST_FACTS}
                  className={styles.links}
                >
                  Fast Facts
                </Link>
                ,{' '}
                <Link
                  href={NAVPATH.RESOURCES__EQUATIONS}
                  className={styles.links}
                >
                  Equations
                </Link>{' '}
                &{' '}
                <Link
                  href={NAVPATH.RESOURCES__DIAGRAMS}
                  className={styles.links}
                >
                  Diagrams
                </Link>
                , & spending some time at the start of each study session
                revising these using spaced repetition. Making flashcards for{' '}
                <Link
                  href={NAVPATH.RESOURCES__DEFINITIONS}
                  className={styles.links}
                >
                  Definitions
                </Link>{' '}
                is probably less important for the Written, as you have more
                time to think of a definition than in the VIVA and can usually
                come up with something sensible. The back of Brandis is also
                great for Fast Facts!
              </Typography>
            </li>
            <li>
              <Typography className={styles.plainText}>
                Create your own notes on key diagrams, mnemonics & key topics & put
                them in readily accessible places – e.g. on your phone, around
                the house, in the toilet, etc. – so you can see them on a
                regular basis. Comparison tables for pharmacology are VERY
                useful.
              </Typography>
            </li>
          </ul>
        </Grid>

        <Grid item className={styles.plainTextContainer}>
          <Typography className={styles.plainText}>
            During revision period:
          </Typography>

          <ul className={styles.unorderedList}>
            <li>
              <Typography className={styles.plainText}>
                In addition to revising Fast Facts, Equations & Diagrams (which
                you should be an expert at by now!), revise the SAQs you did
                previously.
              </Typography>
            </li>
          </ul>
        </Grid>
      </Grid>
    </>
  );
};

export default StudyPlanAdvice;
