import React from 'react';
import { makeStyles } from '@material-ui/core';
import { globalStyles } from 'styles/globalStyles';

const searchContentStyles = makeStyles((theme) => ({
  ...globalStyles,
}));

const SearchContent = (props) => {
  const classes = searchContentStyles();
  return <div className={classes.plainTextContainer}>{props.children}</div>;
};

export default SearchContent;
