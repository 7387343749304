/* eslint-disable no-useless-escape */
import React, { useEffect } from 'react';
import { Button, Grid, Link, makeStyles, Typography, useTheme, } from '@material-ui/core';
import { globalStyles } from 'styles/globalStyles';
import { NAVPATH } from 'constants/navigation';
import { StandardContent } from './_StandardContent';
import { GridContent } from './_GridContent';
import { Heading } from './_Heading';
import { PlainText } from './_PlainText';

import Footer from 'appLayout/components/footer/Footer';
import history from '../../history';

const generateStyles = () => () =>
  makeStyles({
    largeTopMargin: {
      marginTop: '34px',
    },
    xlargeTopBottomMargin: {
      margin: '80px 0 60px 0',
    },
    container: {
      display: 'flex',
    },
    titleWrapper: {
      textAlign: 'center',
    },
    landingHeadingsContainer: {
      paddingLeft: '20px',
    },
    addPaddingLeft: {
      paddingLeft: '20px',
    },
    removeBottomMargin: {
      marginBottom: '0',
    },
    addMediumPaddingLeft: {
      paddingLeft: '40px',
    },
    addLargePaddingLeft: {
      paddingLeft: '80px',
    },
    centeredImage: {
      display: 'flex',
      justifyContent: 'center',
    },
    gridImageContainer: {
      display: 'flex',
      justifyContent: 'center',
      padding: '10px',
    },
    imagesHeroContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    gridImageOverallContainer: {
      width: '50%',
    },
    imageStyle: {
      width: '50%',
    },
    gridImageStyle: {
      width: '100%',
    },
    ...globalStyles,
  });
const fullWidth = 12;

const Landing = () => {
  useEffect(() => {
    const loggedInUser = localStorage.getItem('user');
    if(loggedInUser) history.push('/home');
  })

  const useStylesWithTheme = generateStyles(useTheme());
  const styles = useStylesWithTheme()();
  return (
    <>
      <Grid container className={styles.container}>
        <Grid
          item
          md={fullWidth}
          className={`${styles.titleWrapper} ${styles.xlargeTopBottomMargin}`}
        >
          <Typography className={styles.h1}>Welcome to Crit Bear!</Typography>
        </Grid>
        <PlainText
          styles={styles}
          text={'This site is designed for CICM primary exam candidates and their study. Crit Bear will help you:'}
        />

        <Grid className={styles.plainTextContainer}>
          <ul className={styles.unorderedList}>
            <li>Save time</li>
            <li>Increase the effectiveness of your study</li>
            <li>Keep yourself on track</li>
          </ul>
        </Grid>

        <Grid
          item
          md={12}
          className={`${styles.plainTextContainer}`}
        >
          <Typography className={`${styles.plainText}`}>
            Crit Bear is currently FREE! However to store your individual study progress & access the site, we need you to create an account. Please register below or log in.
            {/* To access the site, please log in or register below – first time users get a FREE 2 WEEK TRIAL.* */}
          </Typography>
        </Grid>

        <Grid item md={12}
              className={`${styles.plainTextContainer} ${styles.marginBtmLg} ${styles.flexRowJustifyCenter}`}>
          <Button
            variant="contained"
            color="primary"
            role={'link'}
            onClick={() => history.push('/login')}
          >
            Subscribe
          </Button>
        </Grid>

        <Heading styles={styles} text={'Features'}/>

        <StandardContent
          styles={styles}
          header={'Dashboard'}
          text={`Keep an eye on how you’re going with key tracking indices on your
          dashboard.`}
          imgSrc={'/images/screenshotDash.png'}
          imgAlt={'dashboard screen'}
        />

        <div className={styles.headingsContainer}>
          <Typography className={`${styles.h3}`}>Study Advice</Typography>
        </div>

        <Grid item md={fullWidth} className={styles.plainTextContainer}>
          <span className={styles.plainText}>
            Be sure to visit the&nbsp;
            <Link
              href={`${NAVPATH.STUDY_ADVICE}`}
              target="_blank"
              className={styles.links}
            >
              Study Advice page
            </Link>&nbsp;
            – it’s full of tips on study & exam strategy.
          </span>
        </Grid>
        <PlainText
          styles={styles}
          text={`Besides study recommendations, it contains useful exam resources - including printable SAQ practice 
          paper & a list of primary exam tutorials in your state. Pick & choose from the advice and see 
          what works for you.`}
        />

        <StandardContent
          styles={styles}
          header={'Study planner'}
          text={`Create a systemic study plan based on the relative importance of each
        section within the primary exam syllabus. This means you focus on
        what’s most important first.`}
          imgSrc={'/images/screenshotPlanner.png'}
          imgAlt={'study planner screen'}
        />

        <StandardContent
          styles={styles}
          header={'CICM Primary Exam Syllabus'}
          text={`The latest CICM primary exam syllabus with links to useful resources and related SAQs so you can test 
          yourself on what you’ve learnt. Check your progress off as you go!`}
          imgSrc={'/images/screenshotSyllabus.png'}
          imgAlt={'syllabus screen'}
        />

        <StandardContent
          styles={styles}
          header={'SAQs'}
          text={`All primary exam SAQs between 2012-2020 ordered by frequency of appearance to maximise the
        yield of your study. Relevant information is just a click away, including links to the exam reports and
        example answers. Check SAQs off as you complete them!`}
          imgSrc={'/images/screenshotSaqs.png'}
          imgAlt={'saqs screen'}
        />

        <GridContent
          styles={styles}
          header={'Reference material'}
          text={`A compilation of key information relevant to the primary exam, including: definitions, key numbers,
        key equations & important diagrams.`}
          images={[
            {
              src: 'screenshotDefs.png',
              alt: 'definitions screen',
            },
            {
              src: 'screenshotFacts.png',
              alt: 'fast facts screen',
            },
            {
              src: 'screenshotEquations.png',
              alt: 'equations screen',
            },
            {
              src: 'screenshotDiagrams.png',
              alt: 'diagrams screen',
            },
          ]}
        />

        <StandardContent
          styles={styles}
          header={'Test yourself'}
          text={'Generate random Written Papers and put yourself to the test!'}
          imgSrc={'/images/screenshotPractice.png'}
          imgAlt={'practice screen'}
          speciallyAddedBottomMargin
        />

        {/* <StandardContent
          styles={styles}
          header={'Pricing'}
          text={'*Pricing is as follows (introductory offer). ' +
          'New users will automatically commence a free 2 week trial.'}
          speciallyAddedBottomMargin
          h2Header
          includeBottomMargin={false}
        />

        <Grid item md={fullWidth} className={styles.plainTextContainer}>
          <ul className={styles.unorderedList}>
            <li>1 month: $8</li>
            <li>3 months: $22</li>
            <li>6 months: $38</li>
          </ul>
        </Grid> */}


        {/* <div
          className={`${styles.plainTextContainer} ${styles.addMediumPaddingLeft}`}
        >
          <Typography className={styles.plainText}>
            *Here’s what your subscription is paying for, to demonstrate this isn’t a money-making exercise.
          </Typography>
          <ul className={`${styles.unorderedList}`}>
            {listItems.map((item, idx) => (
              <div key={`listItem-${idx}`}>
                <li>{item}</li>
              </div>
            ))}
          </ul>
        </div> */}
      </Grid>
      <Footer/>
    </>
  )
    ;
};

export default Landing;
