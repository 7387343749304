import { makeStyles } from '@material-ui/styles';
import { globalStyles } from 'styles/globalStyles';

export const generateStyles = () => customStyles =>
  makeStyles({
    main: {
      height: '100%'
    },
    heroRowContainer: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: '20px'
    },
    quoteHolder: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center'
    },
    timeLeftContainer: {
      flex: '1 0 auto',
    },
    progressContainer: {
      flex: '3 0 auto',
      alignItems: 'center',
      padding: '5px',
      width: '80%'
    },

    flexCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    timeToExam: {},
    currentTopic: {},
    tickerCell: {
      height: '50%'
    },
    ...customStyles,
    ...globalStyles
  });

export const fullWidth = 12;
