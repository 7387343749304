import React from 'react';
// noinspection ES6CheckImport
import {Route, Router, Switch} from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import {AppLayout} from 'appLayout/AppLayout';

import Home from 'pages/home/Home';
import Landing from 'pages/landing/Landing';
import Login from 'pages/login/Login';
import StudyAdvice from 'pages/studyAdvice/StudyAdvice';
import HowToUse from 'pages/howToUse/HowToUse';
import StudyPlan from 'pages/studyPlan/StudyPlan';
import PharmSyllabus from 'pages/syllabus/PharmSyllabus';
import PhysiologySyllabus from 'pages/syllabus/PhysiologySyllabus';
import SaqBySyllabus from 'pages/saq/SaqBySyllabus';
import SaqByPaper from 'pages/saq/SaqByPaper';
import IndividualSaqById from 'pages/saq/IndividualSaqById';
import Contact from 'pages/contact/Contact';
import FormSubmitSuccess from 'pages/contact/FormSubmitSuccess';
import Resources from 'pages/resources/Resources';

import Search from 'pages/search/Search';

import {NAVPATH} from 'constants/navigation.js';
import history from './history';
import {CssBaseline} from '@material-ui/core';
import Payment from './pages/payment/Payment';
import FirstTimeUser from './pages/firstTimeUser/FirstTimeUser';
import LoginSuccess from './pages/login/LoginSuccess';
import PaymentSuccess from './pages/payment/PaymentSuccess';
import PaymentFailure from './pages/payment/PaymentFailure';

const App = () => {
  return (
    <Router history={history}>
      <AppLayout>
        <CssBaseline/>
        <Switch>
          <ProtectedRoute path={NAVPATH.PAYMENT} component={Payment} history={history}/>
          <ProtectedRoute path={NAVPATH.PAYMENT_SUCCESS} component={PaymentSuccess}/>
          <ProtectedRoute path={NAVPATH.PAYMENT_FAILURE} component={PaymentFailure}/>
          <ProtectedRoute path={NAVPATH.FIRSTTIMEUSERWITHID} component={FirstTimeUser}/>
          <Route exact path={NAVPATH.LOGIN_SUCCESS_QSID} component={LoginSuccess}/>
          <ProtectedRoute path={NAVPATH.HOME} component={Home} nested/>
          <Route exact path={NAVPATH.LANDING}>
            <Landing/>
          </Route>
          <Route exact path={NAVPATH.STUDY_ADVICE}>
            <StudyAdvice/>
          </Route>
          <Route exact path={NAVPATH.HOW_TO_USE}>
            <HowToUse/>
          </Route>
          <ProtectedRoute
            path={NAVPATH.STUDY_PLAN}
            component={StudyPlan}
            nested
          />
          <ProtectedRoute
            path={NAVPATH.SYLLABUS__PHYSIOLOGY}
            component={PhysiologySyllabus}
            nested
          />
          <ProtectedRoute
            path={NAVPATH.SYLLABUS__PHARMACOLOGY}
            component={PharmSyllabus}
            nested
          />
          {/* <Route exact path={NAVPATH.MCQ}>
            <Mcq />
          </Route> */}
          <ProtectedRoute
            path={NAVPATH.SAQS__BY_SYLLABUS}
            component={SaqBySyllabus}
            nested
          />
          <ProtectedRoute
            path={NAVPATH.SAQS__BY_PAPER}
            component={SaqByPaper}
            nested
          />
          <ProtectedRoute
            path={NAVPATH.SEARCH}
            component={Search}
          />
          <ProtectedRoute
            path={NAVPATH.SAQ__BY_ID}
            component={IndividualSaqById}
          />
          <Route exact path={NAVPATH.CONTACT}>
            <Contact history={history}/>
          </Route>
          <Route exact path={NAVPATH.CONTACT__SUCCESS}>
            <FormSubmitSuccess/>
          </Route>
          <ProtectedRoute
            path={NAVPATH.RESOURCES}
            component={Resources}
            nested
            exactPath={false}
          />
          <Route exact path={NAVPATH.LOGIN}>
            <Login/>
          </Route>
          <Route>
            <Landing/>
          </Route>
        </Switch>
      </AppLayout>
    </Router>
  );
};

export default App;
