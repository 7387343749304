import React, { useContext, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Advice from './_Advice';
import Planner from './_Planner';

import Footer from 'appLayout/components/footer/Footer';

import { fetchUser } from 'utils/helpers';
import { store } from 'store';

const useStyles = makeStyles({
  studyPlanContainer: {
    marginTop: '20px'
  }
});

const StudyPlan = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const loggedInUser = localStorage.getItem('user');

  useEffect(() => {
    if(!state.user) fetchUser(loggedInUser, dispatch, localStorage);
    const abortController = new AbortController();
    return () => {
      abortController.abort();
    };
  }, [dispatch, loggedInUser, state.user]);

  const styles = useStyles();
  return (
    <div className={styles.studyPlanContainer}>
      <Grid>
        <Advice />
      </Grid>

      <Grid>
        <Planner />
      </Grid>

      <Footer/>
    </div>
  );
};

export default StudyPlan;
