import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import {Checkbox, Grid, Link, makeStyles, Typography,} from '@material-ui/core';
import {useSideBarHook} from 'appLayout/components/sidebar/useSideBarHook';
import {SidebarContext} from 'appLayout/components/sidebar/SideBarContextProvider.jsx';
import {URLS} from 'constants/url';
import {globalStyles} from 'styles/globalStyles';
import {renderPharmacologySyllabusTableHeaders} from 'commonComponents/TableHeaders';
import Footer from 'appLayout/components/footer/Footer';
import {store} from 'store';
import {unpackPoints} from 'utils/helpers';

const syllabusStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '10px',
  },
  pointWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0px 10px 60px',
    alignItems: 'center',
  },
  innerPointWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0px 10px 90px',
    alignItems: 'center',
  },
  furtherInnerPointWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0px 10px 120px',
    alignItems: 'center',
  },
  rowHeaders: {
    fontSize: '25px',
    padding: '10px 0px 10px 20px',
    textDecoration: 'underline',
  },
  tableCellWrapper: {
    padding: '0',
  },
  cellWrapper: {
    padding: '10px 0px 10px 30px',
    wordBreak: 'break-all',
    wordWrap: 'break-word',
  },
  checkbox: {
    padding: '5px',
  },
  link: {
    margin: theme.spacing(0),
    fontSize: '14px',
    color: 'black',
  },
  ...globalStyles,
}));

let pageRef = React.createRef();
let listOfRefs = [];
const PharmSyllabus = () => {
  const globalState = useContext(store);
  const {state, dispatch} = globalState;
  const [pharmSyllabus, setPharmSyllabus] = useState(null);
  const context = useContext(SidebarContext);

  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    if (pharmSyllabus) return;
    const abortController = new AbortController();
    axios
      .get(URLS.SYLLABUS__PHARMACOLOGY)
      .then((response) => {
        const pharmSyllabus = response.data;
        // const headers = syllabus.reduce((acc, curr) => [...acc, curr.headers], []).flat(Infinity)
        const firstFlattenedResult = pharmSyllabus
          .map((syllabus) => syllabus.headers)
          .flat(Infinity);

        const finalFlattenedResult = firstFlattenedResult
          .map((syllabus) => {
            return syllabus.points
              ? unpackPoints([
                {
                  title: syllabus.title,
                  level: syllabus.level,
                  p1Links: syllabus.p1Links,
                  relatedSaqs: syllabus.relatedSaqs,
                },
                ...syllabus.points,
              ])
              : syllabus;
          })
          .flat(Infinity);

        setPharmSyllabus(response.data);
        dispatch({
          type: 'set pharmacology syllabus in store',
          data: finalFlattenedResult,
        });
      })
      .catch((err) => console.log(err));

    return () => {
      abortController.abort();
    };
  }, [dispatch, pharmSyllabus, state.user, user]);
  useSideBarHook(listOfRefs);

  const findSyllabusProgressByTitle = (title) => {
    if (user && user.pharmacologyProgress) {
      return user.pharmacologyProgress[title];
    }
    return false;
  };

  const handleCheckboxOnChange = (event, id, dispatch) => {
    context.resetSideBarContext();
    axios
      .put(`${URLS.PHARMACOLOGY_PROGRESS}/${user.id}`, {
        syllabusId: id,
        value: event.target.checked,
      })
      .then((response) => {
        response.data && localStorage.setItem('user', JSON.stringify(response.data));
        dispatch({
          type: 'change checked status of pharm syllabus row',
          data: {id, checked: !!response.data.pharmacologyProgress[id]},
        });
        dispatch({
          type: 'update progress boolean in store',
          data: true,
        });
      })
      .catch((err) => console.log(err));
  };

  const renderSaqs = (relatedSaqs, styles, grouped = false) => {
    return relatedSaqs.map((saq, idx) => {
      if (typeof saq !== 'string') return renderSaqs(saq, styles, true);
      const paperDate = parseInt(saq.substring(0, 4));
      return paperDate > 2011 ? (
        <React.Fragment key={`relatedSaq-${saq}-${idx}`}>
          {idx === 0 && '['}
          <Link
            href={`/saqs/${saq.trim()}`}
            target={'_blank'}
            className={styles.links}
          >
            {saq}
          </Link>
          {idx < relatedSaqs.length - 1 && ', '}
          {idx === relatedSaqs.length - 1 && ']'}
          {idx === relatedSaqs.length - 1 && grouped && <br/>}
        </React.Fragment>
      ) : (
        <Typography
          className={styles.deadLink}
          key={`relatedSaq-${saq}-${idx}`}
        >
          {idx === 0 && '['}
          {saq}
          {idx < relatedSaqs.length - 1 && ', '}
          {idx === relatedSaqs.length - 1 && ']'}
          {idx === relatedSaqs.length - 1 && grouped && <br/>}
        </Typography>
      );
    });
  };

  const computeStyleForHeaderAndPointRows = (nestingLevel) => {
    switch (nestingLevel) {
      case 1:
        return styles.pointWrapper;
      case 2:
        return styles.innerPointWrapper;
      case 3:
        return styles.furtherInnerPointWrapper;
      default:
        return styles.cellWrapper;
    }
  };

  const renderHeaderContent = (header, idx) => {
    const nestingLevel = header.nestingLevel || 0;
    const styleName = computeStyleForHeaderAndPointRows(nestingLevel);
    return (
      <Grid
        container
        className={styles.tableCellWrapper}
        key={
          nestingLevel === 3
            ? `syllabus-furtherInnerPoint-row-${idx}`
            : nestingLevel === 2
            ? `syllabus-innerPoint-row-${idx}`
            : nestingLevel === 1
              ? `syllabus-point-row-${idx}`
              : `syllabus-header-row-${idx}`
        }
      >
        <Grid item md={5} className={styleName}>
          <Checkbox
            disableRipple
            color={'primary'}
            onChange={(event) =>
              handleCheckboxOnChange(event, header.title, dispatch)
            }
            checked={findSyllabusProgressByTitle(header.title) || false}
            classes={{ root: styles.checkBoxNoPaddingAndSmRightMargin }}
          />
          {header.title}
        </Grid>
        <Grid item md={1} className={styles.cellWrapper}>
          {header.level}
        </Grid>
        <Grid item md={2} className={styles.cellWrapper}>
          {header.p1Links &&
          header.p1Links.map((link, idx) => (
            <Link
              href={`${link}`}
              target="_blank"
              className={styles.links}
              key={`syllabus-pOneLink-${idx}`}
            >
              {'P1'}
              {idx + 1 !== header.p1Links.length && ', '}
            </Link>
          ))}
        </Grid>
        <Grid item md={4} className={styles.cellWrapper}>
          {renderSaqs(header.relatedSaqs, styles, false)}
        </Grid>
        {header.points &&
        header.points.map((point, idx) => renderHeaderContent(point, idx))}
        {header.innerPoints &&
        header.innerPoints.map((innerPoint, idx) =>
          renderHeaderContent(innerPoint, idx)
        )}
      </Grid>
    );
  };

  const renderPharmacologySyllabusContent = (_) => {
    if (!pharmSyllabus) return;
    let refs = [];
    let sections = pharmSyllabus.map((sectionObj, sectionIdx) => {
      let thisRef = React.createRef();
      refs = refs.concat(thisRef);
      return (
        <Grid container key={`${sectionObj._id}${sectionIdx}`}>
          <Grid className={styles.headingsContainer}>
            <div style={{height: '45px'}} ref={thisRef}/>
            <Typography className={styles.h1} color="primary">
              {sectionObj.section}
            </Typography>
          </Grid>
          {sectionObj.headers.map((header, idx) =>
            renderHeaderContent(header, idx)
          )}
        </Grid>
      );
    });
    listOfRefs = refs;
    return sections;
  };

  const styles = syllabusStyles();
  return (
    <div className="syllabus">
      <Grid container className={styles.root} ref={pageRef}>
        {renderPharmacologySyllabusTableHeaders(styles)}
        {renderPharmacologySyllabusContent(pharmSyllabus)}
      </Grid>
      <Footer/>
    </div>
  );
};

export default PharmSyllabus;
