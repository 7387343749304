import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { definitions } from './definitions.json';
import { globalStyles } from 'styles/globalStyles';
import Footer from 'appLayout/components/footer/Footer';

const fullWidth = 12;
const getDefinitionStyles = makeStyles((theme) => ({
  main: { flex: '0 0 100%', marginBottom: '20px' },
  boldInline: { fontWeight: 'bold' },
  definition: {
    display: 'flex',
    margin: '1em 0',
    '& ul': {
      ...theme.typography.body1,
      margin: '0.5em',
    },
  },
  sectionHeader: {
    paddingLeft: '40px',
  },
  indent: {
    flex: '0 0 3em',
  },
  ...globalStyles,
}));

const Definitions = () => {
  const styles = getDefinitionStyles(useTheme());
  return (
    <>
      <Grid className={styles.main} container>
        <Grid item md={fullWidth}>
          {definitions.map(([term, definition, ...bulletPoints], defIdx) => (
            <section className={styles.definition} key={`definition-${defIdx}`}>
              <div className={styles.indent} />
              <div>
                <Typography variant="body1" align="left">
                  <span className={styles.boldInline}>{term}</span> {definition}
                </Typography>
                {bulletPoints && (
                  <ul className={styles.unorderedList}>
                    {bulletPoints.map((point, idx) => (
                      <li key={`definition-${defIdx}-bullet-${idx}`}>
                        {point}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </section>
          ))}
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default Definitions;
