import React, { useEffect, useContext } from 'react';
import { Typography, Grid, useTheme } from '@material-ui/core';

import Sections from 'pages/studyAdvice/sections';

import { generateStyles, fullWidth } from 'pages/studyAdvice/styles';
import { useSideBarHook } from 'appLayout/components/sidebar/useSideBarHook';
import Footer from 'appLayout/components/footer/Footer';
import { fetchUser } from 'utils/helpers';
import { store } from 'store';

const {
  ExamTechniqueAdvice,
  GeneralStudyAdvice,
  Rationale,
  SaqAdvice,
  StudyPlanAdvice,
  // Reference material
  ModelSaqSites,
  OtherOnlineMaterials,
  Books,
  ExamResources,
  // Adjuncts
  Courses,
  Tutorials,
  StudyGroups,
} = Sections;

const approachToExamSections = [
  Rationale,
  GeneralStudyAdvice,
  StudyPlanAdvice,
  SaqAdvice,
  ExamTechniqueAdvice,
];

const referenceMaterialSections = [
  ModelSaqSites,
  OtherOnlineMaterials,
  Books,
  ExamResources,
];

const adjunctSections = [Courses, Tutorials, StudyGroups];

const headerSections = [
  { title: 'Approach to exam', sectionToMap: approachToExamSections },
  {
    title: 'Reference material',
    subtitle: 'A list of the resources I found most helpful.',
    sectionToMap: referenceMaterialSections,
  },
  { title: 'Adjuncts', sectionToMap: adjunctSections },
];

const customStyles = {
  main: {
    flexGrow: '1',
  },
};

let pageRef = React.createRef();
let listOfRefs = [];

const StudyAdvice = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const loggedInUser = localStorage.getItem('user');

  useEffect(() => {
    if (!state.user && loggedInUser) fetchUser(loggedInUser, dispatch, localStorage);
    const abortController = new AbortController();
    return () => {
      abortController.abort();
    };
  }, [dispatch, loggedInUser, state.user]);

  const useStylesWithTheme = generateStyles(useTheme());
  const styles = useStylesWithTheme(customStyles)();
  useSideBarHook(listOfRefs);

  const generateStudyAdvice = () => {
    let refs = [];
    const studyAdviceJsx = (
      <Grid
        ref={pageRef}
        className={styles.main}
        container
        id="before-starting-page"
      >
        {headerSections.map((sectionData, idx) => {
          let thisRef = React.createRef();
          refs = refs.concat(thisRef);
          return (
            <div key={`studyAdvice-${idx}`} className={styles.marginTopSm}>
              <Grid item md={fullWidth} className={styles.headingsContainer}>
                <Typography className={styles.h1} ref={thisRef}>
                  {sectionData.title}
                </Typography>
              </Grid>
              {sectionData.subtitle && (
                <Grid item md={fullWidth} className={styles.plainTextContainer}>
                  <Typography className={styles.plainText}>
                    {sectionData.subtitle}
                  </Typography>
                </Grid>
              )}
              {sectionData.sectionToMap.map((Section, idx) => (
                <React.Fragment key={`before-starting-section-${idx}`}>
                  <Grid container>
                    <Section useStylesWithTheme={useStylesWithTheme} />
                  </Grid>
                </React.Fragment>
              ))}
            </div>
          );
        })}
        <Footer />
      </Grid>
    );
    listOfRefs = refs;
    return studyAdviceJsx;
  };

  return generateStudyAdvice();
};

export default StudyAdvice;
