import React from 'react';
import { Grid, Typography } from '@material-ui/core';

export const Heading = (props) => {
  const { styles, text } = props;
  return (
    <Grid item md={12} className={`${styles.headingsContainer}`}>
      <Typography className={`${styles.h2}`}>{text}</Typography>
    </Grid>
  );
};
