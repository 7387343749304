import React from 'react';
import { makeStyles } from '@material-ui/core';
import { globalStyles } from 'styles/globalStyles';

const searchSectionContainerStyles = makeStyles((theme) => ({
  searchSectionContainer: {
    margin: '20px 0 20px 0',
  },
  ...globalStyles,
}));

const SearchSectionContainer = (props) => {
  const classes = searchSectionContainerStyles();
  return <div className={classes.searchSectionContainer}>{props.children}</div>;
};

export default SearchSectionContainer;
