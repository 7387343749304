import _ from 'lodash/fp';

// remove cap for key iteration
const _fp = _.convert({ cap: false });
// eslint-disable-next-line no-useless-escape
const validEmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const isEmpty = value => !value;

export const checkForEmptyFormFieldValues = formFieldValues =>
  _fp.reduce(
    (acc, curr, key, collection) =>
      isEmpty(curr)
        ? { ...acc, [key]: 'This field is required' }
        : { ...acc, [key]: '' },
    {},
    formFieldValues
  );

export const filterFormFieldValuesForNonEmptyStrings = formFieldValues =>
  _fp.filter(v => v !== '', formFieldValues);

export const validateEmptyString = value => isEmpty(value) ? 'This field is required' : ''

export const validateEmail = email => validEmailRegex.test(email) ? '' : 'The email you entered is invalid';
