import React from 'react'
import {useTheme} from '@material-ui/core';
import {generateStyles} from 'pages/payment/styles';
import Footer from '../../appLayout/components/footer/Footer';

const PaymentFailure = () => {
  const useStylesWithTheme = generateStyles(useTheme());
  const styles = useStylesWithTheme();

  return (
    <div className={styles.main}>
      Payment Failed

      <div className={styles.footerContainer}>
        <Footer/>
      </div>
    </div>
  )
}

export default PaymentFailure