import React from 'react';
import { Link, Paper, Typography, makeStyles } from '@material-ui/core';
import { globalStyles } from 'styles/globalStyles';
import history from '../../../history';

const useFooterStyle = makeStyles(() => ({
  root: {
    padding: '10px',
    height: '100px',
    minHeight: '100px',
    width: '100%',
    display: 'flex',
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '30%',
  },
  flexContainer: {
    display: 'flex',
  },
  ...globalStyles,
}));

const Footer = () => {
  const styles = useFooterStyle();
  return (
    <Paper className={styles.root}>
      <div className={styles.leftContainer}>
        <div className={styles.flexContainer}>
          <Typography className={`${styles.plainText} ${styles.marginBtmSm}`}>
            CRIT BEAR
          </Typography>
        </div>
        <div className={styles.flexContainer}>
          <Typography className={styles.plainText}>
            Crit Bear has no affiliation with CICM.
            This site has not been optimized for mobile or tablet & all images are subject to{' '}
            <Link
              href={'https://creativecommons.org/licenses/by-nc-nd/4.0/'}
              target="_blank"
              className={styles.links}
            >
              this licence.
            </Link>
          </Typography>
        </div>
      </div>

      <div className={styles.rightContainer}>
        <Typography className={`${styles.plainText} ${styles.marginRightMed}`}>
          <Link
            href={
              'https://drive.google.com/file/d/1IFyOFeKnNhEqRFIv9f0fypDzAa_MBWPk/view?usp=sharing'
            }
            target="_blank"
            className={styles.footerlinks}
          >
            Privacy
          </Link>
        </Typography>
        <Typography className={`${styles.plainText} ${styles.marginRightMed}`}>
          <span className={styles.footerlinks} onClick={() => history.push('/contact')}>
            Contact
          </span>
        </Typography>
      </div>
    </Paper>
  );
};

export default Footer;
