import React from 'react';
import { Grid, Link, Typography, makeStyles } from '@material-ui/core';
import { globalStyles } from 'styles/globalStyles';
import { areAllSaqsBefore2012 } from 'utils/helpers';

const saqBySyllabusStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  saqTableWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0 0 0',
    position: 'sticky',
    backgroundColor: '#d3d3d3',
    height: '45px',
    zIndex: 9,
    top: 0,
  },
  cellWrapper: {
    padding: '0px 0px 0px 30px',
    wordBreak: 'break-all',
    wordWrap: 'break-word',
  },
  tableCellWrapper: {
    padding: '0',
    marginBottom: '20px',
  },
  link: {
    margin: theme.spacing(0),
    fontSize: '14px',
    color: 'black',
  },
  ...globalStyles,
}));

const phySyllabusStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  furtherInnerPointWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px 0px 0px 55px',
    alignItems: 'center',
  },
  mainHeaders: {
    fontSize: '25px',
    padding: '10px 0px 10px 30px',
  },
  rowHeaders: {
    fontSize: '25px',
    padding: '10px 0px 10px 30px',
  },
  subSectionHeaders: {
    fontSize: '18px',
    padding: '10px 0px 10px 0px',
    fontWeight: '800',
  },
  cellWrapper: {
    padding: '10px 0px 10px 30px',
    wordBreak: 'break-all',
    wordWrap: 'break-word',
  },
  tableCellWrapper: {
    padding: '0px',
    marginBottom: '20px',
  },
  ...globalStyles,
}));

const pharmSyllabusStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '10px',
  },
  pointWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0px 10px 45px',
    alignItems: 'center',
  },
  innerPointWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0px 10px 60px',
    alignItems: 'center',
  },
  rowHeaders: {
    fontSize: '25px',
    padding: '10px 0px 10px 20px',
    textDecoration: 'underline',
  },
  tableCellWrapper: {
    padding: '0',
    marginBottom: '20px'
  },
  cellWrapper: {
    padding: '10px 0px 10px 30px',
    wordBreak: 'break-all',
    wordWrap: 'break-word',
  },
  checkbox: {
    padding: '5px',
  },
  link: {
    margin: theme.spacing(0),
    fontSize: '14px',
    color: 'black',
  },
  cellWrapperBold: {
    padding: '10px 0px 10px 30px',
    wordBreak: 'break-all',
    wordWrap: 'break-word',
    fontSize: '30px',
    fontWeight: '600',
  },
  pointWrapperBold: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0px 10px 45px',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: '600',
  },
  innerPointWrapperBold: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0px 10px 60px',
    alignItems: 'center',
    fontSize: '30px',
    fontWeight: '600',
  },
  ...globalStyles,
}));

const renderSaqRelatedSaqs = (saqs, rowSaqId, styles, grouped = false) => {
  const filteredSaqs = saqs.filter((saq) => saq !== rowSaqId);
  return filteredSaqs.map((saq, idx) => {
    if (typeof saq !== 'string')
      return renderSaqRelatedSaqs(saq, rowSaqId, styles, true);
    const paperDate = parseInt(saq.substring(0, 4));
    return paperDate > 2011 ? (
      <React.Fragment key={`relatedSaq-${saq}-${idx}`}>
        {idx === 0 && '['}
        <Link href={`/saqs/${saq.trim()}`} target="_blank" className={styles.links}>
          {saq}
        </Link>
        {idx < filteredSaqs.length - 1 && ', '}
        {idx === filteredSaqs.length - 1 && ']'}
        {idx === filteredSaqs.length - 1 && grouped && <br />}
      </React.Fragment>
    ) : (
      <React.Fragment key={`relatedSaq-${saq}-${idx}`}>
        {idx === 0 && '['}
        <span className={styles.deadLink}>{saq}</span>
        {idx < filteredSaqs.length - 1 && ', '}
        {idx === filteredSaqs.length - 1 &&
          filteredSaqs.length > 1 &&
          !areAllSaqsBefore2012(filteredSaqs) &&
          ']'}
        {idx === filteredSaqs.length - 1 &&
          filteredSaqs.length > 1 &&
          areAllSaqsBefore2012(filteredSaqs) &&
          ']'}
        {idx === filteredSaqs.length - 1 && filteredSaqs.length === 1 && ']'}
        {idx === filteredSaqs.length - 1 && grouped && <br />}
      </React.Fragment>
    );
  });
};

export const renderSaqsBySyllabus = (saq) => {
  const styles = saqBySyllabusStyles();
  return (
    saq &&
    saq.map((saqRowData) => (
      <Grid key={saqRowData._id}>
        <Grid container className={styles.tableCellWrapper}>
          <Grid item md={1}>
            {saqRowData.paperSaqId}
          </Grid>
          <Grid item md={6}>
            {saqRowData.question}
          </Grid>
          <Grid item md={1}>
            {saqRowData.erLink.map((link, idx) => (
              <Link
                key={`erLink${idx}`}
                href={`${link}`}
                target="_blank"
                className={styles.links}
              >
                ER
              </Link>
            ))}
          </Grid>
          <Grid item md={1}>
            {saqRowData.cwLinks.map((link, idx) => (
              <Link
                key={`cwLink${idx}`}
                href={`${link}`}
                target="_blank"
                className={styles.links}
              >
                CW
              </Link>
            ))}
          </Grid>
          <Grid item md={1}>
            {saqRowData.ippLinks.map((link, idx) => (
              <Link
                key={`ippLink${idx}`}
                href={`${link}`}
                target="_blank"
                className={styles.links}
              >
                IPP
              </Link>
            ))}
          </Grid>
          <Grid item md={2}>
            {renderSaqRelatedSaqs(
              saqRowData.relatedSaqs,
              saqRowData.paperSaqId,
              styles,
              false
            )}
          </Grid>
        </Grid>
      </Grid>
    ))
  );
};

const renderPhysiologyRelatedSaqs = (relatedSaqs, styles, grouped = false) => {
  return relatedSaqs.map((saq, idx) => {
    if (typeof saq !== 'string')
      return renderPhysiologyRelatedSaqs(saq, styles, true);
    const paperDate = parseInt(saq.substring(0, 4));
    return paperDate > 2011 ? (
      <React.Fragment key={`phyRelatedSaq-${saq}-${idx}`}>
        {idx === 0 && '['}
        <Link
          href={`/saqs/${saq.trim()}`}
          target={'_blank'}
          className={styles.links}
        >
          {saq}
        </Link>
        {idx < relatedSaqs.length - 1 && ', '}
        {idx === relatedSaqs.length - 1 && ']'}
        {idx === relatedSaqs.length - 1 && grouped && <br />}
      </React.Fragment>
    ) : (
      <Typography
        className={styles.deadLink}
        key={`phyRelatedSaq-${saq}-${idx}`}
      >
        {idx === 0 && '['}
        {saq}
        {idx < relatedSaqs.length - 1 && ', '}
        {idx === relatedSaqs.length - 1 && ']'}
        {idx === relatedSaqs.length - 1 && grouped && <br />}
      </Typography>
    );
  });
};

export const renderPhysiologySyllabusContent = (syllabus) => {
  const styles = phySyllabusStyles();
  return (
    syllabus &&
    syllabus.map((syllabusData, syllabusIdx) => {
      return (
        <Grid key={`phySyllabus-subSection-${syllabusIdx}`}>
          <Grid container className={styles.tableCellWrapper}>
            <Grid item md={1}>
              {syllabusData.syllabusId}
            </Grid>
            <Grid item md={5}>
              {syllabusData.ability}
            </Grid>
            <Grid item md={1}>
              {syllabusData.level}
            </Grid>
            <Grid item md={2}>
              {syllabusData.pOneLinks &&
                syllabusData.pOneLinks.map(({ title, link }, idx) => (
                  <Link
                    href={`${link}`}
                    target="_blank"
                    className={styles.links}
                    key={`syllabus-pOneLink-${idx}`}
                  >
                    {`${title}`}
                    {idx + 1 !== syllabusData.pOneLinks.length && ', '}
                  </Link>
                ))}
            </Grid>
            <Grid item md={3}>
              {renderPhysiologyRelatedSaqs(
                syllabusData.relatedSaqs,
                styles,
                false
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    })
  );
};

const renderPharmacologySaqs = (relatedSaqs, styles, grouped = false) => {
  return relatedSaqs.map((saq, idx) => {
    if (typeof saq !== 'string')
      return renderPharmacologySaqs(saq, styles, true);
    const paperDate = parseInt(saq.substring(0, 4));
    return paperDate > 2011 ? (
      <React.Fragment key={`pharmRelatedSaq-${saq}-${idx}`}>
        {idx === 0 && '['}
        <Link
          href={`/saqs/${saq.trim()}`}
          target={'_blank'}
          className={styles.links}
        >
          {saq}
        </Link>
        {idx < relatedSaqs.length - 1 && ', '}
        {idx === relatedSaqs.length - 1 && ']'}
        {idx === relatedSaqs.length - 1 && grouped && <br />}
      </React.Fragment>
    ) : (
      <Typography
        className={styles.deadLink}
        key={`pharmRelatedSaq-${saq}-${idx}`}
      >
        {idx === 0 && '['}
        {saq}
        {idx < relatedSaqs.length - 1 && ', '}
        {idx === relatedSaqs.length - 1 && ']'}
        {idx === relatedSaqs.length - 1 && grouped && <br />}
      </Typography>
    );
  });
};

const renderPharmDataRow = (data, styles) => {
  return (
    <Grid container className={styles.tableCellWrapper}>
      <Grid item md={5}>
        {data.title}
      </Grid>
      <Grid item md={1}>
        {data.level}
      </Grid>
      <Grid item md={2}>
        {data.p1Links &&
          data.p1Links.map((link, idx) => (
            <Link
              href={`${link}`}
              target="_blank"
              className={styles.links}
              key={`syllabus-pOneLink-${idx}`}
            >
              P1
              {idx + 1 !== data.p1Links.length && ', '}
            </Link>
          ))}
      </Grid>
      <Grid item md={4}>
        {renderPharmacologySaqs(data.relatedSaqs, styles, false)}
      </Grid>
    </Grid>
  );
};

export const renderPharmacologySyllabusContent = (syllabus) => {
  const styles = pharmSyllabusStyles();
  return (
    syllabus &&
    syllabus.map((syllabusData, syllabusIdx) => {
      return (
        <Grid key={`pharmSyllabus-${syllabusIdx}`}>
          {renderPharmDataRow(syllabusData, styles, 'first')}
        </Grid>
      );
    })
  );
};
