import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import Definitions from 'pages/resources/sections/_Definitions';
import FastFacts from 'pages/resources/sections/_FastFacts';
import Equations from 'pages/resources/sections/_Equations';
import Diagrams from 'pages/resources/sections/_Diagrams';
import Templates from 'pages/resources/sections/_Templates';
import RandomPaper from 'pages/resources/sections/_RandomPaper';

const Resources = () => {
  const match = useRouteMatch();
  return (
    <>
      <Route path={`${match.path}/definitions`}>
        <Definitions />
      </Route>
      <Route path={`${match.path}/fast-facts`}>
        <FastFacts />
      </Route>
      <Route path={`${match.path}/equations`}>
        <Equations />
      </Route>
      <Route path={`${match.path}/diagrams`}>
        <Diagrams />
      </Route>
      <Route path={`${match.path}/templates`}>
        <Templates />
      </Route>
      <Route path={`${match.path}/randomPaper`}>
        <RandomPaper />
      </Route>
    </>
  );
};

export default Resources;
