import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

import { globalStyles } from 'styles/globalStyles';
import { renderPharmacologySyllabusTableHeaders } from 'commonComponents/TableHeaders';
import { renderPharmacologySyllabusContent } from 'pages/search/commonComponents/RenderContent';
import SearchHeader from 'pages/search/commonComponents/SearchHeader';
import SearchContent from 'pages/search/commonComponents/SearchContent';

const searchPharmSyllabusStyles = makeStyles((theme) => ({
  ...globalStyles,
}));

const SearchPharmSyllabus = (props) => {
  const { syllabus } = props;
  const isThereSyllabusData = (syllabus || []).length > 0
  const classes = searchPharmSyllabusStyles();
  return (
    <>
      <SearchHeader>
        <Typography className={classes.h1}>Pharmacology Syllabus</Typography>
      </SearchHeader>

      <SearchContent>
        {isThereSyllabusData && renderPharmacologySyllabusTableHeaders(classes, true)}
        {renderPharmacologySyllabusContent(syllabus)}
        {!isThereSyllabusData && (
          <Typography className={classes.h3}>
            No Pharmacology syllabus found
          </Typography>
        )}
      </SearchContent>
    </>
  );
};

export default SearchPharmSyllabus;
