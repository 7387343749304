/* eslint-disable no-useless-escape */
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Card, CardContent, Grid, Link, Typography, useTheme, } from '@material-ui/core';
import { generateStyles } from 'pages/home/styles';
import { makeStyles } from '@material-ui/styles';

import { quotesList } from './quotes.json';
import { store } from 'store';
import Footer from 'appLayout/components/footer/Footer';
import { fetchUser } from '../../utils/helpers';
import { NAVPATH } from '../../constants/navigation';

const progressTickers = [
  {
    title: 'SAQs',
    progress: '40',
  },
  {
    title: 'Physiology syllabus',
    progress: '30',
  },
  {
    title: 'Pharmacology syllabus',
    progress: '30',
  },
];

const customStyles = makeStyles({
  tickerCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  cardContent: {
    height: '1em',
    padding: '5px'
  },
  title: {
    fontSize: '14px'
  },
  underlined: {
    textDecoration: 'underline',
  },
});

const calculateProgress = (loggedInUserInfo, type, countList) => {
  const saqsProgress = loggedInUserInfo.saqsProgress;
  const phySyProgress = loggedInUserInfo.physiologyProgress;
  const pharmSyProgress = loggedInUserInfo.pharmacologyProgress;

  const saqsTotalCount = (countList && countList['saqs']) || 0;
  const phySyTotalCount = (countList && countList['phySyllabus']) || 0;
  const pharmSyTotalCount = (countList && countList['pharmSyllabus']) || 0;

  switch (type) {
    case 'saqs':
      return saqsProgress ? (
        ((Object.keys(saqsProgress).length) / saqsTotalCount) *
        100
      ).toFixed(2) : 0;
    case 'physiology syllabus':
      return phySyProgress ? (
        (Object.keys(phySyProgress).length / phySyTotalCount) *
        100
      ).toFixed(2) : 0;
    case 'pharmacology syllabus':
      return pharmSyProgress ? (
        (Object.keys(pharmSyProgress).length / pharmSyTotalCount) *
        100
      ).toFixed(2) : 0;
    default:
      return 0;
  }
};

const TickerCard = (props) => {
  const { state } = props;
  const loggedInUserInfo = JSON.parse(localStorage.getItem('user'));
  const useStylesWithTheme = generateStyles(useTheme());
  const tickerStyles = useStylesWithTheme(customStyles)();

  const trimedSmallTitle = props.title.trim().toLowerCase();
  const percentage = calculateProgress(
    loggedInUserInfo,
    trimedSmallTitle,
    state.syllabusSaqsCountList
  );

  return (
    loggedInUserInfo && (
      <Card className={tickerStyles.tickerCard}>
        <CardContent className={tickerStyles.cardContent}>
          <Typography
            className={tickerStyles.title}
            align="center"
          >
            {props.title}
          </Typography>
          <Typography
            className={tickerStyles.progress}
            variant="h6"
            align="center"
          >
            <span>{percentage}%</span>
          </Typography>
        </CardContent>
      </Card>
    )
  );
};

const Home = (_) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const [quote, setQuote] = useState({});
  const userInLS = localStorage.getItem('user')
  useEffect(() => {
    userInLS && fetchUser(userInLS, dispatch, localStorage)
      .then()
      .catch(err => console.log(err))
    const quotesListSize = quotesList.length - 1;
    const randomPosition =
      Math.floor(Math.random() * (quotesListSize + 1));
    setQuote({
      value: quotesList[randomPosition][0],
      author: quotesList[randomPosition][1],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const useStylesWithTheme = generateStyles(useTheme());
  const styles = useStylesWithTheme()();
  const user = JSON.parse(localStorage.getItem('user'))

  /**************************************
   * Process exam date and time to exam  *
   **************************************/
  const processTimeToExam = () => {
    const examDateMoment = moment.utc(user.examDate)
    const durationBetweenBothDates = moment.duration(examDateMoment.diff(moment().utc()))
    const durationBetweenBothDatesInDays = durationBetweenBothDates.asDays()
    const noOfWeeks = Math.floor(durationBetweenBothDates.asWeeks())
    const noOfDays = (durationBetweenBothDates.days()) % 7
    return durationBetweenBothDatesInDays > 0
      ? `Time to exam: ${noOfWeeks} weeks ${noOfDays} days`
      : (
        <>
          <span>Time to exam: {user.examDate && 'exam date has passed,'}set date using&nbsp;</span>
          <Link href={NAVPATH.STUDY_PLAN} className={styles.links}>Study Planner</Link>
        </>
      )
  }


  return (
    user && (
      <div className={styles.heroRowContainer}>
        <Grid className={styles.quoteHolder} item>
          <Typography className={styles.limitedH1}>
            {`“${quote.value}” - ${quote.author}`}
          </Typography>
        </Grid>
        <Grid className={styles.timeLeftContainer} container>
          <Grid
            className={`${styles.timeToExam} ${styles.flexCenter}`}
            item
            md={12}
          >
            <Typography variant="h5" align="center">
              Exam Date:&nbsp;
                {user.examDate
                  ? moment(user.examDate).format('D MMM YYYY')
                  : 'set date using '
                }
                {!user.examDate && (
                  <Link href={NAVPATH.STUDY_PLAN} className={styles.links}>Study Planner</Link>)}
            </Typography>
            <Typography variant="h5" align="center">
              {processTimeToExam()}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={styles.progressContainer} container justify="center">
          {progressTickers.map((tickerProps, idx) => (
            <Grid key={`ticker=${idx}`} className={styles.tickerCell} item md={4}>
              {userInLS && <TickerCard {...tickerProps} state={state}/>}
            </Grid>
          ))}
        </Grid>
        <Footer/>
      </div>
    )
  );
};

export default Home;
