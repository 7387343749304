import { makeStyles } from '@material-ui/core';
import { globalStyles } from 'styles/globalStyles';

export const fullWidth = 12;

export const generateStyles = makeStyles(theme => ({
  heroContainer: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  root: {
    padding: '20px',
    display: 'flex',
    width: '50%',
    flexDirection: 'column'
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '30px'
  },
  textField: {
    width: '100%'
  },
  marginBottom: {
    marginBottom: '30px'
  },
  ...globalStyles
}));
