import HowToUseThisPlatform from 'pages/studyAdvice/sections/_HowToUseThisPlatform';
import Rationale from 'pages/studyAdvice/sections/_Rationale';
import StudyPlanAdvice from 'pages/studyAdvice/sections/_StudyPlanAdvice';
import GeneralStudyAdvice from 'pages/studyAdvice/sections/_GeneralStudyAdvice';
import SaqAdvice from 'pages/studyAdvice/sections/_SaqAdvice';
import ExamTechniqueAdvice from 'pages/studyAdvice/sections/_ExamTechniqueAdvice';
import ModelSaqSites from 'pages/studyAdvice/sections/_ModelSaqSites';
import OtherOnlineMaterials from 'pages/studyAdvice/sections/_OtherOnlineMaterials';
import Books from 'pages/studyAdvice/sections/_Books';
import ExamResources from 'pages/studyAdvice/sections/_ExamResources';
import Courses from 'pages/studyAdvice/sections/_Courses';
import Tutorials from 'pages/studyAdvice/sections/_Tutorials';
import StudyGroups from 'pages/studyAdvice/sections/_StudyGroups';


export default {
  // Approach To Exam
  ExamTechniqueAdvice,
  GeneralStudyAdvice,
  HowToUseThisPlatform,
  Rationale,
  SaqAdvice,
  StudyPlanAdvice,
  // Reference Material
  ModelSaqSites,
  OtherOnlineMaterials,
  Books,
  ExamResources,
  Courses,
  Tutorials,
  StudyGroups
};
