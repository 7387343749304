import React from 'react';
import { Typography, Grid, Link } from '@material-ui/core';

import { fullWidth } from 'pages/studyAdvice/styles.js';
import { NAVPATH } from 'constants/navigation';

const SaqAdvice = ({ useStylesWithTheme }) => {
  const styles = useStylesWithTheme()();
  return (
    <>
      <Grid item md={fullWidth} className={styles.headingsContainer}>
        <Typography className={styles.h2}>SAQ advice</Typography>
      </Grid>

      <Grid container className={styles.plainTextContainer}>
        <Grid item>
          <ul className={styles.unorderedList}>
            <li>
              <Typography className={styles.plainText}>
                Plan your SAQ structure first – you can do this briefly on scrap
                paper (you’ll have some in the exam). Think to yourself: what headings
                should I use? Do any SAQ&nbsp;
                {
                  <Link
                    href={NAVPATH.RESOURCES__TEMPLATES}
                    className={styles.links}
                  >
                    templates
                  </Link>
                }{' '}
                apply? Should I use a table?
              </Typography>
            </li>

            <li>
              <Typography className={styles.plainText}>
                Think if any{' '}
                {
                  <Link
                    href={NAVPATH.RESOURCES__DIAGRAMS}
                    className={styles.links}
                  >
                    Diagrams
                  </Link>
                }
                ,{' '}
                {
                  <Link
                    href={NAVPATH.RESOURCES__EQUATIONS}
                    className={styles.links}
                  >
                    Equations
                  </Link>
                }
                , or specific examples apply?
              </Typography>
            </li>

            <li>
              <Typography className={styles.plainText}>
                Write out the headings you chose in your structure & briefly
                flesh these out, then go back & elaborate. Doing this means:
              </Typography>
            </li>

            <ul className={styles.unorderedListNestingLevelTwo}>
              <li>
                <Typography className={styles.plainText}>
                  You’ll avoid having only completed half your structure and
                  running out of time (instead you may run out of time but have a
                  complete answer with less detail).
                </Typography>
              </li>
              <li>
                <Typography className={styles.plainText}>
                  The examiner knows which direction you’re headed if you DO run
                  out of time.
                </Typography>
              </li>
              <li>
                <Typography className={styles.plainText}>
                  You have a rough idea of how quick you need to be to finish
                  your answer in 10 min.
                </Typography>
              </li>
              <li>
                <Typography className={styles.plainText}>
                  You’ve thought about structure.
                </Typography>
              </li>
            </ul>

            <li>
              <Typography className={styles.plainText}>
                Write the parts of the SAQ worth the most marks first – you’re
                aiming to get a comfortable pass for each SAQ (12-15/20). Early
                marks (marks 0-5) are easier to get than late marks (marks
                15-20) so if you score 16-20/20 it’s likely you’re spending too
                long on that SAQ.
              </Typography>
            </li>

            <li>
              <Typography className={styles.plainText}>
                Write neatly but be fast & leave LOTS OF SPACE (in the exam you
                can ask for more answer booklets). Use bullets, shorthand,
                arrows & buzz words.
              </Typography>
            </li>

            <li>
              <Typography className={styles.plainText}>
                If you’re struggling for additional points, think of the special
                groups mentioned in the syllabus (disease – especially critical
                ill; obesity; pregnancy – including effects on fetus &
                lactation; extremes of age) & whether you can write anything
                relevant on them.
              </Typography>
            </li>
          </ul>
        </Grid>
      </Grid>
    </>
  );
};

export default SaqAdvice;
