/* eslint-disable no-useless-escape */
import React, {useContext, useEffect} from 'react';
import {Typography, useTheme} from '@material-ui/core';
import {generateStyles} from 'pages/login/styles';
import Footer from '../../appLayout/components/footer/Footer';
import {store} from '../../store';
import axios from 'axios';
import {NAVPATH} from '../../constants/navigation';

const LoginSuccess = props => {
  const globalState = useContext(store);
  const {dispatch} = globalState;
  useEffect(() => {
    const {match} = props;
    const userId = match ? match.params.id : null;
    axios
      .get(`/users/${userId}`)
      .then((response) => {
        const retrievedUser = response.data[0];
        localStorage.setItem('user', JSON.stringify(retrievedUser));
        dispatch({
          type: 'set user in store',
          data: retrievedUser,
        });
        dispatch({
          type: 'set checked status of all physiology syllabus',
          data: retrievedUser.physiologyProgress || {},
        });
        dispatch({
          type: 'set checked status of all pharmacology syllabus',
          data: retrievedUser.pharmacologyProgress || {},
        });
        dispatch({
          type: 'set checked status of all saqs',
          data: retrievedUser.saqsProgress || {},
        });
        dispatch({
          type: 'update progress boolean in store',
          data: false,
        });

        if (!response.data[0].trialEndDate) {
          props.history.push(`${NAVPATH.FIRSTTIMEUSERWITHID}/${retrievedUser.id}`)
        }

        props.history.push(NAVPATH.HOME)
      })
      .catch((err) => console.log(err));
  })

  const useStylesWithTheme = generateStyles(useTheme());
  const styles = useStylesWithTheme()();

  return (
    <div className={styles.main}>
      <Typography className={styles.title}>
        You are successfully logged in, please wait while we take your to the dashboard...
      </Typography>
      <div className={styles.footerContainer}>
        <Footer/>
      </div>
    </div>
  );
};

export default LoginSuccess;
