import {makeStyles} from '@material-ui/styles';
import {globalStyles} from 'styles/globalStyles';

export const generateStyles = () => () =>
  makeStyles({
    main: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    },
    loginMain: {
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'column'
    },
    title: {
      margin: '20px 0 30px 0'
    },
    loginBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '10px 20px',
      border: '1px solid black'
    },
    loginRegisterText: {
      margin: '20px 0 20px 0'
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'column'
    },
    ...globalStyles
  });

export const fullWidth = 12;
