import React from 'react';
import { makeStyles } from '@material-ui/core';
import { globalStyles } from 'styles/globalStyles';

const searchHeaderStyles = makeStyles((theme) => ({
  ...globalStyles,
}));

const SearchHeader = (props) => {
  const classes = searchHeaderStyles();
  return <div className={classes.headingsContainer}>{props.children}</div>;
};

export default SearchHeader;
