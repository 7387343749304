import React from 'react';
import { Grid, Typography, useTheme } from '@material-ui/core';
import { templates } from 'pages/resources/sections/templates.json';

import { generateStyles } from 'pages/resources/styles';

import Footer from 'appLayout/components/footer/Footer';

const ListSection = ({
  listOfTemplates,
  topicKey,
  parentFactKey,
  styles,
  firstIteration,
}) => {
  return listOfTemplates.map((eachTemplate, factIdx) => {
    let key = parentFactKey
      ? parentFactKey
      : `topic-${topicKey}-fact${factIdx}`;

    if (typeof eachTemplate === 'string') {
      return (
        <React.Fragment key={`${key}-point${factIdx}`}>
          <li>{eachTemplate}</li>
        </React.Fragment>
      );
    }

    const ulClassName = firstIteration
      ? styles.unorderedList
      : styles.unorderedListNestingLevelTwo;

    return (
      <React.Fragment key={`${key}-subfact${factIdx}`}>
        <ul className={ulClassName}>
          <ListSection
            listOfTemplates={eachTemplate}
            parentFactKey={`${key}-subfact${factIdx}`}
            styles={styles}
            firstIteration={false}
          />
        </ul>
      </React.Fragment>
    );
  });
};

const templatesStyles = {
  templateContainer: {
    marginTop: '20px'
  }
};

const _Templates = () => {
  const useStylesWithTheme = generateStyles(useTheme());
  const styles = useStylesWithTheme(templatesStyles)();
  return (
    <>
      <Grid container className={styles.templateContainer}>
        {templates.map(([subSectionTopic, ...listOfTemplates], topicKey) =>
            <Grid item md={12} key={`fast-facts-subsection-${topicKey}`}>
              <Grid item md={12} className={styles.headingsContainer}>
                <Typography className={styles.h1}>{subSectionTopic}</Typography>
              </Grid>
              <Grid
                key={`fast-facts-subsection-${topicKey}`}
                item
                md={12}
                className={styles.plainTextContainer}
              >
                <ListSection
                  listOfTemplates={listOfTemplates}
                  topicKey={topicKey}
                  styles={styles}
                  firstIteration
                />
              </Grid>
            </Grid>
        )}
      </Grid>
      <Footer />
    </>
  );
};

export default _Templates;
