export const NAVPATH = {
  HOME: '/home',
  LANDING: '/',
  LOGIN: '/login',
  PAYMENT: '/payment',
  PAYMENT_SUCCESS: '/payment-success/:id',
  PAYMENT_FAILURE: '/payment-failed/:id',
  FIRSTTIMEUSER: '/freetrial',
  FIRSTTIMEUSERWITHID: '/freetrial/:id',
  LOGIN_SUCCESS: '/login/success',
  LOGIN_SUCCESS_QSID: '/login/success/:id',
  STUDY_ADVICE: '/studyadvice',
  HOW_TO_USE: '/howtouse',
  STUDY_PLAN: '/studyplan',
  SYLLABUS: '/syllabus',
  SYLLABUS__PHYSIOLOGY: '/syllabus/physiology',
  SYLLABUS__PHARMACOLOGY: '/syllabus/pharmacology',
  MCQ: '/mcq',
  SAQS: '/saqs',
  SAQS__BY_SYLLABUS: '/saqs/syllabus',
  SAQS__BY_PAPER: '/saqs/paper',
  SAQ__BY_ID: '/saqs/:id',
  CONTACT: '/contact',
  CONTACT__SUCCESS: '/contact/success',
  RESOURCES: '/resources',
  RESOURCES__DEFINITIONS: '/resources/definitions',
  RESOURCES__FAST_FACTS: '/resources/fast-facts',
  RESOURCES__EQUATIONS: '/resources/equations',
  RESOURCES__DIAGRAMS: '/resources/diagrams',
  RESOURCES__TEMPLATES: '/resources/templates',
  RESOURCES__RANDOM_PAPER: '/resources/randomPaper',
  SEARCH: '/search'
}