import React from 'react';
import { Grid } from '@material-ui/core';

export const GridContent = (props) => {
  const { styles, header, text, images } = props;
  return (
    <Grid item md={12} className={styles.marginBtmMed}>
      <Grid item md={12} className={`${styles.headingsContainer}`}>
        <h3 className={`${styles.h3}`}>{header}</h3>
      </Grid>
      <Grid item md={12} className={styles.plainTextContainer}>
        <span className={styles.plainText}>{text}</span>
      </Grid>
      <div className={styles.imagesHeroContainer}>
        <Grid container className={styles.gridImageOverallContainer}>
          {images.map((img, idx) => (
            <Grid
              item
              md={6}
              key={`img-${idx}`}
              className={styles.gridImageContainer}
            >
              <img
                src={`/images/${img.src}`}
                alt={img.alt}
                className={styles.gridImageStyle}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </Grid>
  );
};
