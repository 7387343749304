import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Checkbox, Grid, Link, makeStyles, Typography, } from '@material-ui/core';
import { URLS } from 'constants/url';
import { store } from 'store';
import Footer from '../../appLayout/components/footer/Footer';

const saqStyles = makeStyles((theme) => ({
  indSaqContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
  },
  tableHeaders: {
    fontSize: '14px',
    padding: '10px',
  },
  rowHeaders: {
    fontSize: '25px',
    padding: '10px 10px 10px 20px',
    textDecoration: 'underline',
  },
  link: {
    margin: theme.spacing(0),
    fontSize: '14px',
    color: 'rgb(0, 0, 255)',
  },
  deadLink: {
    margin: theme.spacing(0),
    fontSize: '14px',
  },
  cellWrapper: {
    padding: '10px',
    wordBreak: 'break-all',
    wordWrap: 'break-word',
  },
  paddingRightOnly: {
    padding: '0 5px 0 0'
  }
}));

const IndividualSaqById = (props) => {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const saqId = props.match.params.id;
  const [individualSaq, setIndividualSaq] = useState(null);
  const loggedInUser = JSON.parse(localStorage.getItem('user'));
  useEffect(() => {
    if(individualSaq) return;
    axios
      .get(`${URLS.SAQS}/${saqId}`)
      .then((response) => {
        const saq = response.data[0] || response.data;
        setIndividualSaq(saq);
      })
      .catch((err) => console.log(err));
  });

  const findSaqProgressByPaperSaqId = (paperSaqId) => {
    if(loggedInUser) {
      return loggedInUser.saqsProgress[paperSaqId];
    }
    return false;
  };

  const handleCheckboxOnChange = (event, id, dispatch) => {
    axios
      .put(`${URLS.SAQS_PROGRESS}/${loggedInUser.id}`, {
        paperSaqId: id,
        value: event.target.checked,
      })
      .then((response) => {
        response.data && localStorage.setItem('user', JSON.stringify(response.data));
        dispatch({
          type: 'change checked status of saq row',
          data: { id, checked: !!response.data.saqsProgress[id] },
        });
        dispatch({
          type: 'update progress boolean in store',
          data: true,
        });
      })
      .catch((err) => console.log(err));
  };

  const renderTableHeaders = (styles) => (
    <Grid container className={styles.tableWrapper}>
      <Grid item md={1}>
        <Typography className={styles.tableHeaders} color="secondary">
          SAQ
        </Typography>
      </Grid>
      <Grid item md={1}>
        <Typography className={styles.tableHeaders} color="secondary">
          Syllabus
        </Typography>
      </Grid>
      <Grid item md={5}>
        <Typography className={styles.tableHeaders} color="secondary">
          Question
        </Typography>
      </Grid>
      <Grid item md={1}>
        <Typography className={styles.tableHeaders} color="secondary">
          ER (external)
        </Typography>
      </Grid>
      <Grid item md={1}>
        <Typography className={styles.tableHeaders} color="secondary">
          CW (external)
        </Typography>
      </Grid>
      <Grid item md={1}>
        <Typography className={styles.tableHeaders} color="secondary">
          IPP (external)
        </Typography>
      </Grid>
      <Grid item md={2}>
        <Typography className={styles.tableHeaders} color="secondary">
          Related SAQs
        </Typography>
      </Grid>
    </Grid>
  );

  const renderSaqs = (saqs, rowSaqId, styles, grouped = false) => {
    if(!saqs) return null;

    const filteredSaqs = saqs.filter((saq) => saq !== rowSaqId);
    return filteredSaqs.map((saq, idx) => {
      if(typeof saq !== 'string')
        return renderSaqs(saq, rowSaqId, styles, true);
      const paperDate = parseInt(saq.substring(0, 4));
      const isOnlyOneSaq = saq.length === 1;
      return paperDate > 2011 ? (
        <React.Fragment key={`relatedSaq-${saq}-${idx}`}>
          {idx === 0 && '['}
          <Link
            key={`relatedSaq-${saq}-${idx}`}
            href={`${saq.trim()}`}
            target={'_blank'}
            className={styles.link}
          >
            {saq}
          </Link>

          {idx < filteredSaqs.length - 1 && !isOnlyOneSaq && ', '}
          {idx === filteredSaqs.length - 1 && ']'}
          {idx === filteredSaqs.length - 1 && grouped && <br/>}
        </React.Fragment>
      ) : (
        <React.Fragment
          key={`relatedSaq-${saq}-${idx}`}
        >
          {idx === 0 && '['}
          <span className={styles.deadLink}>{saq}</span>
          {idx < filteredSaqs.length - 1 && ', '}
          {idx === filteredSaqs.length - 1 && ']'}
          {idx === filteredSaqs.length - 1 && grouped && <br/>}
        </React.Fragment>
      );
    });
  };

  const styles = saqStyles();
  return (
    individualSaq && (
      <div className={styles.indSaqContainer}>
        <Grid container className={styles.root}>
          {renderTableHeaders(styles)}
          <Grid container className={styles.tableWrapper}>
            <Grid item md={1} className={styles.cellWrapper}>
              <Checkbox
                disableRipple
                color={'primary'}
                onChange={(event) =>
                  handleCheckboxOnChange(
                    event,
                    individualSaq.paperSaqId,
                    dispatch
                  )
                }
                className={styles.paddingRightOnly}
                checked={
                  findSaqProgressByPaperSaqId(individualSaq.paperSaqId)
                }
              />
              {individualSaq.paperSaqId}
            </Grid>
            <Grid item md={1} className={styles.cellWrapper}>
              {individualSaq.syllabusSection}
            </Grid>
            <Grid item md={5} className={styles.cellWrapper}>
              {individualSaq.question}
            </Grid>
            <Grid item md={1} className={styles.cellWrapper}>
              {individualSaq.erLink &&
              individualSaq.erLink.map((link, idx) => (
                <Link
                  key={`erLink${idx}`}
                  href={`${link}`}
                  target="_blank"
                  className={styles.link}
                  color={'secondary'}
                >
                  ER
                </Link>
              ))}
            </Grid>
            <Grid item md={1} className={styles.cellWrapper}>
              {individualSaq.cwLinks &&
              individualSaq.cwLinks.map((link, idx) => (
                <Link
                  key={`cwLink${idx}`}
                  href={`${link}`}
                  target="_blank"
                  className={styles.link}
                  color={'secondary'}
                >
                  CW
                </Link>
              ))}
            </Grid>
            <Grid item md={1} className={styles.cellWrapper}>
              {individualSaq.ippLinks &&
              individualSaq.ippLinks.map((link, idx) => (
                <Link
                  key={`ippLink${idx}`}
                  href={`${link}`}
                  target="_blank"
                  className={styles.link}
                  color={'secondary'}
                >
                  IPP
                </Link>
              ))}
            </Grid>
            <Grid item md={2} className={styles.cellWrapper}>
              {individualSaq &&
              renderSaqs(
                individualSaq.relatedSaqs,
                individualSaq.paperSaqId,
                styles,
                false
              )}
            </Grid>
          </Grid>
        </Grid>
        <Footer/>
      </div>
    )
  );
};

export default IndividualSaqById;
