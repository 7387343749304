import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

import { globalStyles } from 'styles/globalStyles';
import { renderPhysiologySyllabusTableHeaders } from 'commonComponents/TableHeaders';
import { renderPhysiologySyllabusContent } from 'pages/search/commonComponents/RenderContent'
import SearchHeader from 'pages/search/commonComponents/SearchHeader';
import SearchContent from 'pages/search/commonComponents/SearchContent';

const searchPhySyllabusStyles = makeStyles((theme) => ({
  ...globalStyles,
}));

const SearchPhySyllabus = (props) => {
  const { syllabus } = props
  const classes = searchPhySyllabusStyles();
  return (
    <>
      <SearchHeader>
        <Typography className={classes.h1}>Physiology Syllabus</Typography>
      </SearchHeader>

      <SearchContent>
        {renderPhysiologySyllabusTableHeaders(classes, syllabus, true)}
        {renderPhysiologySyllabusContent(syllabus)}
        {(syllabus || []).length === 0 && <Typography className={classes.h3}>No Physiology syllabus found</Typography>}
      </SearchContent>
    </>
  );
};

export default SearchPhySyllabus;
