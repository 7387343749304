import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Checkbox, Grid, Link, makeStyles, Typography } from '@material-ui/core';
import { useSideBarHook } from 'appLayout/components/sidebar/useSideBarHook';
import { SidebarContext } from 'appLayout/components/sidebar/SideBarContextProvider.jsx';
import { URLS } from 'constants/url';
import { areAllSaqsBefore2012, fetchUser, sortRelatedSaqsForPaperAndSyllabus } from 'utils/helpers';
import { globalStyles } from 'styles/globalStyles';
import { store } from 'store';
import Footer from 'appLayout/components/footer/Footer';

const saqStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  saqTableWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0 0 0',
    position: 'sticky',
    backgroundColor: '#d3d3d3',
    height: '45px',
    zIndex: 9,
    top: 0,
  },
  tableHeaders: {
    fontSize: '14px',
    padding: '10px',
  },
  link: {
    margin: theme.spacing(0),
    fontSize: '14px',
    color: 'black',
  },
  cellWrapper: {
    padding: '0px 0px 0px 30px',
    wordBreak: 'break-all',
    wordWrap: 'break-word',
  },
  tableCellWrapper: {
    padding: '0',
    marginBottom: '20px',
  },
  ...globalStyles,
}));

let pageRef = React.createRef();
let listOfRefs = [];
const SaqByPaper = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const [saq, setSaqs] = useState(null);
  const context = useContext(SidebarContext);

  const user = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    if(!state.user) fetchUser(JSON.stringify(user), dispatch, localStorage);

    if(saq) return;
    const abortController = new AbortController();
    axios
      .get(URLS.SAQS)
      .then((response) => {
        const saqs = response.data;
        const saqsWithoutDuplicates = Array.from(
          new Set(
            saqs
              .map((saq) => saq.paperSaqId)
              .map((id) => saqs.find((saq) => saq.paperSaqId === id))
          )
        );
        const sortedSaqsByPaper = saqsWithoutDuplicates.sort((a, b) =>
          a.paperSaqId.localeCompare(b.paperSaqId, 'en', { numeric: true })
        );
        const saqsWithHeaders = [
          ...new Set(
            sortedSaqsByPaper.map((saq) => saq.paperSaqId.substring(0, 5))
          ),
        ]
          .reduce(
            (acc, curr) => [
              ...acc,
              {
                saqs: saqsWithoutDuplicates.filter(
                  (saq) => saq.paperSaqId.substring(0, 5) === curr
                ),
              },
            ],
            []
          )
          .sort((a, b) =>
            b.saqs[0].paperSaqId.localeCompare(a.saqs[0].paperSaqId, 'en', {
              numeric: true,
            })
          )
          .map(header => ({
            saqs: header.saqs.map(headerSaq => sortRelatedSaqsForPaperAndSyllabus(headerSaq))
          }))
        return setSaqs(saqsWithHeaders);
      })
      .catch((err) => console.log(err));

    return () => {
      abortController.abort();
    };
  }, [dispatch, saq, state.user, user]);
  useSideBarHook(listOfRefs);

  const findSaqProgressByPaperSaqId = (paperSaqId) => {
    if(user) {
      return user.saqsProgress[paperSaqId];
    }
    return null;
  };

  const handleCheckboxOnChange = (event, id, dispatch) => {
    context.resetSideBarContext();
    axios
      .put(`${URLS.SAQS_PROGRESS}/${user.id}`, {
        paperSaqId: id,
        value: event.target.checked,
      })
      .then((response) => {
        response.data && localStorage.setItem('user', JSON.stringify(response.data))
        dispatch({
          type: 'change checked status of saq row',
          data: { id, checked: !!response.data.saqsProgress[id] },
        });
        dispatch({
          type: 'update progress boolean in store',
          data: true,
        });
      })
      .catch((err) => console.log(err));
  };

  const renderTableHeaders = (styles) => (
    <Grid container className={styles.saqTableWrapper}>
      <Grid item md={2}>
        <Typography className={styles.tableHeaders} color="secondary">
          SAQ
        </Typography>
      </Grid>
      {/* <Grid item md={2}>
        <Typography className={styles.tableHeaders} color="secondary">
          Syllabus
        </Typography>
      </Grid> */}
      <Grid item md={5}>
        <Typography className={styles.tableHeaders} color="secondary">
          Question
        </Typography>
      </Grid>
      <Grid item md={1}>
        <Typography className={styles.tableHeaders} color="secondary">
          ER (external)
        </Typography>
      </Grid>
      <Grid item md={1}>
        <Typography className={styles.tableHeaders} color="secondary">
          CW (external)
        </Typography>
      </Grid>
      <Grid item md={1}>
        <Typography className={styles.tableHeaders} color="secondary">
          IPP (external)
        </Typography>
      </Grid>
      <Grid item md={2}>
        <Typography className={styles.tableHeaders} color="secondary">
          Related SAQs
        </Typography>
      </Grid>
    </Grid>
  );

  const renderSaqs = (saqs, rowSaqId, saqMongoId, styles, grouped = false) => {
    const filteredSaqs = saqs.filter((saq) => saq !== rowSaqId);
    return filteredSaqs.map((saq, idx) => {
      if(typeof saq !== 'string')
        return renderSaqs(saq, rowSaqId, saqMongoId, styles, true);
      const paperDate = parseInt(saq.substring(0, 4));
      return paperDate > 2011 ? (
        <React.Fragment key={`relatedSaq-${saq}-${idx}`}>
          {idx === 0 && '['}
          <Link
            href={`${saq.trim()}`}
            target={'_blank'}
            className={styles.links}
          >
            {saq}
          </Link>
          {idx < filteredSaqs.length - 1 && ', '}
          {idx === filteredSaqs.length - 1 && ']'}
          {idx === filteredSaqs.length - 1 && grouped && <br/>}
        </React.Fragment>
      ) : (
        <React.Fragment key={`relatedSaq-${saq}-${idx}`}>
          {idx === 0 && '['}
          <span className={styles.deadLink}>{saq}</span>
          {idx < filteredSaqs.length - 1 && ', '}
          {idx === filteredSaqs.length - 1 &&
          filteredSaqs.length > 1 &&
          !areAllSaqsBefore2012(filteredSaqs) &&
          ']'}
          {idx === filteredSaqs.length - 1 &&
          filteredSaqs.length > 1 &&
          areAllSaqsBefore2012(filteredSaqs) &&
          ']'}
          {idx === filteredSaqs.length - 1 && filteredSaqs.length === 1 && ']'}
          {idx === filteredSaqs.length - 1 && grouped && <br/>}
        </React.Fragment>
      );
    });
  };

  const renderAllSaqRows = (saq) => {
    let refs = [];
    return (
      saq &&
      saq.map((section) => {
        let sections = section.saqs.map((saqRowData, idx) => {
          let thisRef;
          if(idx === 0) {
            thisRef = React.createRef();
            refs = refs.concat(thisRef);
          }
          return (
            <Grid container key={saqRowData._id}>
              {idx === 0 && (
                <Grid className={styles.headingsContainer}>
                  <div style={{ height: '45px' }} ref={thisRef}/>
                  <Typography className={styles.h1} color="primary">
                    {saqRowData.paperSaqId.substring(0, 5)}
                  </Typography>
                </Grid>
              )}
              <Grid container className={styles.tableCellWrapper}>
                <Grid item md={2} className={styles.cellWrapper}>
                  {/* eslint-disable jsx-a11y/anchor-is-valid */}
                  <Checkbox
                    disableRipple
                    color={'primary'}
                    onChange={(event) =>
                      handleCheckboxOnChange(event, saqRowData.paperSaqId, dispatch)
                    }
                    checked={!!findSaqProgressByPaperSaqId(saqRowData.paperSaqId)}
                    classes={{ root: styles.checkBoxNoPaddingAndSmRightMargin }}
                  />
                  <a name={`${saqRowData.paperSaqId.toString()}`}>
                    {saqRowData.paperSaqId}
                  </a>
                </Grid>
                {/* <Grid item md={2} className={styles.cellWrapper}>
                  {saqRowData.syllabusSection}
                </Grid> */}
                <Grid item md={5} className={styles.cellWrapper}>
                  {saqRowData.question}
                </Grid>
                <Grid item md={1} className={styles.cellWrapper}>
                  {saqRowData.erLink.map((link, idx) => (
                    <Link
                      key={`erLink${idx}`}
                      href={`${link}`}
                      target="_blank"
                      className={styles.links}
                    >
                      ER
                    </Link>
                  ))}
                </Grid>
                <Grid item md={1} className={styles.cellWrapper}>
                  {saqRowData.cwLinks.map((link, idx) => (
                    <Link
                      key={`cwLink${idx}`}
                      href={`${link}`}
                      target="_blank"
                      className={styles.links}
                    >
                      CW
                    </Link>
                  ))}
                </Grid>
                <Grid item md={1} className={styles.cellWrapper}>
                  {saqRowData.ippLinks.map((link, idx) => (
                    <Link
                      key={`ippLink${idx}`}
                      href={`${link}`}
                      target="_blank"
                      className={styles.links}
                    >
                      IPP
                    </Link>
                  ))}
                </Grid>
                <Grid item md={2} className={styles.cellWrapper}>
                  {renderSaqs(
                    saqRowData.relatedSaqs,
                    saqRowData.paperSaqId,
                    saqRowData._id,
                    styles,
                    false
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        });
        listOfRefs = refs;
        return sections;
      })
    );
  };

  const styles = saqStyles();
  return (
    <div className="syllabus">
      <Grid container className={styles.root} ref={pageRef}>
        {renderTableHeaders(styles)}
        {renderAllSaqRows(saq)}
      </Grid>
      <Footer/>
    </div>
  );
};

export default SaqByPaper;
