import React from 'react';
import { Grid, Link, Typography, useTheme } from '@material-ui/core';
import MathJax from 'react-mathjax';

import { equations } from 'pages/resources/sections/equations.json';
import { generateStyles } from 'pages/resources/styles';

import Footer from 'appLayout/components/footer/Footer';

const ListSection = ({
  listOfEquations,
  topicKey,
  parentEquationKey,
  styles,
}) => {
  return listOfEquations.map((eachEquation, eqIdx) => {
    let key = parentEquationKey
      ? parentEquationKey
      : `topic-${topicKey}-equation${eqIdx}`;

    if (typeof eachEquation === 'string') {
      const isMathEquation = eachEquation.substring(0, 9) === 'equation:';
      if (eachEquation.includes('@@')) {
        return (
          <React.Fragment key={`${key}-point${eqIdx}`}>
            <ul className={styles.unorderedList}>
              <li>
                {eachEquation.split('@@')[0]}
                {eachEquation
                  .split('@@')
                  .filter((_, index) => index !== 0)
                  .map((link, linkIdx) => {
                    const shouldRouteToDiagrams = link.includes('Diagrams');
                    const tag = shouldRouteToDiagrams
                      ? 'diagrams'
                      : `#${link.trim().toLowerCase()}`;
                    return (
                      <Link
                        href={tag}
                        key={`internal-eq-link${linkIdx}`}
                        className={styles.links}
                        style={{ color: 'rgb(0, 0, 255)' }}
                      >
                        {link.trim()};{' '}
                      </Link>
                    );
                  })}
              </li>
            </ul>
          </React.Fragment>
        );
      }

      return isMathEquation ? (
        <MathJax.Provider key={`${key}-math-equation${eqIdx}`}>
          <div className={styles.equationContainer}>
            <MathJax.Node inline formula={eachEquation.substring(9)} />
          </div>
        </MathJax.Provider>
      ) : (
        <React.Fragment key={`${key}-point${eqIdx}`}>
          <ul className={styles.unorderedList}>
            <li>
              <Typography>{eachEquation}</Typography>
            </li>
          </ul>
        </React.Fragment>
      );
    }

    if (typeof eachEquation[0] === 'object') {
      return (
        <ListSection
          listOfEquations={
            eachEquation.length < 2 ? eachEquation[0] : eachEquation
          }
          parentEquationKey={`equations-${key}-subsubequation${eqIdx}`}
          styles={styles}
          key={`listSection-key-${eqIdx}`}
        />
      );
    }
    const isEquationWithTitle = eachEquation[0].substring(0, 5) === 'title';
    return isEquationWithTitle ? (
      <React.Fragment key={`${key}-subequation${eqIdx}`}>
        <Typography className={styles.plainText}>
          {eachEquation[0].substring(6)}
        </Typography>

        <MathJax.Provider key={`${key}-math-equation${eqIdx}`}>
          <div className={styles.equationContainer}>
            <MathJax.Node inline formula={eachEquation[1]} />
          </div>
        </MathJax.Provider>
      </React.Fragment>
    ) : (
      <React.Fragment key={`${key}-subequation${eqIdx}`}>
        <ListSection
          listOfEquations={eachEquation}
          parentEquationKey={`${key}-subsubequations${eqIdx}`}
          styles={styles}
        />
      </React.Fragment>
    );
  });
};

const equationsStyles = {
  topicSection: { paddingLeft: '10px' },
  container: { marginTop: '20px' }
};

const _Equations = () => {
  const useStylesWithTheme = generateStyles(useTheme());
  const styles = useStylesWithTheme(equationsStyles)();
  return (
    <>
      <Grid container className={styles.container}>
        {equations.map(([subSectionTopic, ...listOfEquations], topicKey) => (
          <Grid item md={12} key={`equations-subsection-${topicKey}`}>
            <Grid item md={12} className={styles.headingsContainer}>
              <Typography
                className={styles.h3}
                id={subSectionTopic.trim().toLowerCase()}
              >
                {subSectionTopic}
              </Typography>
            </Grid>
            <Grid className={styles.plainTextContainer}>
              <ListSection
                listOfEquations={listOfEquations}
                topicKey={topicKey}
                styles={styles}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Footer />
    </>
  );
};

export default _Equations;
