import React, { useState } from 'react';
import {
  Tab,
  Popper,
  Paper,
  MenuList,
  MenuItem,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/*
  #TODO
  - Styling
    |_ Highlight tab's bottom border when on page
*/

const getNavTabStyles = makeStyles({
  menuItem: {
    width: '100%',
    textAlign: 'left'
  },
  popper: {
    zIndex: 99
  },
  tabText: {
    textTransform: 'capitalize'
  }
});

export const NavTab = ({
  label,
  value,
  path,
  menuList,
  navigateTo
}) => {
  const styles = getNavTabStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  let timer = null;

  const onMouseEnter = event => {
    // Note: Material React's tab is built on html <Button />
    if (anchorEl && event.currentTarget.type !== 'Button') {
      return clearTimeout(timer);
    }
    setAnchorEl(event.currentTarget);
  };

  const onMouseLeave = () => {
    timer = setTimeout(() => setAnchorEl(null), 20);
  };

  const navigateOnClick = path => () => {
    navigateTo(path);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <Tab
        label={label}
        value={value}
        className={styles.tabText}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={menuList ? null : navigateOnClick(path)}
      />
      {menuList && (
        <Popper
          id={open ? `nav-${value}-popper` : undefined}
          open={open}
          anchorEl={anchorEl}
          placement="bottom"
          transition
          className={styles.popper}
        >
          <Paper>
            <MenuList onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
              {menuList.map(p => {
                return (
                  <MenuItem
                    key={`navTabMenuItem-${p.label}`}
                    onClick={navigateOnClick(`${path}/${p.id}`)}
                  >
                    <Typography
                      className={styles.menuItem}
                      variant="caption"
                      align="center"
                    >
                      {p.label}
                    </Typography>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Paper>
        </Popper>
      )}
    </>
  );
};
