import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  TextField
} from '@material-ui/core';

import { generateStyles } from './styles';
import {
  filterFormFieldValuesForNonEmptyStrings,
  validateEmptyString,
  validateEmail
} from 'utils/formValidators';

const Form = ({ history }) => {
  const styles = generateStyles();

  const [formFieldValues, setFormFieldValues] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [formFieldErrors, setFormFieldErrors] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleOnChange = (e, field) => {
    const value = e.target.value;
    setFormFieldValues({
      ...formFieldValues,
      [field]: value
    });
  };

  const handleSubmitOnClick = () => {
    const emailValidators = [validateEmptyString, validateEmail];
    const { name, email, message } = formFieldValues;

    // Email validation
    const runEmailValidation = emailValidators
      .map(validator => validator(email))
      .filter(value => value);
    const emailErrorMessage =
      runEmailValidation.length > 0 ? runEmailValidation[0] : '';

    const errors = {
      name: validateEmptyString(name),
      email: emailErrorMessage,
      message: validateEmptyString(message)
    };

    if (filterFormFieldValuesForNonEmptyStrings(errors).length > 0) {
      setFormFieldErrors(errors);
    } else {
      sendDataToEmail();
    }
  };

  const sendDataToEmail = async (
    url = 'https://submit-form.com/a8r5wtxK',
    data = {
      name: formFieldValues.name,
      email: formFieldValues.email,
      message: formFieldValues.message,
      subject: `Form submission from ${formFieldValues.email}`
    }
  ) => {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(data)
    });
    await response.json();
    clearAllFieldsAndErrors();
    history.push('/contact/success');
  };

  const clearAllFieldsAndErrors = () => {
    setFormFieldValues({
      name: '',
      email: '',
      message: ''
    });
    setFormFieldErrors({
      name: '',
      email: '',
      message: ''
    });
  };

  return (
    <>
      <Grid item md={3} className={styles.inputsContainer}>
        
        <Grid item md={12} className={styles.marginBottom}>
          <FormControl>
            <InputLabel htmlFor="name">Name</InputLabel>
            <Input
              id="name"
              value={formFieldValues.name}
              onChange={e => handleOnChange(e, 'name')}
              error={!!formFieldErrors.name}
            />
            {formFieldErrors.name && (
              <FormHelperText id="name-error-text" error>
                {formFieldErrors.name}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item md={12}>
          <FormControl>
            <InputLabel htmlFor="email">Email address</InputLabel>
            <Input
              id="email"
              value={formFieldValues.email}
              onChange={e => handleOnChange(e, 'email')}
              error={!!formFieldErrors.email}
            />
            {formFieldErrors.email && (
              <FormHelperText id="email-error-text" error>
                {formFieldErrors.email}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        
      </Grid>

      <Grid item md={12} className={styles.marginBottom}>
        <TextField
          id="outlined-multiline-static"
          label="Message"
          multiline
          rows="4"
          className={styles.textField}
          margin="normal"
          variant="outlined"
          error={!!formFieldErrors.message}
          value={formFieldValues.message}
          onChange={e => handleOnChange(e, 'message')}
        />
        {formFieldErrors.message && (
          <FormHelperText id="message-error-text" error>
            {formFieldErrors.message}
          </FormHelperText>
        )}
      </Grid>

      <Grid item md={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmitOnClick}
        >
          Submit
        </Button>
      </Grid>
    </>
  );
};

export default Form;
