import React from 'react'
// noinspection ES6CheckImport
import {Redirect, Route} from 'react-router-dom'
import {NAVPATH} from 'constants/navigation'
import {shouldUserBeDirectedToPaymentPage} from './utils/helpers';
import FirstTimeUser from './pages/firstTimeUser/FirstTimeUser';
import Payment from './pages/payment/Payment';

const ProtectedRoute = (props) => {
  const {component: Component, path, nested = false, exactPath = true} = props
  const isAuthenticated = !!(localStorage.getItem('user'))

  const renderRoutedComponent = (nested, Component, path) =>
    nested ? (
      <Route exact={exactPath} path={path}>
        <Component/>
      </Route>
    ) : (
      <Route exact={exactPath} path={path} component={Component}/>
    )

  if (isAuthenticated) {
    const isPaymentSuccessPath = path === '/payment-success/:id'
    const user = JSON.parse(localStorage.getItem('user'))
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const hasFreeTrialEndDate = !!user.trialEndDate
    // If user hasn't started free trial, redirect to free trial page
    if (!hasFreeTrialEndDate) {
      return <Route path={path} component={FirstTimeUser}/>
    } else {
      if (shouldUserBeDirectedToPaymentPage(user) && !isPaymentSuccessPath) {
        return <Route path={path} component={Payment}/>
      } else {
        return renderRoutedComponent(nested, Component, path)
      }
    }
  }
  return <Redirect to={{pathname: NAVPATH.LOGIN}}/>
}

export default ProtectedRoute
