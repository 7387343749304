import React from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import { fullWidth } from 'pages/contact/styles';

const OtherOnlineMaterials = ({ useStylesWithTheme }) => {
  const styles = useStylesWithTheme()();
  return (
    <Grid container>
      <Grid item md={fullWidth} className={styles.headingsContainer}>
        <Typography className={styles.h2}>Other online materials</Typography>
      </Grid>
      <Grid item className={styles.plainTextContainer}>
        <ul className={styles.unorderedList}>
          <li>
            <Link
              href={'https://www.cvphysiology.com/'}
              target={'_blank'}
              className={styles.links}
            >
              CV Physiology{' '}
            </Link>
            – good notes on CV physiology and pharmacology
          </li>
          <li>
            <Link
              href={'https://derangedphysiology.com/main/cicm-primary-exam'}
              target={'_blank'}
              className={styles.links}
            >
              Deranged Physiology{' '}
            </Link>
            – addresses a range of ICU topics, including some Primary topics
          </li>
          <li>
            <Link
              href={'http://primprepcast.libsyn.com/'}
              target={'_blank'}
              className={styles.links}
            >
              ICU Primary PrepCast{' '}
            </Link>
            - podcasts on topics from the CICM physiology & pharmacology
            syllabus
          </li>
          <li>
            <Link
              href={'https://partone.litfl.com/'}
              target={'_blank'}
              className={styles.links}
            >
              Part One{' '}
            </Link>
            – excellent Primary exam resource organized in the same structure as
            the syllabus. Great accurate, clear and succinct notes.
          </li>
        </ul>
      </Grid>
    </Grid>
  );
};

export default OtherOnlineMaterials;
