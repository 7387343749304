import React from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { diagrams } from 'pages/resources/sections/diagrams.json';
import { globalStyles } from 'styles/globalStyles';

import Footer from 'appLayout/components/footer/Footer';

const getDiagramStyles = makeStyles({
  main: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },
  lineItemsContainer: {
    height: '0 auto',
  },
  imageLineItemsContainer: {
    textAlign: 'center'
  },
  diagramImage: {
    width: '640px',
    height: '30%',
  },
  diagramImageBottomMargin: {
    width: '640px',
    height: '30%',
    marginBottom: '30px',
  },
  ...globalStyles,
});

const handleText = (text, styles) => {
  if(!text.toLowerCase().includes('fast facts')) return text;
  const removeExtraSpaces = text.replace(/\s\s+/g, ' ');
  const convertToArray = removeExtraSpaces.split(' ');
  const removeLastTwoElements = convertToArray.splice(
    0,
    convertToArray.length - 2
  );
  const convertArrayToString = removeLastTwoElements.join(' ');
  return (
    <>
      {convertArrayToString}{' '}
      <Link href={'fast-facts'} className={styles.links}>
        {'Fast Facts'}
      </Link>
    </>
  );
};

const _Diagrams = () => {
  const styles = getDiagramStyles();
  return (
    <>
      <Grid className={styles.main}>
        {diagrams.map((lineItem, idx) => {
          const { style, text, filename } = lineItem;
          return (
            <div
              key={`lineItem${idx}`}
              className={`${style === 'image' ? styles.imageLineItemsContainer : styles.lineItemsContainer} ${idx ===
              diagrams.length - 1 && styles.marginBtmSm}`}
            >
              {style === 'header' && (
                <Grid className={styles.headingsContainer}>
                  <Typography className={styles.h1}>{text}</Typography>
                </Grid>
              )}
              {style === 'subHeader' && (
                <Grid className={styles.headingsContainer}>
                  <Typography className={styles.h2}>{text}</Typography>
                </Grid>
              )}
              {style === 'first' && (
                <div className={styles.plainTextContainerNoBottomMargin}>
                  <ul
                    className={
                      lineItem.lastline
                        ? styles.firstListBottomMargin
                        : styles.firstList
                    }
                  >
                    <li>
                      <Typography className={styles.diagramsFirst}>
                        {handleText(text, styles)}
                      </Typography>
                    </li>
                  </ul>
                </div>
              )}
              {style === 'second' && (
                <div className={styles.plainTextContainerNoBottomMargin}>
                  <ul
                    className={
                      lineItem.lastline
                        ? styles.secondListBottomMargin
                        : styles.secondList
                    }
                  >
                    <li>
                      <Typography className={styles.diagramsSecond}>
                        {handleText(text, styles)}
                      </Typography>
                    </li>
                  </ul>
                </div>
              )}
              {style === 'third' && (
                <div className={styles.plainTextContainerNoBottomMargin}>
                  <ul
                    className={
                      lineItem.lastline
                        ? styles.thirdListBottomMargin
                        : styles.thirdList
                    }
                  >
                    <li>
                      <Typography className={styles.diagramsThird}>
                        {handleText(text, styles)}
                      </Typography>
                    </li>
                  </ul>
                </div>
              )}
              {style === 'plaintext' && (
                <div className={styles.plainTextContainerNoBottomMargin}>
                  <Typography className={styles.plainText}>
                    {handleText(text, styles)}
                  </Typography>
                </div>
              )}
              {style === 'image' && (
                <img
                  src={`/images/${filename}`}
                  alt={filename}
                  className={
                    lineItem.lastline
                      ? styles.diagramImageBottomMargin
                      : styles.diagramImage
                  }
                />
              )}
            </div>
          );
        })}
      </Grid>
      <Footer/>
    </>
  );
};

export default _Diagrams;
