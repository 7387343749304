import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import { fullWidth } from 'pages/studyAdvice/styles';
import { NAVPATH } from 'constants/navigation';

const customStyles = theme => ({
  listBody: theme.typography.body1,
  listHeader: {
    fontWeight: 'bold'
  }
});

const HowToUseThisPlatform = ({ useStylesWithTheme }) => {
  const addOnStyles = customStyles(useTheme());
  const styles = useStylesWithTheme(addOnStyles)();
  return (
    <Grid container item md={fullWidth} className={styles.headingsContainer}>
      <Typography className={styles.h2}>
        Summary of how to use this platform
      </Typography>
      <ul className={styles.orderedList}>
        <li>
          <Link to={NAVPATH.STUDY_PLAN} className={styles.links}>
            Study Plan
          </Link>{' '}
          to create a systematic study plan
        </li>
        <li>Choose your style</li>

        <ul className={styles.unorderedListNestingLevelTwo}>
          <li>
            I just want to do{' '}
            <Link to={NAVPATH.SAQS__BY_SYLLABUS} className={styles.links}>
              SAQs by syllabus
            </Link>{' '}
            to complete highest-yield SAQs for each section of the syllabus
          </li>
          <li>
            I have a little more time – still use{' '}
            <Link to={NAVPATH.SAQS__BY_SYLLABUS} className={styles.links}>
              SAQs by syllabus
            </Link>{' '}
            to complete highest-yield SAQs, but also use the Syllabus{' '}
            <Link to={NAVPATH.SYLLABUS__PHYSIOLOGY} className={styles.links}>
              physiology
            </Link>{' '}
            and{' '}
            <Link to={NAVPATH.SYLLABUS__PHARMACOLOGY} className={styles.links}>
              pharmacology
            </Link>{' '}
            pages to read up on each syllabus Ability
          </li>
        </ul>
        <li>
          Use{' '}
          <Link to={NAVPATH.RESOURCES__FAST_FACTS} className={styles.links}>
            Fast Facts
          </Link>
          ,{' '}
          <Link to={NAVPATH.RESOURCES__EQUATIONS} className={styles.links}>
            Equations
          </Link>{' '}
          and{' '}
          <Link to={NAVPATH.RESOURCES__DIAGRAMS} className={styles.links}>
            Diagrams
          </Link>{' '}
          in your day-to-day study
        </li>
        <li>
          Use{' '}
          <Link to={NAVPATH.RESOURCES__TEMPLATES} className={styles.links}>
            Templates
          </Link>{' '}
          to give you default templates to use for SAQs – particularly helpful
          for SAQs you haven’t seen before
        </li>
        <li>
          Use{' '}
          <Link to={NAVPATH.RESOURCES__RANDOM_PAPER} className={styles.links}>
            Random Paper
          </Link>{' '}
          to generate a list of 10 random SAQs to do under exam conditions, just
          prior to sitting the Written
        </li>
      </ul>
    </Grid>
  );
};

export default HowToUseThisPlatform;
