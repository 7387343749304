const margins = {
  marginBtmSm: {
    marginBottom: '20px',
  },
  marginBtmMed: {
    marginBottom: '40px',
  },
  marginBtmLg: {
    marginBottom: '80px',
  },
  marginBtmXLg: {
    marginBottom: '120px',
  },
  marginRightMed: {
    marginRight: '40px',
  },
  marginTopSm: {
    marginTop: '20px',
  }
};

const negation = {
  noMarginPadding: {
    padding: 0,
    margin: 0,
  },
  noMargin: {
    margin: 0,
  },
  noPadding: {
    padding: 0,
  },
  noBottomPadding: {
    paddingBottom: 0,
  },
};

const footerStyles = {
  footer: {
    height: '10%',
  },
};

export const globalStyles = {
  h1: {
    fontSize: '24px',
    color: '#BB120F',
    fontWeight: 800,
  },
  limitedH1: {
    fontSize: '24px',
    width: '80%',
    color: '#BB120F',
    fontWeight: 800,
    textAlign: 'center',
  },
  h2: {
    fontSize: '20px',
    color: '#BF6753',
    fontWeight: 800,
  },
  h3: {
    fontSize: '16px',
    color: 'black',
    fontWeight: 800,
  },
  plainText: {
    fontSize: '14px',
    color: 'black',
  },
  generalHeroContainer: {
    padding: '20px',
  },
  headingsContainer: {
    paddingLeft: '20px',
    marginBottom: '20px',
  },
  noPaddingHeadingsContainer: {
    marginBottom: '20px',
  },
  plainTextContainer: {
    padding: '0px 20px 0 40px',
    width: '100%',
    marginBottom: '20px',
  },
  plainTextContainerNoBottomMargin: {
    padding: '0px 20px 0 40px',
  },
  equationContainer: {
    padding: '0 0 0 40px',
  },
  syllabusPlainTextContainer: {
    padding: '0',
  },
  flexRowJustifyCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  listText: {
    paddingLeft: '20px',
  },
  orderedList: {
    margin: '0px',
    paddingLeft: '40px',
  },
  howToUseOrderedList: {
    margin: '0px',
    paddingLeft: '80px',
  },
  unorderedListNestingLevelTwo: {
    margin: '0px',
    paddingLeft: '40px',
    listStyleType: 'square',
  },
  generalAdviceUnorderedListNestingLevelTwo: {
    margin: '0px',
    paddingLeft: '40px'
  },
  unorderedList: {
    margin: '0px',
    paddingLeft: '40px',
  },
  links: {
    color: 'rgb(0, 0, 255)',
    textDecoration: 'none',
  },
  privatePolicyLink: {
    color: 'rgb(0, 0, 255)',
    textDecoration: 'none',
    lineHeight: '1.5'
  },
  footerlinks: {
    color: '#BB120F',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  deadLink: {
    fontSize: '14px',
    color: 'black',
  },
  // table styles
  tableWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
    position: 'sticky',
    backgroundColor: '#d3d3d3',
    height: '45px',
    zIndex: 9,
    top: 0,
  },
  saqTableWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 0 0 0',
    position: 'sticky',
    backgroundColor: '#d3d3d3',
    height: '45px',
    zIndex: 9,
    top: 0,
  },
  tableHeaderWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 0 10px 0',
    position: 'sticky',
    backgroundColor: '#d3d3d3',
    height: '45px',
    zIndex: 9,
    top: 0,
  },
  tableHeaders: {
    fontSize: '14px',
    padding: '0px 0px 0px 30px',
  },
  searchTableHeaders: {
    fontSize: '14px'
  },
  studyPlantableHeaderWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0px 10px 0',
    backgroundColor: '#d3d3d3',
    height: '45px',
    zIndex: 9,
    top: 0,
  },
  studyPlanTableHeaders: {
    fontSize: '16px',
    padding: '0',
  },
  studyPlanTableRowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0px 10px 0',
    height: '45px',
    zIndex: 9,
    top: 0,
  },
  studyPlanTableLastRowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    zIndex: 9,
    padding: '10px 0 20px 0',
    top: 0,
  },
  diagramsFirst: {
    fontSize: '14px',
    color: 'black',
  },
  diagramsSecond: {
    fontSize: '14px',
    color: 'black',
  },
  diagramsThird: {
    fontSize: '14px',
    color: 'black',
  },
  firstList: {
    paddingLeft: '40px',
    fontSize: '14px',
    color: 'black',
    margin: 0,
    padding: 0,
  },
  firstListBottomMargin: {
    paddingLeft: '40px',
    margin: '0 0 30px 0',
    fontSize: '14px',
    color: 'black',
    padding: 0,
  },
  secondList: {
    paddingLeft: '80px',
    listStyleType: 'square',
    fontSize: '14px',
    color: 'black',
    margin: 0,
    padding: 0,
  },
  secondListBottomMargin: {
    paddingLeft: '80px',
    fontSize: '14px',
    color: 'black',
    margin: '0 0 30px 0',
    padding: 0,
  },
  thirdList: {
    paddingLeft: '120px',
    listStyleType: 'square',
    fontSize: '14px',
    color: 'black',
    margin: 0,
    padding: 0,
  },
  thirdListBottomMargin: {
    paddingLeft: '120px',
    margin: '0 0 30px 0',
    fontSize: '14px',
    color: 'black',
    padding: 0,
  },
  spaceBetweenFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  footerContainer: {
    width: '100%',
    bottom: 0
  },
  checkBoxNoPaddingAndSmRightMargin: {
    padding: '0px',
    marginRight: '5px'
  },
  ...margins,
  ...negation,
  ...footerStyles,
};
