import React, { useState } from 'react';
import { Button, Typography, useTheme } from '@material-ui/core';
import { generateStyles } from 'pages/payment/styles';

// noinspection ES6CheckImport
import { loadStripe } from '@stripe/stripe-js';
import Select from '@material-ui/core/Select';
import Footer from '../../appLayout/components/footer/Footer';
// eslint-disable-next-line max-len
// const stripePromise = loadStripe('pk_test_51HKPrKHjlnsTICuDxuxtkXZOUS2Yc6TNSwG6nuFG3KTJCXXF3A6dJq0WIwLDtt9xmQjfPwLBptVESKXADJ7t133u00rJZZdN8E');
// eslint-disable-next-line max-len
const stripePromise = loadStripe('pk_live_51HKPrKHjlnsTICuDrFPAeXwN3yyTi5yQ96hmiWnJ64ZRbsLzLAJgbMuVhedBSmE14vfCMPKyrRmNAOvfsFMdz1xQ00fAgpHQQA');

const Payment = () => {
  const [paymentOption, setPaymentOption] = useState('1');

  const useStylesWithTheme = generateStyles(useTheme());
  const styles = useStylesWithTheme();

  const handlePaymentButtonOnClick = async () => {
    // Get Stripe.js instance
    const stripe = await stripePromise;

    // Call your backend to create the Checkout Session
    const response = await fetch('/payment/create-checkout-session', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ paymentOption })
    });

    const session = await response.json();

    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if(result.error) {
      console.log(result.error.message)
    }
  };

  return (
    <div className={styles.main}>
      <div className={`${styles.paddingLeftMed} ${styles.fullWidth}`}>
        <Typography className={`${styles.title} ${styles.plainText}`}>
          Your access has expired. Please resubscribe to continue using Crit Bear.
          Don’t forget this cost is tax-deductible!
        </Typography>

        <Typography className={`${styles.text} ${styles.plainText}`}>Please select your preferred option:</Typography>

        <div className={styles.paymentContainer}>
          <Select
            native
            value={paymentOption}
            onChange={event => setPaymentOption(event.target.value)}
            inputProps={{
              name: 'price',
              id: 'price-native-simple',
            }}
            classes={{
              select: styles.dropdown,
              selectMenu: styles.selectMenu
            }}
          >
            <option value={'1'}>1 month: $8</option>
            <option value={'3'}>3 months: $22 (10% off)</option>
            <option value={'6'}>6 months: $38 (20% off)</option>
          </Select>
          <Button
            variant="contained"
            color="primary"
            role={'link'}
            onClick={() => handlePaymentButtonOnClick()}
            className={styles.paymentButton}
          >
            Pay Now
          </Button>
        </div>

        <div className={styles.paymentPointsContainer}>
          <Typography className={styles.plainText}>Here’s a reminder of what your subscription is paying
            for:</Typography>
          <ul className={`${styles.unorderedList} ${styles.plainText}`}>
            <li>Website development: had to be custom built from scratch – thousands of $$</li>
            <li>Ongoing maintenance to website including updates as exams are released – hundreds of $$ p.a.
            </li>
            <li>Ongoing cloud server hosting – hundreds of $$ p.a.</li>
            <li>Ongoing cloud database services</li>
            <li>Ongoing domain registration & hosting</li>
            <li>Many hours of my time</li>
          </ul>
        </div>
      </div>

      <div className={styles.footerContainer}>
        <Footer/>
      </div>
    </div>
  );
};

export default Payment;
