import {NAVPATH} from 'constants/navigation';
import {hasFreeTrialDateButHasExpired, hasSubscriptionDateButHasExpired} from '../../../utils/helpers';

export const navTabList = (user) => {
  return (user && user.trialEndDate && !hasFreeTrialDateButHasExpired(user)) ||
  (user && user.trialEndDate &&
    (hasFreeTrialDateButHasExpired(user) && !hasSubscriptionDateButHasExpired(user))
  )
    ? [
      {
        label: 'Dashboard',
        value: 'dashboard',
        path: NAVPATH.HOME,
      },
      {
        label: 'How to use',
        value: 'howtouse',
        path: NAVPATH.HOW_TO_USE,
      },
      {
        label: 'Study Advice',
        value: 'studyadvice',
        path: NAVPATH.STUDY_ADVICE,
      },
      {
        label: 'Study Plan',
        value: 'studyplan',
        path: NAVPATH.STUDY_PLAN,
      },
      {
        label: 'Syllabus',
        value: 'syllabus',
        path: NAVPATH.SYLLABUS,
        menuList: [
          {
            label: 'Physiology',
            id: 'physiology',
          },
          {
            label: 'Pharmacology',
            id: 'pharmacology',
          },
        ],
      },
      // { label: 'MCQs', value: 'mcq', path: NAVPATH.MCQ },
      {
        label: 'SAQs',
        value: 'saq',
        path: NAVPATH.SAQS,
        menuList: [
          {
            label: 'By Syllabus',
            id: 'syllabus',
          },
          {
            label: 'By Paper',
            id: 'paper',
          },
        ],
      },
      {
        label: 'Resources',
        value: 'resources',
        path: NAVPATH.RESOURCES,
        menuList: [
          {
            label: 'Definitions',
            id: 'definitions',
          },
          {
            label: 'Fast Facts',
            id: 'fast-facts',
          },
          {
            label: 'Equations',
            id: 'equations',
          },
          {
            label: 'Diagrams',
            id: 'diagrams',
          },
          {
            label: 'Templates',
            id: 'templates',
          },
          {
            label: 'Random Paper',
            id: 'randomPaper',
          },
        ],
      },
    ]
    : [
      {
        label: 'Features',
        value: 'features',
        path: NAVPATH.LANDING,
      },
      {
        label: 'How to use',
        value: 'howtouse',
        path: NAVPATH.HOW_TO_USE,
      },
      {
        label: 'Study Advice',
        value: 'studyadvice',
        path: NAVPATH.STUDY_ADVICE,
      },
    ];
}
