import React from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import { fullWidth } from 'pages/contact/styles';

/* eslint-disable max-len */
const COURSES_CONTENT = [
  {
    linkTitle: 'Basic Science Clinic\'s Primary Clinic: ',
    description:
      'St. Vincent’s Hospital, Sydney – 3 days. Excellent course focusing on knowledge & key concepts, & SAQ technique. Comes with access to a great app for revision on the go.'
  },
  {
    linkTitle: '',
    description:
      'Exam Performance Workshop: Sydney - excellent workshop run by CICM, but only open to candidates who have had a failed attempt at the exam. Allows candidates a fantastic opportunity to ask examiners questions directly & work through SAQs in small group sessions. Email CICM if eligible. 1 day.'
  },
  {
    linkTitle: 'Gold PERC: ',
    linkTarget: 'http://www.goldperc.com.au',
    description: 'Gold Coast University Hospital, Gold Coast - 1 day.'
  },
  {
    linkTitle: '',
    linkTarget:
      'https://www.cicm.org.au/CICM_Media/CICMSite/CICM-Website/Resources/Events%20and%20Courses/Registration-form-Mater-CICM-short-course-2019.pdf',
    description: `Mater Primary Short Course: Mater Hospital, Brisbane - excellent course 
    focusing on content for the Written. Multiple examiners involved in the teaching. 5 days. 
    Course details released in the months prior to the course.`
  },
  {
    linkTitle: 'South Australian CICM Primary Exam Course (Finnis’ course): ',
    linkTarget: 'https://saica.com.au/course_primary.html',
    description:
      'Adelaide - great course, visiting a lot of Primary content. 5 days.'
  },
  {
    linkTitle: 'VPECC: ',
    linkTarget: 'https://www.vpecc.com',
    description: `Alfred Hospital, Melbourne - good course focusing on exam
    technique. 1 day.`
  },
  {
    linkTitle: 'ANZCA primary exam courses: ',
    linkTarget:
      'https://members.anzca.edu.au/anzca/ANZCA/Events/ANZCA/EventLayouts/Event_Listing.aspx',
    description: `runs in each state - usually 1-2 week course (depending on state)
    focused on Primary content. Although for anesthetic trainees, I was
    allowed to attend – email and ask. 90-95% relevant to ICU and
    heavily subsidized by ANZCA (much cheaper than the equivalent ICU
    courses).`
  }
];

const Courses = ({ useStylesWithTheme }) => {
  const styles = useStylesWithTheme()();
  return (
    <Grid container>
      <Grid item md={fullWidth} className={styles.headingsContainer}>
        <Typography className={styles.h2}>Courses</Typography>
      </Grid>
      <Grid className={styles.plainTextContainer}>
        <ul className={styles.unorderedList}>
          {COURSES_CONTENT.map(
            ({ linkTitle, linkTarget, description }, idx) => {
              return (
                <li key={`courses-link-${idx}`}>
                  {linkTitle && (
                    <Link
                      href={linkTarget}
                      target={'_blank'}
                      className={styles.links}
                    >
                      {linkTitle}{' '}
                    </Link>
                  )}
                  {description}
                </li>
              );
            }
          )}
        </ul>
      </Grid>
    </Grid>
  );
};

export default Courses;
