import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import './NavBar.css'
import { NavTab } from 'appLayout/components/navbar/navTab/NavTab'
import { navTabList } from './navBarContents'
import { shouldUserBeDirectedToPaymentPage } from '../../../utils/helpers'
import { NAVPATH } from '../../../constants/navigation'

const useNavBarStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  tabs: {
    zIndex: 99
  }
})

const renderNonLoggedInUserView = (navBarClasses, history) =>
  (
    <Paper className={navBarClasses.root}>
      <Tabs
        value={false}
        indicatorColor="primary"
        textColor="primary"
        centered={true}
        className={navBarClasses.tabs}
      >
        {navTabList(JSON.stringify({})).map(tabProps => (
          <NavTab
            key={`navTab-${tabProps.value}`}
            {...tabProps}
            navigateTo={history.push}
          />
        ))}
      </Tabs>
    </Paper>)

const renderLoggedInUserRestrictedWithTrialView = (navBarClasses, history, user) => {
  const paymentComponents = [
    {
      label: 'Features',
      value: 'features',
      path: NAVPATH.LANDING,
    },
    {
      label: 'How to use',
      value: 'howtouse',
      path: NAVPATH.HOW_TO_USE,
    },
    {
      label: 'Study Advice',
      value: 'studyadvice',
      path: NAVPATH.STUDY_ADVICE,
    },
    {
      label: 'Free Trial',
      value: 'freetrial',
      path: `${NAVPATH.FIRSTTIMEUSER}/${user.id}`,
    }
  ]
  return (
    <Paper className={navBarClasses.root}>
      <Tabs
        value={false}
        indicatorColor="primary"
        textColor="primary"
        centered={true}
        className={navBarClasses.tabs}
      >
        {paymentComponents.map(tabProps => (
          <NavTab
            key={`navTab-${tabProps.value}`}
            {...tabProps}
            navigateTo={history.push}
          />
        ))}
      </Tabs>
    </Paper>)
}

const renderLoggedInUserRestrictedWithPaymentView = (navBarClasses, history) => {
  const paymentComponents = [
    {
      label: 'Features',
      value: 'features',
      path: NAVPATH.LANDING,
    },
    {
      label: 'How to use',
      value: 'howtouse',
      path: NAVPATH.HOW_TO_USE,
    },
    {
      label: 'Study Advice',
      value: 'studyadvice',
      path: NAVPATH.STUDY_ADVICE,
    },
    {
      label: 'Payment',
      value: 'payment',
      path: NAVPATH.PAYMENT,
    }
  ]
  return (
    <Paper className={navBarClasses.root}>
      <Tabs
        value={false}
        indicatorColor="primary"
        textColor="primary"
        centered={true}
        className={navBarClasses.tabs}
      >
        {paymentComponents.map(tabProps => (
          <NavTab
            key={`navTab-${tabProps.value}`}
            {...tabProps}
            navigateTo={history.push}
          />
        ))}
      </Tabs>
    </Paper>)
}

const Navbar = ({ history }) => {
  const loggedInUser = JSON.parse(localStorage.getItem('user'))
  const navBarClasses = useNavBarStyles()
  if (loggedInUser) {
    if (!loggedInUser.trialEndDate) {
      return renderLoggedInUserRestrictedWithTrialView(navBarClasses, history, loggedInUser)
    }

    if (shouldUserBeDirectedToPaymentPage(loggedInUser)) {
      return renderLoggedInUserRestrictedWithPaymentView(navBarClasses, history)
    }
  }

  if (!loggedInUser) {
    return renderNonLoggedInUserView(navBarClasses, history, loggedInUser)
  }
  return (
    <Paper className={navBarClasses.root}>
      <Tabs
        value={false}
        indicatorColor="primary"
        textColor="primary"
        centered={true}
        className={navBarClasses.tabs}
      >
        {navTabList(loggedInUser).map(tabProps => (
          <NavTab
            key={`navTab-${tabProps.value}`}
            {...tabProps}
            navigateTo={history.push}
          />
        ))}
      </Tabs>
    </Paper>
  )
}

export default Navbar
