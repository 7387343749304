import { makeStyles } from '@material-ui/styles';
import { globalStyles } from 'styles/globalStyles';

export const generateStyles = (theme) => (customStyles) =>
  makeStyles({
    interSectionBottomMargin: {
      marginBottom: '20px',
    },
    paragraphContainer: {
      paddingRight: '10px',
    },
    sectionSubHeader: {
      margin: '30px 0',
      paddingLeft: '50px',
    },
    tableContainerGrid: {
      marginBottom: '20px',
    },
    studyPlanTitle: {
      marginTop: '50px'
    },
    timeTillExamText: {
      margin: '40px 0 20px 0'
    },
    copyPrintText: {
      marginBottom: '20px'
    },
    textFieldStyle: {
      marginRight: '20px',
    },
    examDateEntryRow: {
      display: 'flex',
      marginLeft: '40px',
      marginBottom: '20px',
      height: '40px'
    },
    studyPlanTableContainer: {
      paddingLeft: '40px'
    },
    ...customStyles,
    ...globalStyles,
  });

export const fullWidth = 12;
