import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
// noinspection ES6CheckImport
import { withRouter } from 'react-router-dom'

import Header from 'appLayout/components/header/Header'

import { NAVPATH } from 'constants/navigation'
import { SidebarProvider } from './components/sidebar/SideBarContextProvider'
import { OFFLINE_BANNER_TEXT } from '../constants/labels'
import { featureToggles } from '../features'

export const headerHeight = 20

const useAppLayoutStyles = makeStyles({
  layout: {
    boxSizing: 'border-box',
    margin: '0px',
    minWidth: '1280px',
    overflow: 'hidden',
  },
  header: {
    width: '100%',
    height: '159px',
    backgroundColor: 'white',
  },
  body: {
    position: 'fixed',
    display: 'flex',
    width: '100%',
    height: 'calc(100vh - 259px)',
  },
  content: {
    width: '100%',
    overflow: 'auto',
    height: '100%'
  },
  banner: {
    width: '100%',
    height: '100px',
    backgroundColor: 'lightgray',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 100px 0 100px'
  }
})

const renderSidebarOnPaths = [
  NAVPATH.STUDY_ADVICE,
  NAVPATH.RESOURCES__FAST_FACTS,
  NAVPATH.SAQS__BY_SYLLABUS,
  NAVPATH.SAQS__BY_PAPER,
  NAVPATH.SYLLABUS__PHARMACOLOGY,
  NAVPATH.SYLLABUS__PHYSIOLOGY,
]

const AppLayoutX = ({ history, location, children }) => {
  const styles = useAppLayoutStyles()
  const isLoginSuccessPage = location.pathname.includes('/login/success')
  return (
    <div className={styles.layout}>
      {
        !isLoginSuccessPage ? (
            <>
              <div className={styles.header}>
                <Header history={history} headerHeight={headerHeight}/>
              </div>
              {featureToggles.goingOfflineBannerToggle && (
                <div className={styles.banner}>
                  <Typography variant={'h6'} align={'center'}>
                    {OFFLINE_BANNER_TEXT}
                  </Typography>
                </div>
              )}
              <div className={styles.body}>
                <SidebarProvider
                  currentPathName={location.pathname}
                  renderSidebarOnPaths={renderSidebarOnPaths}
                >
                  <div className={styles.content}>{children}</div>
                </SidebarProvider>
              </div>
            </>)
          :
          (
            <>
              {featureToggles.goingOfflineBannerToggle && (
                <div className={styles.banner}>
                  <Typography variant={'h6'} align={'center'}>
                    {OFFLINE_BANNER_TEXT}
                  </Typography>
                </div>
              )}
              <SidebarProvider
                currentPathName={location.pathname}
                renderSidebarOnPaths={renderSidebarOnPaths}
              >
                <div className={styles.content}>{children}</div>
              </SidebarProvider>
            </>
          )

      }

    </div>
  )
}

export const AppLayout = withRouter(AppLayoutX)
