import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Typography, Grid, Link } from '@material-ui/core';
import table from 'images/exam-technique-table-v3.png';

import { fullWidth } from 'pages/studyAdvice/styles';
import { NAVPATH } from 'constants/navigation';

const customStyles = theme => ({
  imgHolder: {
    marginTop: '1em',
    width: '100%',
    textAlign: 'center'
  },
  imgExamTechniqueTable: {
    width: '50%'
  },
  listBody: theme.typography.body1,
  listHeader: {
    fontWeight: 'bold'
  }
});

const ExamTechniqueAdvice = ({ useStylesWithTheme }) => {
  const addOnStyles = customStyles(useTheme());
  const styles = useStylesWithTheme(addOnStyles)();
  return (
    <>
      <Grid item md={fullWidth} className={styles.headingsContainer}>
        <Typography className={styles.h2}>Exam technique advice</Typography>
      </Grid>

      <Grid item md={fullWidth} className={styles.headingsContainer}>
        <Typography className={styles.h3}>General points</Typography>
      </Grid>

      <Grid item className={styles.plainTextContainer}>
        <ul>
          <li>
            <Typography className={styles.plainText}>
              I recommend allocating 120 min to complete 10 SAQs instead of the
              100 min recommended by CICM (but still do SAQs to 10 min during
              your study & during the exam – see below for explanation). As you
              can see from the table below, following CICM’s recommendation of
              50 min for MCQs & 100 min for SAQs means that you can spend a
              leisurely 10 min earning each mark for the MCQs but only have 5
              min to earn each mark in the SAQs, shortchanging yourself on time
              for the SAQs. Allocating 30 min for the MCQs & 120 min for the
              SAQs redistributes the min/mark evenly.
            </Typography>
          </li>
        </ul>

        <div className={styles.imgHolder}>
          <img
            className={styles.imgExamTechniqueTable}
            src={table}
            alt="Exam Technique Table"
          />
        </div>

        <ul>
          <li>
            <Typography className={styles.plainText}>
              Use the supplied scrap paper to jot notes and plan SAQs
            </Typography>
          </li>

          <li>
            <Typography className={styles.plainText}>
              Put a tiny mark in the margin for MCQs or the SAQs you want to
              come back to if time allows (the margin isn’t scanned to the
              examiners, which also means that you shouldn’t write any part of
              your answer in the margins!)
            </Typography>
          </li>
        </ul>
      </Grid>

      <Grid item md={fullWidth} className={styles.headingsContainer}>
        <Typography className={styles.h3}>
          The exam from start to finish
        </Typography>
      </Grid>

      <Grid item className={styles.plainTextContainer}>
        <Typography className={styles.plainText}>
          Reading Time (10 min):
        </Typography>
        <ul className={styles.unorderedList}>
          <li>
            Read the SAQ booklet first – briefly think about appropriate
            structures &{' '}
            {
              <Link
                href={NAVPATH.RESOURCES__TEMPLATES}
                className={styles.links}
              >
                template
              </Link>
            }{' '}
            for each – then move to reading MCQs
          </li>
          <li>
            When writing time starts, you may want to jot down your SAQ
            structures, & any other important points on them, before starting on
            the MCQs
          </li>
        </ul>
      </Grid>

      <Grid item className={styles.plainTextContainer}>
        <Typography className={styles.plainText}>MCQs (30 min)</Typography>
        <ul className={styles.unorderedList}>
          <li>
            Do these first – they can jog your memory on SAQs & if they do, use
            your scrap paper to take notes and jot these thoughts down as you go
          </li>
          <li>
            Don’t leave any MCQs blank – you may miss a line on your answer sheet
            & all the rest of your MCQs will be out of order! Just guess the answer,
            put a mark beside it to remind you to come back to it, & move on.
          </li>
          <li>You need to be fast here to finish in 30 min</li>
        </ul>
      </Grid>

      <Grid item className={styles.plainTextContainer}>
        <Typography className={styles.plainText}>SAQs (120 min)</Typography>
        <ul className={styles.unorderedList}>
          <li>
            Underline key words in the question so as not to misinterpret – verbs,
            nouns, negatives
          </li>
          <li>
            Keep time closely; DON’T let yourself run over time on SAQs. When
            you hit the 10 min mark, stop & move on. Stopping at 10 min instead
            of 12 min means you should have 20 min spare at the end of the paper
            – use this time to identify the SAQs where you can most easily score
            further marks. These might be:
          </li>
          <ul className={styles.unorderedListNestingLevelTwo}>
            <li>
              Your weakest SAQs where anything you write may still earn easy
              marks (0-10/20 mark zone)
            </li>
            <li>
              SAQs where you are able to write more without entering the minutia
              of the 15-20/20 mark zone
            </li>
          </ul>
        </ul>
      </Grid>
    </>
  );
};

export default ExamTechniqueAdvice;
