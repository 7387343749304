import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Grid, Link, makeStyles, Typography} from '@material-ui/core';
import {URLS} from 'constants/url';
import {areAllSaqsBefore2012} from 'utils/helpers';
import {globalStyles} from 'styles/globalStyles';
import {shuffle} from 'utils/helpers.js';

import Footer from 'appLayout/components/footer/Footer';

// Syllabus Letters Mapping
const syllabusLetterMapping = {
  'acid base': 'J. ',
  'autonomic nervous': 'M. ',
  'body fluids & electrolytes': 'I. ',
  cardiovascular: 'G. ',
  'cellular physiology': 'E. ',
  endocrine: 'U. ',
  gastrointestinal: 'O. ',
  haematological: 'Q. ',
  'immunology & host defence': 'S. ',
  liver: 'N. ',
  microbiology: 'T. ',
  musculoskeletal: 'L. ',
  nervous: 'K. ',
  'nutrition & metabolism': 'P. ',
  obstetrics: 'V. ',
  pharmacodynamics: 'C. ',
  pharmacokinetics: 'B. ',
  'principles of measurement & equipment': 'W. ',
  'procedural anatomy': 'X. ',
  renal: 'H. ',
  respiratory: 'F. ',
  thermoregulation: 'R. ',
  'variability in drug response': 'D. ',
};

const randomPaperStyles = makeStyles((theme) => ({
  randomPaperContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  saqTableWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0 0 0',
    position: 'sticky',
    backgroundColor: '#d3d3d3',
    height: '45px',
    zIndex: 9,
    top: 0,
  },
  cellWrapper: {
    padding: '0px 0px 0px 30px',
    wordBreak: 'break-all',
    wordWrap: 'break-word',
  },
  tableCellWrapper: {
    padding: '0',
    marginBottom: '20px',
  },
  link: {
    margin: theme.spacing(0),
    fontSize: '14px',
    color: 'black',
  },
  ...globalStyles,
}));

const _RandomPaper = () => {
  const [saq, setSaqs] = useState(null);
  useEffect(() => {
    if (saq) return;
    const abortController = new AbortController();
    axios
      .get(URLS.SAQS)
      .then((response) => {
        const saqs = response.data.filter((saq) => saq.syllabusSection);
        const saqsWithoutDuplicates = Array.from(
          new Set(
            saqs
              .map((saq) => saq.question)
              .map((question) => saqs.find((saq) => saq.question === question))
          )
        );
        
        const shuffledSaqs = shuffle(saqsWithoutDuplicates)
        const randomList = shuffledSaqs.splice(0, 10)

        const sortedSaqsBySyllabus = randomList.sort((a, b) =>
          a.syllabusSection.localeCompare(b.syllabusSection, 'en', {
            numeric: true,
          })
        );
        const saqsWithHeaders = [
          ...new Set(sortedSaqsBySyllabus.map((saq) => saq.syllabusSection)),
        ].reduce(
          (acc, curr) => [
            ...acc,
            {
              saqs: randomList.filter((saq) => saq.syllabusSection === curr),
              headerSection: `${
                syllabusLetterMapping[curr.toLowerCase()]
              }${curr}`,
            },
          ],
          []
        );

        return setSaqs(saqsWithHeaders);
      })
      .catch((err) => console.log(err));

    return () => {
      abortController.abort();
    };
  }, [saq]);

  const renderSaqs = (saqs, rowSaqId, styles, grouped = false) => {
    const filteredSaqs = saqs.filter((saq) => saq !== rowSaqId);
    return filteredSaqs.map((saq, idx) => {
      if (typeof saq !== 'string')
        return renderSaqs(saq, rowSaqId, styles, true);
      const paperDate = parseInt(saq.substring(0, 4));
      return paperDate > 2011 ? (
        <React.Fragment key={`relatedSaq-${saq}-${idx}`}>
          {idx === 0 && '['}
          <Link
            href={`/saqs/${saq.trim()}`}
            target="_blank"
            className={styles.links}
          >
            {saq}
          </Link>
          {idx < filteredSaqs.length - 1 && ', '}
          {idx === filteredSaqs.length - 1 && ']'}
          {idx === filteredSaqs.length - 1 && grouped && <br />}
        </React.Fragment>
      ) : (
        <React.Fragment key={`relatedSaq-${saq}-${idx}`}>
          {idx === 0 && '['}
          <span className={styles.deadLink}>{saq}</span>
          {idx < filteredSaqs.length - 1 && ', '}
          {idx === filteredSaqs.length - 1 &&
            filteredSaqs.length > 1 &&
            !areAllSaqsBefore2012(filteredSaqs) &&
            ']'}
          {idx === filteredSaqs.length - 1 &&
            filteredSaqs.length > 1 &&
            areAllSaqsBefore2012(filteredSaqs) &&
            ']'}
          {idx === filteredSaqs.length - 1 && filteredSaqs.length === 1 && ']'}
          {idx === filteredSaqs.length - 1 && grouped && <br />}
        </React.Fragment>
      );
    });
  };

  // const getSyllabusLetterFromTitle = syllabusTitle =>
  //   syllabusLetterMapping[syllabusTitle.toLowerCase()];

  const renderAllSaqRows = (saq) => {
    return (
      saq &&
      saq.map((section) => {
        return section.saqs.map((saqRowData, idx) => {
          return (
            <Grid container key={`${saqRowData._id}-${idx}`}>
              <Grid container className={styles.tableCellWrapper}>
                <Grid item md={1} className={styles.cellWrapper}>
                  {saqRowData.paperSaqId}
                </Grid>
                <Grid item md={6} className={styles.cellWrapper}>
                  {saqRowData.question}
                </Grid>
                <Grid item md={1} className={styles.cellWrapper}>
                  {saqRowData.erLink.map((link, idx) => (
                    <Link
                      key={`erLink${idx}`}
                      href={`${link}`}
                      target="_blank"
                      className={styles.links}
                    >
                      ER
                    </Link>
                  ))}
                </Grid>
                <Grid item md={1} className={styles.cellWrapper}>
                  {saqRowData.cwLinks.map((link, idx) => (
                    <Link
                      key={`cwLink${idx}`}
                      href={`${link}`}
                      target="_blank"
                      className={styles.links}
                    >
                      CW
                    </Link>
                  ))}
                </Grid>
                <Grid item md={1} className={styles.cellWrapper}>
                  {saqRowData.ippLinks.map((link, idx) => (
                    <Link
                      key={`ippLink${idx}`}
                      href={`${link}`}
                      target="_blank"
                      className={styles.links}
                    >
                      IPP
                    </Link>
                  ))}
                </Grid>
                <Grid item md={2} className={styles.cellWrapper}>
                  {renderSaqs(
                    saqRowData.relatedSaqs,
                    saqRowData.paperSaqId,
                    styles,
                    false
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        });
      })
    );
  };

  const styles = randomPaperStyles();
  return (
    <div className={styles.randomPaperContainer}>
      <Grid container className={styles.root}>
        <Grid className={styles.plainTextContainer}>
          <br />
          <Typography className={styles.plainText}>
            Below is a random set of SAQs for you to practice to time. To
            generate a new set of questions, just refresh the page.
          </Typography>
        </Grid>

        {renderAllSaqRows(saq)}
      </Grid>
      <Footer />
    </div>
  );
};

export default _RandomPaper;
