import React from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import { fullWidth } from 'pages/contact/styles';
import { NAVPATH } from 'constants/navigation';

/* eslint-disable max-len */
// const content = [
//   [
//     'ACT',
//     'https://docs.google.com/spreadsheets/d/13VsiU84omRhGvqDXI0VTfSpfI_dewwxznfR_ARwGsgs/edit?usp=sharing',
//   ],
//   [
//     'NSW',
//     'https://docs.google.com/spreadsheets/d/18njTsq4jrXYw7wHKU_DE9_XOj1cDoB3tr4irhRl7Sc0/edit?usp=sharing',
//   ],
//   [
//     'NT',
//     'https://docs.google.com/spreadsheets/d/1b8t3TvzTfQmYA6MQQqXNSxn7ar3QaBzgSZsu-fjT8tA/edit?usp=sharing',
//   ],
//   [
//     'QLD',
//     'https://docs.google.com/spreadsheets/d/1ZDxL3ji42P-8bnXW3BxFFodupcBcscb-GcI9aa_Dudk/edit?usp=sharing',
//   ],
//   [
//     'SA',
//     'https://docs.google.com/spreadsheets/d/1YfhghyljfiKEQPYd1c16RFkqhYNBboTgLs0cTO_Juso/edit?usp=sharing',
//   ],
//   [
//     'TAS',
//     'https://docs.google.com/spreadsheets/d/1Q8JqtMyj0Iw4uI7B7t9r_iR8CaGQArH2mtSSP16zWDw/edit?usp=sharing',
//   ],
//   [
//     'VIC',
//     'https://docs.google.com/spreadsheets/d/1BMAYel9k9ou8sJgaJyfWGV47EAPyr9lcelSWmupbuwg/edit?usp=sharing',
//   ],
//   [
//     'WA',
//     'https://docs.google.com/spreadsheets/d/1O6o9wziYYphxZzJYWpmLMNS-HtbWYfs8biLpCu2uMYU/edit?usp=sharing',
//   ],
// ];
/* eslint-enable */

const StudyGroups = ({ useStylesWithTheme }) => {
  const styles = useStylesWithTheme()();
  return (
    <Grid container>
      <Grid item md={fullWidth} className={styles.headingsContainer}>
        <Typography className={styles.h2}>Study groups</Typography>
      </Grid>

      <Grid item md={fullWidth} className={styles.plainTextContainer}>
        Click{' '}
        <Link
          href={
            'https://drive.google.com/open?id=117jB5VKVZ2EsMVgUemjPV4PlXdzHkLoU'
          }
          target={'_blank'}
          className={styles.links}
        >
          here
        </Link>{' '}
        to find people looking for study group members in your area.
        <br />
        <br />
        Like to list a study group or change details?&nbsp;
        <Link href={NAVPATH.CONTACT} target={'_blank'} className={styles.links}>
          Contact us.
        </Link>
      </Grid>
    </Grid>
  );
};

export default StudyGroups;
