import React from 'react';

import { Grid, Typography, useTheme } from '@material-ui/core';
import { fullWidth, generateStyles } from './styles';

const Advice = React.forwardRef((props, ref) => {
  const useStylesWithTheme = generateStyles(useTheme());
  const styles = useStylesWithTheme()();
  return (
    <>
      <Grid item md={fullWidth} className={styles.headingsContainer}>
        <Typography className={styles.h1} ref={ref}>Advice</Typography>
      </Grid>

      <Grid item md={fullWidth} className={styles.plainTextContainer}>
        <Grid className={styles.plainText}>
          This page will help you create an effective study plan. You may hear
          that this is a 1000-hour exam – unfortunately, that’s not that far
          off.
        </Grid>
      </Grid>
      <Grid item md={fullWidth} className={styles.plainTextContainer}>
        <Grid className={styles.plainText}>
          I’d suggest that 15-20 hours of study a week is reasonably achievable
          around a full-time job (43 hours/week for Australian registrars) &amp;
          all of life’s other commitments. This equates to ~2.5 hours study/day.
          There’ll be days where you study for many hours, days where you do
          only a small amount of study, &amp; days where you don’t study at all,
          but an average of 2.5 hours/day is hopefully achievable.
        </Grid>
      </Grid>
      <Grid item md={fullWidth} className={styles.plainTextContainer}>
        <Grid className={styles.plainText}>
          You’ll need to be realistic about the study time required before
          sitting the exam. Remember that it takes time to get into gear – so
          the first month(s) of study may not be effective.
        </Grid>
      </Grid>
      <Grid item md={fullWidth} className={styles.plainTextContainer}>
        <ul className={styles.unorderedList}>
          <li>
            Some candidates may need 1000 hours of study. At 2.5 hours/day,
            this will take ~15 months. 1000 hours over 15 months = 16.6
            hours/week = 2.4 hours/day.
          </li>
          <li>
            Other candidates will only need 800 hours, which at the same rate,
            is 12 months of study.
          </li>
          <li>
            Very effective candidates may pass after 600 hours - about 9 months.
          </li>
        </ul>
      </Grid>
    </>
  );
});

export default Advice;
