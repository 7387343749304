import React from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import { fullWidth } from 'pages/contact/styles';
import { NAVPATH } from 'constants/navigation';

/* eslint-disable max-len */
// const content = [
//   [
//     'ACT',
//     'https://docs.google.com/spreadsheets/d/1WTdg0vpjOE4EDcvZ1exTkyZ3gAzIMsOpcCtfTW-q91M/edit?usp=sharing',
//   ],
//   [
//     'NSW',
//     'https://docs.google.com/spreadsheets/d/1MDkbTOhq7c6GIx1qt8mO6P-yNJsSUZIdWca0zo5us_Q/edit?usp=sharing',
//   ],
//   [
//     'NT',
//     'https://docs.google.com/spreadsheets/d/1uToJnyrpDnI7OBSzV4zvwa4QMmFEIBPoShHigEQXIBU/edit?usp=sharing',
//   ],
//   [
//     'QLD',
//     'https://docs.google.com/spreadsheets/d/1FOfu7By17-oVEK4hQrfX7Qkk_PSBKV7EvnrkBgOyRgc/edit?usp=sharing',
//   ],
//   [
//     'SA',
//     'https://docs.google.com/spreadsheets/d/188Z5tagZI9VXGnXw6MO5_hKhqFzYJ4idhmzHogB8Ysc/edit?usp=sharing',
//   ],
//   [
//     'TAS',
//     'https://docs.google.com/spreadsheets/d/1tpgKBAiDnDJ2irA527uPnj_jBsjG3UYXoMKjutKBwss/edit?usp=sharing',
//   ],
//   [
//     'VIC',
//     'https://docs.google.com/spreadsheets/d/11-HSFvRxvWcxnuCwPxxpo-TiSn3Mzk4gmTOkq4oPimE/edit?usp=sharing',
//   ],
//   [
//     'WA',
//     'https://docs.google.com/spreadsheets/d/1jHbNmV85Fo0mQ71o602aPiP9zGNKWlgca7qYkzULSOg/edit?usp=sharing',
//   ],
// ];
/* eslint-enable */

const Tutorials = ({ useStylesWithTheme }) => {
  const styles = useStylesWithTheme()();
  return (
    <Grid container>
      <Grid item md={fullWidth} className={styles.headingsContainer}>
        <Typography className={styles.h2}>Tutorials</Typography>
      </Grid>

      <Grid item md={fullWidth} className={styles.plainTextContainer}>
        <Grid item className={styles.plainText}>
          Click{' '}
          <Link
            href={
              'https://drive.google.com/open?id=1I6JnJm1ZhAAUMog9ITLkgRcaO6SKeHgY'
            }
            target={'_blank'}
            className={styles.links}
          >
            here
          </Link>{' '}
          to find a list of tutorial groups you may be able to join, by state.
          Please reach out to the given contact first to confirm tutorial
          details and that you’re able to attend.
          <br />
          <br />
          Like to list a tutorial or change details?&nbsp;
          <Link href={NAVPATH.CONTACT} target={'_blank'} className={styles.links}>
            Contact us.
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Tutorials;
