import React from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import { fullWidth } from 'pages/contact/styles';

const ModelSaqSites = ({ useStylesWithTheme }) => {
  const styles = useStylesWithTheme()();
  return (
    <Grid container>
      <Grid item md={fullWidth} className={styles.headingsContainer}>
        <Typography className={styles.h2}>Model SAQ sites</Typography>
      </Grid>

      <Grid item className={styles.plainTextContainer}>
        <ul className={styles.unorderedList}>
          <li>
            <Link
              href={'https://cicmwrecks.wordpress.com/'}
              target={'_blank'}
              className={styles.links}
            >
              CICM Wrecks{' '}
            </Link>
            – contains model answers for SAQs from 2007b-2015b, then updated to
            include SAQs from 2016a-2020a
          </li>
          <li>
            <Link
              href={'https://icuprimaryprep.com/'}
              target={'_blank'}
              className={styles.links}
            >
              ICUprimaryprep{' '}
            </Link>
            – contains model answers for SAQs between 2007b-2014a. Also has
            excellent summary notes (not based on most recent syllabus but still
            very relevant).
          </li>
        </ul>
      </Grid>
    </Grid>
  );
};

export default ModelSaqSites;
