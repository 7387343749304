import React from 'react';
import { Grid, Typography } from '@material-ui/core';

export const renderSaqBySyllabusTableHeaders = (styles, saqs, isForSearch = false) =>
  saqs && saqs.length > 0 ? (
    <Grid container className={isForSearch ? '' : styles.saqTableWrapper}>
      <Grid item md={isForSearch ? 1 : 2}>
        <Typography className={isForSearch ? styles.searchTableHeaders : styles.tableHeaders} color="secondary">
          SAQ
        </Typography>
      </Grid>
      {/* <Grid item md={2}>
        <Typography className={styles.tableHeaders} color="secondary">
          Syllabus
        </Typography>
      </Grid> */}
      <Grid item md={isForSearch ? 6 : 5}>
        <Typography className={isForSearch ? styles.searchTableHeaders : styles.tableHeaders} color="secondary">
          Question
        </Typography>
      </Grid>
      <Grid item md={1}>
        <Typography className={isForSearch ? styles.searchTableHeaders : styles.tableHeaders} color="secondary">
          ER (external)
        </Typography>
      </Grid>
      <Grid item md={1}>
        <Typography className={isForSearch ? styles.searchTableHeaders : styles.tableHeaders} color="secondary">
          CW (external)
        </Typography>
      </Grid>
      <Grid item md={1}>
        <Typography className={isForSearch ? styles.searchTableHeaders : styles.tableHeaders} color="secondary">
          IPP (external)
        </Typography>
      </Grid>
      <Grid item md={2}>
        <Typography className={isForSearch ? styles.searchTableHeaders : styles.tableHeaders} color="secondary">
          Related SAQs
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <></>
  );

export const renderPhysiologySyllabusTableHeaders = (styles, syllabus, forSearch = false) =>
  syllabus && syllabus.length > 0 ? (
    <Grid container className={forSearch ? '' : styles.tableHeaderWrapper}>
      <Grid item md={1}>
        <Typography className={forSearch ? styles.searchTableHeaders : styles.tableHeaders} color="secondary">
          Syllabus
        </Typography>
      </Grid>
      <Grid item md={5}>
        <Typography className={forSearch ? styles.searchTableHeaders : styles.tableHeaders} color="secondary">
          Ability
        </Typography>
      </Grid>
      <Grid item md={1}>
        <Typography className={forSearch ? styles.searchTableHeaders : styles.tableHeaders} color="secondary">
          Level
        </Typography>
      </Grid>
      <Grid item md={2}>
        <Typography className={forSearch ? styles.searchTableHeaders : styles.tableHeaders} color="secondary">
          P1 (external)
        </Typography>
      </Grid>
      <Grid item md={3}>
        <Typography className={forSearch ? styles.searchTableHeaders : styles.tableHeaders} color="secondary">
          Related SAQs
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <></>
  );

export const renderPharmacologySyllabusTableHeaders = (styles, forSearch = false) => (
  <Grid container className={forSearch ? '' : styles.tableHeaderWrapper}>
    <Grid item md={5}>
      <Typography className={forSearch ? styles.searchTableHeaders : styles.tableHeaders} color="secondary">
        Ability
      </Typography>
    </Grid>
    <Grid item md={1}>
      <Typography className={forSearch ? styles.searchTableHeaders : styles.tableHeaders} color="secondary">
        Level
      </Typography>
    </Grid>
    <Grid item md={2}>
      <Typography className={forSearch ? styles.searchTableHeaders : styles.tableHeaders} color="secondary">
        P1 (external)
      </Typography>
    </Grid>
    <Grid item md={4}>
      <Typography className={forSearch ? styles.searchTableHeaders : styles.tableHeaders} color="secondary">
        Related SAQs
      </Typography>
    </Grid>
  </Grid>
);

export const renderStudyPlanTableHeaders = (styles) => (
  <Grid container className={styles.studyPlantableHeaderWrapper}>
    <Grid item md={4}>
      <Typography className={styles.studyPlanTableHeaders} color="secondary">
        Date
      </Typography>
    </Grid>
    <Grid item md={4}>
      <Typography className={styles.studyPlanTableHeaders} color="secondary">
        Section
      </Typography>
    </Grid>
    <Grid item md={4}>
      <Typography className={styles.studyPlanTableHeaders} color="secondary">
        Days
      </Typography>
    </Grid>
  </Grid>
);
