import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { fullWidth } from 'pages/contact/styles';

/* eslint-disable max-len */
const content = [
  [
    'General physiology',
    [
      'Dr Podcast – both the book & podcasts (content is the same), are fantastic. Covers much of the syllabus & also written in question & answer format (so great for the Viva). The podcasts are particularly helpful for cramming in extra study in situations where you’re able to listen but not read, e.g. in the car.',
      'Ganong’s review of medical physiology, Barrett et al. – great book. Detailed notes but I found this easier to understand than Guyton & Hall.',
      'Principles of physiology for the anaesthetist, Power & Kam – great book for a quick overview. It seems a lot of exam answers are taken from Power & Kam. I found the explanations a little confusing at times though.',
      'Textbook of medical physiology, Guyton & Hall – great book. Quite detailed notes, good as a secondary reference.',
      'The physiology viva, Brandis – fantastic book. Excellent summary of most of the important aspects of the intensive care syllabus. The question & answer format is extremely helpful for the Viva & the Fast Facts section at the back is invaluable.'
    ]
  ],
  [
    'Respiratory',
    [
      'Nunn’s applied respiratory physiology, Lumb – also fantastic, but much more detailed than West. The ‘Bible’ of respiratory.',
      'Respiratory physiology – the essentials, West – fantastic book. Short & to the point. Can’t go without it.'
    ]
  ],
  [
    'Renal',
    [
      'Vander’s renal physiology, Eaton & Poole – fantastic book. The ‘Bible’ of renal.'
    ]
  ],
  [
    'Pharmacology',
    [
      'Basic & clinical pharmacology, Katzung et al. – great book. I found it a much easier read than Goodman & Gilman’s, & Stoelting’s.',
      'Drugs in anaesthesia & intensive care, Smith et al. – fantastic book. It’s an encyclopedia of anaesthetic & intensive care drugs listed in the structure we use for the Primary & it’s small & can be taken around in your pocket at work.',
      'Goodman & Gilman\'s the pharmacological basis of therapeutics, Hardman et al. – good book. This is supposed to be the ‘Bible’ of pharmacology.',
      'Pharmacology for anaesthesia and intensive care, Peck and Hill – great book to give you a brief overview of pharmaceutics, pharmacodynamics, pharmacokinetics and many drugs, but not enough detail.',
      'Stoelting’s pharmacology & physiology in anesthetic practice, Shafer et al. - anaesthetists seem to like this book a lot although I didn’t find it easy to study from'
    ]
  ],
  [
    'Measurement',
    [
      'Equipment in anaesthesia and critical care, Aston – fantastic book. Simple and concise explanations of equipment used in intensive care.'
    ]
  ]
];
/* eslint-enable */

const Books = ({ useStylesWithTheme }) => {
  const styles = useStylesWithTheme()();
  return (
    <Grid container justify="flex-end">
      <Grid item md={fullWidth} className={styles.headingsContainer}>
        <Typography className={styles.h2}>Books</Typography>
      </Grid>
      {content.map(([subtitle, points], idx) => {
        return (
          <Grid container key={`booksContent-${idx}`}>
            <Grid item md={fullWidth} className={styles.headingsContainer}>
              <Typography className={styles.h3}>{subtitle}</Typography>
            </Grid>
            <Grid className={styles.plainTextContainer}>
              <ul className={styles.unorderedList}>
                {points.map((point, idxx) => (
                  <li key={`subsection-books-points-${idxx}`}>{point}</li>
                ))}
              </ul>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Books;
