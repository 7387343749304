/* eslint-disable no-useless-escape */
import React from 'react';
import { Typography, useTheme } from '@material-ui/core';
import { FacebookLoginButton, GoogleLoginButton } from 'react-social-login-buttons';
import { generateStyles } from 'pages/login/styles';
import Footer from '../../appLayout/components/footer/Footer';
// import { isDev } from '../../utils/helpers';

const Login = () => {
  const useStylesWithTheme = generateStyles(useTheme());
  const styles = useStylesWithTheme()();

  // const handleFacebookLoginOnClick = () => {
  //   window.location.href = 'http://localhost:8080/auth/facebook';
  // };
  //
  // const handleGoogleLoginOnClick = () => {
  //   window.location.href = 'http://localhost:8080/auth/google'
  // }

  const handleFacebookLoginOnClick = () => {
    window.location.href = 'https://critbear.com/api/auth/facebook';
  };

  const handleGoogleLoginOnClick = () => {
    window.location.href = 'https://critbear.com/api/auth/google';
  };

  return (
    <div className={styles.loginMain}>
      <div>
        <Typography className={styles.title}>
          {/* Please log in or register. First time users get a FREE 2 WEEK TRIAL. */}
        </Typography>
        <div className={styles.loginBox}>
          <Typography className={styles.loginRegisterText}>Login/Register</Typography>
          <div className={styles.buttonsContainer}>
            <FacebookLoginButton
              variant="contained"
              color="primary"
              onClick={() => handleFacebookLoginOnClick()}
            />
            <GoogleLoginButton
              variant="contained"
              color="primary"
              onClick={() => handleGoogleLoginOnClick()}
            />
          </div>
        </div>
      </div>

      <div className={styles.footerContainer}>
        <Footer />
      </div>
    </div>
  );
};

export default Login;
