import React, { useContext, useEffect, useState } from 'react';
import { Button, Typography, useTheme } from '@material-ui/core';
import { generateStyles } from 'pages/firstTimeUser/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Footer from '../../appLayout/components/footer/Footer';
import { store } from '../../store';
import { NAVPATH } from '../../constants/navigation';
import { hasFreeTrialDateButHasExpired, hasSubscriptionDateButHasExpired } from '../../utils/helpers';
import axios from 'axios';

const FirstTimeUser = props => {
  const globalState = useContext(store);
  const { dispatch } = globalState;

  const [checked, setChecked] = useState(false);
  const [userId, setUserId] = useState('');

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'))
    if(!user) props.history.push(NAVPATH.LOGIN)

    if(user.trialEndDate && hasFreeTrialDateButHasExpired(user) && hasSubscriptionDateButHasExpired(user)) {
      props.history.push(NAVPATH.PAYMENT)
    }

    if((user.trialEndDate && !hasFreeTrialDateButHasExpired(user))
      || (hasFreeTrialDateButHasExpired(user) && !hasSubscriptionDateButHasExpired(user))) {
      props.history.push(NAVPATH.LANDING)
    }

    setUserId(user.id)
  }, [dispatch, props]);

  const useStylesWithTheme = generateStyles(useTheme());
  const styles = useStylesWithTheme();

  const postData = async (url = '', data = {}) => {
    // Default options are marked with *
    const options = {
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer
    }

    const response = await axios.post(url, data, options);
    return response.data;
  }

  const handleCheckboxOnChange = event => setChecked(event.target.checked);

  const handleSubmitOnClick = () => {
    postData('/users/start-free-trial', { userId })
      .then(user => {
        props.history.push(`${NAVPATH.LOGIN_SUCCESS}/${user.id}`)
      })
      .catch(err => console.log('error', err))
  }


  return (
    <div className={styles.main}>
      <div className={styles.freeTrialTextContainer}>
        <Typography className={`${styles.title} ${styles.plainText}`}>
          {/* You will automatically get a free 2 week trial. Pricing thereafter (introductory offer): */}
        </Typography>
        {/* <ul className={`${styles.unorderedList} ${styles.marginBtmSm} ${styles.plainText}`}>
          <li>1 month: $8</li>
          <li>3 months: $22 (10% off)</li>
          <li>6 months: $38 (20% off)</li>
        </ul> */}

        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={handleCheckboxOnChange}
              color="primary"
            />
          }
          classes={{
            root: styles.root,
            label: styles.label
          }}
          label="I agree to Crit Bear’s"
        />
        &nbsp;
        <a target={'_blank'}
           rel={'noopener noreferrer'}
           href={'https://drive.google.com/file/d/1IFyOFeKnNhEqRFIv9f0fypDzAa_MBWPk/view?usp=sharing'}
           className={`${styles.privatePolicyLink} ${styles.plainText}`}>
          private policy.
        </a>

      </div>

      <div className={styles.submitButtonContainer}>
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: '10px', cursor: 'pointer' }}
          onClick={handleSubmitOnClick}
          disabled={!checked}
        >
          Submit
        </Button>
      </div>


      <div className={styles.footerContainer}>
        <Footer/>
      </div>
    </div>
  );
};

export default FirstTimeUser;
