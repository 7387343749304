import { useContext, useState, useEffect } from 'react';
import { SidebarContext } from 'appLayout/components/sidebar/SideBarContextProvider.jsx';

export function useSideBarHook(listOfRefs) {
  const context = useContext(SidebarContext);
  /*eslint-disable*/
  const [scrolledToSelected, updateScrolledTo] = useState(null);

  useEffect(() => {
    return () => context.resetSideBarContext();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (listOfRefs.length > 0 && listOfRefs[0].current && context.id && context.id !== 'initialId') {
    listOfRefs[context.selected].current.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
    updateScrolledTo(context.selected);
  }
}
